<div fxLayout="column" fxLayoutAlign="top" [ngStyle]="{'height':'100%', padding: '1em', overflow: 'hidden'}">
  <h2 mat-dialog-title fxFlex="8%">
    Select your organization
  </h2>

  <mat-dialog-content fxFlex="82%">
    <div fxLayout="column">
      <div *ngIf="form">
        <form [formGroup]="form" fxLayout="column" role="form">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>search</mat-label>
            <input matInput placeholder="search" class="form-control" formControlName="filterText">
          </mat-form-field>
        </form>
      </div>

      <div fxFlex="auto"  style="overflow: auto">
        <mat-nav-list>
          <mat-list-item *ngFor="let org of organizationsForView" (click)="continue(org)">
            <img width="50px" matListAvatar [src]="org.fullLogoUrl">
            <span matLine> {{org.fullName}} </span>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
  </mat-dialog-content>
</div>
