<app-modal>
    <div class="modal_header" #modal_header>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <thumbnail [image]="user?.picture"></thumbnail>

            <div fxFlex="stretch" class="modal_title" fxLayout="row" fxLayoutGap="10px">
                <div>{{user?.fullname}}</div>
                <span style="background-color: red" *ngIf="user?.decedent"> -
                    {{labels.decedent}} - </span>
            </div>
        </div>

        <!-- <div style="margin: 9px -10px -10px;">
            <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        </div> -->
    </div>

    <div class="modal_body" #modal_body style="height: 67vh;">
        @if (isLoading) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <div class="p-2">
            @if (toggleBtns) {
            <div fxLayout="row" fxLayoutAlign="space-between" class="mb-4">
                <mat-form-field appearance="outline">
                    <mat-label>{{labels.search}}</mat-label>
                    <input matInput type="text" [(ngModel)]="searchText" (keydown.enter)="handleSearchText()">
                    @if (searchText) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }
                </mat-form-field>

                @if (user) {
                <div>
                    <mat-button-toggle-group [(ngModel)]="filter" (ngModelChange)="onFilterChange()" name="filter"
                        aria-label="Filters">
                        @for (btn of toggleBtns; track $index) {
                        <mat-button-toggle [value]="btn.value">{{btn.label}}</mat-button-toggle>
                        }
                    </mat-button-toggle-group>
                </div>
                } @else {
                <div>
                    <mat-button-toggle checked [value]="institution.id">{{labels.referredBy}}</mat-button-toggle>
                </div>
                }
            </div>
            }

            <div>
                @if (currentUserConnectedServices && !isLoading) {
                <div>
                    @if (tableCfg) {
                    <mat-accordion>
                        <mat-expansion-panel hideToggle *ngFor="let row of tableCfg"
                            (opened)="getRequestCreatorName(row.originalRequest.log.createdBy)">

                            <mat-expansion-panel-header [ngClass]="[row._rowClass ? row._rowClass : '']">
                                <mat-panel-description style="width: 50%; color: black;">
                                    {{row.title}}
                                </mat-panel-description>
                                <mat-panel-description style="width: 25%; color: black;">
                                    {{row.service}}
                                </mat-panel-description>
                                @if (filter != 'customer') {
                                <mat-panel-description style="width: 25%; color: black;">
                                    {{row.customerFullName}}
                                </mat-panel-description>
                                } @else {
                                <mat-panel-description style="width: 25%; color: black;">
                                    {{row.executorFullName}}
                                </mat-panel-description>
                                }

                            </mat-expansion-panel-header>
                            <div fxLayout="column" class="settings_content" style="margin-left: 15px;">
                                <div fxLayout="row" fxLayoutGap="10px" style="margin-bottom: 20px;">
                                    <div fxFlex="stretch" fxLayout="column">
                                        <div class="text-primary">{{labels.created_at}}</div>
                                        <div>{{toDate(row.originalRequest.log.createdAt)}}</div>
                                    </div>
                                    <div fxFlex="stretch" fxLayout="column">
                                        <div class="text-primary">{{labels.start_date}}</div>
                                        <div>{{row.originalRequest?.startDate}}</div>
                                    </div>
                                </div>

                                <div fxLayout="row" fxLayoutGap="10px">
                                    <div fxFlex="stretch" fxLayout="column">
                                        <div class="text-primary">{{labels.created_by}}</div>
                                        <div>{{creatorName}}</div>
                                    </div>
                                    <div fxFlex="stretch" fxLayout="column">
                                        <div class="text-primary">{{labels.end_date}}</div>
                                        <div>{{row.originalRequest?.endDate}}</div>
                                    </div>
                                </div>

                                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
                                    <div fxFlex="stretch" fxLayout="column"></div>
                                    <div fxLayout="column">
                                        <button class="text-primary" style="width: max-content;" mat-button
                                            [disabled]="!currentUserConnectedServices.includes(row.originalRequest.management.serviceId)"
                                            (click)="handleRowSelect(row)">{{labels.go_to_request}}</button>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <div class="p-3" fxLayout="row" fxLayoutAlign="center center">
                        <button [disabled]="fetchingMore"
                            *ngIf="lastDocRef || requests.length < teamCacheRequest?.length" (click)="fetchMore()"
                            mat-stroked-button color="primary">{{fetchingMore ? labels.please_wait
                            : labels.fetch_more}}</button>
                    </div>
                    }

                    @if (!isLoading && !tableCfg.length) {
                    <div class="py-4">
                        <app-empty-list [title]="labels.no_requests_found"
                            [description]="tableCfg.length ? labels.empty_requests_desc : labels.empty_requests_desc_alt">
                        </app-empty-list>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row">
            <button *ngIf="filter == 'customer'" mat-raised-button color="primary" (click)="createRequest()">
                {{labels.add_request}}
            </button>
        </div>
    </div>
</app-modal>