import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';
import { Request } from '@models/model';
import { IDataTableConfig, ITableData } from '@shared/components';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-customer-open-requests',
  templateUrl: './customer-open-requests.component.html',
  styleUrls: ['./customer-open-requests.component.scss']
})
export class CustomerOpenRequestsComponent implements OnInit {
  protected requests: Request[];
  public labels = this.labelService.defaultProvider();
  protected userName: string;
  protected dataTableConfig: IDataTableConfig;
  
  constructor(
    private dialogRef: MatDialogRef<CustomerOpenRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { requests: Request[]; customerName: string },
    private labelService: LabelService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-customer-open-requests')).data
    this.requests = cloneDeep(this.data.requests) || [];
    this.userName = this.data.customerName.trim();
    this.getTableConfig();
  }

  private getTableConfig() {
    const config: IDataTableConfig = {
      data: this.requests.map(req => {
        return {
          _statusValue: { val: req.status, /* org: this.org*/ },
          _metadata: { originalData: req, },
          title: req.title
        };
      }),
      displayProperties: ['status-row', 'title'],
      rowOptions: [],
      allowSelection: false,
      displayHeaders: false,
    };
    this.dataTableConfig = config;
  }

  protected handleRowSelect(data: ITableData) {
    this.dialogRef.close(data._metadata.originalData);
  }

}
