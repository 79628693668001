<app-modal [hideCloseButton]="true">
  <div class="modal_header" #modal_header>
    <span class="modal_title">
      {{data.title}}
    </span>
  </div>

  <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
    <p [innerHtml]="data.message"></p>
    <div class="match_text" *ngIf="config.requireMatchText">{{config.requireMatchText}}</div>

    <form [formGroup]="promptForm">
      <app-phone-field *ngIf="config.type === 'phone'" [val]="config.initialValue" [message]="labels.invalid_phone_text"
        [placeholder]="labels.input" (update)="onPhone($event)">
      </app-phone-field>

      <mat-form-field appearance="outline" class="full-width" *ngIf="config.type !== 'phone'">
        <mat-label>{{labels.input}}</mat-label>
        <input *ngIf="config.type === 'input'" type="text" matInput class="form-control"
          formControlName="inputValue">

        <input *ngIf="config.type === 'password'" type="password" matInput
          class="form-control" formControlName="inputValue">

        <input *ngIf="config.type === 'number'" type="number" matInput class="form-control"
          formControlName="inputValue">

        <textarea *ngIf="config.type === 'textarea'" matInput formControlName="inputValue" cdkTextareaAutosize cdkAutosizeMinRows
          minRows="3"></textarea>
      </mat-form-field>
    </form>
  </div>

  <div class="modal_footer" #modal_footer>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <button *ngIf="!config.required && !config.disableCancel" mat-stroked-button
        (click)="cancel()">{{labels.cancel}}</button>
      <button (click)="continue()" [disabled]="!canSubmit()" color="primary"
        mat-raised-button>{{labels.submit}}</button>
    </div>
  </div>
</app-modal>