import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IObjectMap } from '@models/interface';
import { environment } from 'environments/environment';
import { from, Observable, of } from 'rxjs';
import { map, mergeAll, take } from 'rxjs/operators';
import { ObjectMap } from '../../../../functions/src/interfaces';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

export interface ApiResponse<T> {
  success: boolean;
  data?: T;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: LocalStorageService
  ) {
    this.authService.customClaimsHandler = {
      clear: () => {
        return this.post('users/auth/logout').pipe(take(1), map(_ => null)).toPromise();
      }
    };
  }

  private appendTimeStamp(params: IObjectMap<string>): IObjectMap<string> {
    params['__t__'] = Date.now().toString();

    return params;
  }

  private apiConfig(url: string, params?: IObjectMap<any>): Observable<{ config: ObjectMap<any>; path: string; }> {
    return this.authService.authState$.pipe(
      map(user => {
        return user ? from(user.getIdToken()) : of('');
      }),
      mergeAll(),
      map(token => {
        const config = {
          params: this.appendTimeStamp(params || {}),
          headers: {
            'Authorization': 'Bearer ' + token,
            userId: this.storageService.getItemSync('user_id') ?? '',
            orgId: this.storageService.getItemSync('user_organization') ?? '',
          }
        };

        return { config, path: url };
      }),
    );
  }

  public get<T>(url: string, params?: IObjectMap<any>): Observable<ApiResponse<T>> {
    return this.apiConfig(url, params).pipe(
      map(r => {
        return this.http.get<ApiResponse<T>>(`${environment.apiEndpoint}/${r.path}`, r.config);
      }),
      mergeAll()
    );
  }

  public post<T>(url: string, body?: ObjectMap<any>): Observable<ApiResponse<T>> {
    return this.apiConfig(url).pipe(
      map(r => {
        return this.http.post<ApiResponse<T>>(`${environment.apiEndpoint}/${r.path}`, body || {}, r.config);
      }),
      mergeAll()
    );
  }

  public put<T>(url: string, body?: ObjectMap<any>): Observable<ApiResponse<T>> {
    return this.apiConfig(url).pipe(
      map(r => {
        return this.http.put<ApiResponse<T>>(`${environment.apiEndpoint}/${r.path}`, body || {}, r.config);
      }),
      mergeAll()
    );
  }

  public exportDatabasePost(url: string, body?: ObjectMap<any>) {
    return this.apiConfig(url).pipe(
      map(r => {
        return this.http.post<{ docId: string; exportedCols: { zipUrl: string } }>(`${environment.exportDatabaseEndpoint}/${r.path}`, body || {}, r.config);
      }),
      mergeAll()
    );
  }

  public externalGet(url: string, headers?: IObjectMap<string>, params?: IObjectMap<any>) {
    url = url.startsWith('https://') ? url : `https://${url}`;
    return this.http.get(url, { headers, params }).pipe(take(1)).toPromise();
  }

  public externalPost(url: string, headers?: IObjectMap<string>, body?: IObjectMap<any>) {
    url = url.startsWith('https://') ? url : `https://${url}`;
    return this.http.post(url, body, headers).pipe(take(1)).toPromise();
  }

}
