<div fxLayoutAlign="center center" fxLayout="column">
    <div [style.width]="width">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="100%">
                <svg viewBox="0 0 36 36" class="circular-chart" [ngClass]="color">
                    <path class="circle-bg" d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831" />
                    <path class="circle" [ngStyle]="{'stroke-dasharray': percentage +' ,100'}" d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831" />
                    <text x="18" y="20.35" class="percentage">{{percentage | number:'1.1-2'}}%</text>
                </svg>
            </div>
        </div>

        <div *ngIf="message" fxLayoutAlign="center center">
            <p>{{message}}</p>
        </div>
    </div>
</div>