<app-modal>
    <div class="modal_body" #modal_body style="height: 100%;" fxLayout="column">
        <div style="padding: 30px;" fxLayout="row" fxLayoutAlign="center center" *ngIf="busy">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>

        <div class="image_thumbnail_container" fxLayout="row" fxLayoutAlign="start center">
            <div class="image_thumbnail" *ngFor="let file of files" [ngClass]="{active: file.id === fileInScope?.id}">
                <span class="edit_control" (click)="replaceImage(file)" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>edit</mat-icon>
                </span>
                <span class="image_thumbnail_inner" (click)="selectFile(file)"
                    [ngStyle]="{'background-image': 'url(' + file.url + ')'}" fxLayout="column"
                    fxLayoutAlign="center center">
                    <app-progress *ngIf="uploadProgress[file.id]" width="50px" color="primary"
                        [percentage]="uploadProgress[file.id].percent" [message]="uploadProgress[file.id].message">
                    </app-progress>
                </span>
            </div>
        </div>

        <div fxLayout="column" fxFlex="stretch" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center" style="min-width: 50%">
                <image-cropper *ngIf="config.shouldCrop && fileInScope" [imageChangedEvent]="fileInScope.file"
                    [maintainAspectRatio]="config.maintainAspectRatio" [aspectRatio]="config.aspectRatio"
                    [resizeToWidth]="config.resizeWidth" format="png" outputType="file" [imageFile]="fileInScope.file"
                    (imageCropped)="imageCropped($event)" (loadImageFailed)="loadImageFailed()">
                </image-cropper>

                <img *ngIf="!config.shouldCrop && fileInScope"
                    [src]="_DomSanitizationService.bypassSecurityTrustUrl(fileInScope?.url)" width="100%" />
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <button mat-button (click)="closeModal()">cancel</button>
            <button mat-raised-button color="primary" [ngClass]="{'disabled_element': files.length === 0}"
                (click)="config.shouldUpload ? uploadFiles() : outputResult()">
                {{ config.shouldUpload ? 'Upload' : 'Continue' }}
            </button>
        </div>
    </div>
</app-modal>

<div class="secret-input">
    <input *ngIf="config.isMultiple" #imageSelectorElement type="file" multiple accept="image/*"
        (change)="onFileSelected($event)">

    <input *ngIf="!config.isMultiple" #imageSelectorElement type="file" accept="image/*"
        (change)="onFileSelected($event)">
</div>