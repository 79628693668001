<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.history_activity}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 15px;">
        <div class="content" *ngIf="docTableConfig?.data?.length">
            <data-table [configOptions]="docTableConfig">
            </data-table>
        </div>
    </div>
</app-modal>