import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UserPromptsService } from '@core/services';
import { UsageDisplayModelComponent } from '../usage-display-model/usage-display-model.component';

interface TostPayload {
  message: string;
  icon: string;
  userId: string;
}

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  public toastData: TostPayload

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { html: string },
    private userPromptService: UserPromptsService
  ) { }

  ngOnInit(): void {
    this.toastData = JSON.parse(this.data.html);
  }

  public showDialog() {
    this.userPromptService.showDialogue(UsageDisplayModelComponent, {userId: this.toastData.userId})
  }

}
