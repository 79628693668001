<div class="container_body">
    <div class="section_title">
        {{workingArea?.name}}
    </div>

    <div class="empty_list" *ngIf="services?.length === 0">
        {{labels.no_services_with_catalog}}
    </div>

    <div *ngIf="services?.length > 0" fxLayout="column" fxLayoutGap="15px">
        <div *ngFor="let group of services" fxLayout="row" fxLayoutGap="15px">
            <div *ngFor="let service of group" class="catalog-item">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title style="font-size: 15px;">{{service.name}}</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="card-content">
                        <p class="carriage_returns">
                            {{service.description}}
                        </p>
                    </mat-card-content>

                    <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
                        <button
                            [disabled]="service.settings.catalog?.action !== ServiceCatalogActionTypes.add_service_registration"
                            mat-button color="primary" (click)="addRegistration(service)">
                            {{labels.add_registration}}
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>