import { Injectable } from '@angular/core';
import { FirestoreService, DocItem } from '../firestore.service';
import { LocalStorageService } from '../local-storage.service';
import { Observable } from 'rxjs';
import { IMailinglistItem } from '@shared/model';
import { map } from 'rxjs/operators';
import { orderBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MailinglistService {
  private colRef: string;

  constructor(
    private afsDB: FirestoreService,
    private localStorageService: LocalStorageService
  ) {
    this.colRef = `/organizations/${this.localStorageService.getItemSync('user_organization')}/mailingLists`;

    this.localStorageService.getItem('user_organization').subscribe((orgId) => {
      this.colRef = `/organizations/${orgId}/mailingLists`;
    });
  }

  public getMailinglists(queryFn?: any): Observable<IMailinglistItem[]> {
    return this.afsDB.colWithIds$<IMailinglistItem>(this.colRef, queryFn).pipe(
      map(list => orderBy(list, ['name'], ['asc']))
    );
  }

  public getAciveMailinglists(): Observable<IMailinglistItem[]> {
    return this.getMailinglists(ref => {
      return ref.where('active', '==', true);
    });
  }

  public getAciveMailinglistsUserCanSubscribe(): Observable<IMailinglistItem[]> {
    return this.getMailinglists(ref => {
      return ref.where('active', '==', true).where('userCanSubscribe', '==', true);
    });
  }

  public deleteMailinglist(id: string): Promise<void> {
    return this.afsDB.remove(`${this.colRef}/${id}`);
  }

  public createMailinglist(m: IMailinglistItem): Promise<void> {
    return this.afsDB.add(this.colRef, m);
  }

  public updateMailinglist(m: IMailinglistItem): Promise<void> {
    return this.afsDB.update(`${this.colRef}/${m.id}`, m as DocItem);
  }

  public patchMailinglist(id: string, patch: DocItem): Promise<void> {
    return this.afsDB.update(`${this.colRef}/${id}`, patch as DocItem);
  }
}
