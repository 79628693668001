import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IObjectMap } from '@shared/interface';
import { LabelService } from '@core/services/labels.service';

export interface ConfirmDialogConfig {
    confirmBtn?: string;
    cancelBtn?: string;
}

@Component({
    selector: 'app-confirm-dialogue',
    styleUrls: ['./confirm-dialogue.component.css'],
    templateUrl: './confirm-dialogue.component.html',
})
export class ConfirmDialogueComponent implements OnInit {
    public labels: any = {};
    public config: ConfirmDialogConfig;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IObjectMap<any>,
        private labelService: LabelService
    ) { }

    async ngOnInit() {
        this.config = this.data.config || {};
        this.labels = (await this.labelService.getLabels('app-confirm-dialogue')).data;
    }

    continue() {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}
