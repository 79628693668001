import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { Observable } from 'rxjs';
import { NetworkPartner, NetworkInstitution } from '@shared/model';
import { LocalStorageService } from './local-storage.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CustomerNetworkService {
    private orgId: string;

    constructor(
        private afsDb: FirestoreService,
        private localStorageService: LocalStorageService
    ) {
        this.orgId = this.localStorageService.getItemSync('user_organization');
        this.localStorageService.getItem('user_organization').subscribe(orgId => {
            this.orgId = orgId;
        });
    }

    public getNetworkPartners(customerId: string): Observable<NetworkPartner[]> {
        return this.afsDb.colWithIds$(`/organizations/${this.orgId}/networkPartners`, ref => {
            return ref.where('customerId', '==', customerId);
        });
    }

    public createNetworkPartner(partner: NetworkPartner): Promise<any> {
        return this.afsDb.add(`/organizations/${this.orgId}/networkPartners`, JSON.parse(JSON.stringify(partner)));
    }

    public isNetworkPartnerForCustomer(customerId: string, partnerId: string): Observable<boolean> {
        return this.afsDb.colWithIds$<NetworkPartner>(`/organizations/${this.orgId}/networkPartners`, ref => {
            return ref.where('customerId', '==', customerId)
                .where('partnerId', '==', partnerId).limit(1);
        }).pipe(
            map(users => !!users[0])
        );
    }

    public updateNetworkPartner(partner: NetworkPartner): Promise<any> {
        return this.afsDb.update(
            `/organizations/${this.orgId}/networkPartners/${partner.id}`,
            JSON.parse(JSON.stringify(partner))
        );
    }

    public createNetworkInstitution(payload: NetworkInstitution): Promise<any> {
        return this.afsDb.add(`/organizations/${this.orgId}/networkInstitutions`, JSON.parse(JSON.stringify(payload)));
    }

    public updateNetworkInstitution(payload: NetworkInstitution): Promise<any> {
        return this.afsDb.update(
            `/organizations/${this.orgId}/networkInstitutions/${payload.id}`,
            JSON.parse(JSON.stringify(payload))
        );
    }

    public getNetworkInstitutions(): Observable<NetworkInstitution[]> {
        return this.afsDb.colWithIds$(`/organizations/${this.orgId}/networkInstitutions`);
    }

    public deleteNetworkPartner(partnerId: string): Promise<any> {
        return this.afsDb.remove(`/organizations/${this.orgId}/networkPartners/${partnerId}`);
    }

    public deleteNetworkInstitution(docId: string): Promise<any> {
        return this.afsDb.remove(`/organizations/${this.orgId}/networkInstitutions/${docId}`);
    }
}
