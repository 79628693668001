
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ICustomFormField, ICustomFormModel, ICustomFormModelData, User } from '@shared/model';
import { isArray, find } from 'lodash';
import { LabelService } from '@core/services/labels.service';
import { CustomFormsService } from '@core/services/custom-forms.service';
import { IObjectMap } from '@shared/interface';
import { UserService } from '@core/services/user.service';
import { ExportService } from './export.service';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ExportFormsService {
  global_labels: any = {};
  global_address_and_contact: any = {};

  constructor(
    private labelService: LabelService,
    private customFormsService: CustomFormsService,
    private userService: UserService,
    private exportService: ExportService
  ) {
    this.initLabels();
  }

  async initLabels() {
    this.global_labels = (await this.labelService.getLabels('global-labels')).data;
    this.global_address_and_contact = (await this.labelService.getLabels('global-address-and-contact')).data;
  }

  public async exportForm(formId: string, date: string) {
    const formModel: ICustomFormModel = await this.customFormsService
      .getCustomFormById(formId).pipe(take(1)).toPromise();

    const forms: ICustomFormModelData[] = await this.customFormsService
      .getCustomUserFormsByFormId(formId, date);

    const header: string[] = [
      this.global_address_and_contact.firstname,
      this.global_address_and_contact.lastname,
      this.global_address_and_contact.number,
      this.global_labels.updated,
      this.global_labels.created,
    ];
    const users: IObjectMap<User> = {};

    for (const form of forms) {
      users[form.userId] = await this.userService.getUserById(form.userId).pipe(take(1)).toPromise();
    }

    const rows: string[][] = forms.map(() => []);

    forms.forEach((form: ICustomFormModelData, i: number) => {
      const user: User = users[form.userId];
      if (user) {
        rows[i].push(user.firstname);
        rows[i].push(user.lastname);
        rows[i].push(user.id);
        rows[i].push(format(user.log.createdAt, 'YYYY-MM-DD'));
        rows[i].push(format(user.log.updatedAt, 'YYYY-MM-DD'));
      } else {
        rows[i].push('');
        rows[i].push('');
        rows[i].push('');
        rows[i].push('');
        rows[i].push('');
      }


    });

    formModel.fields.forEach((field: ICustomFormField) => {
      header.push(field.label);
      forms.forEach((form: ICustomFormModelData, i: number) => {
        rows[i].push(this.stringifyValue(form.fields[`${field.fieldName}`]));
      });
    });

    this.exportService.downloadArray([header, ...rows], `${formModel.label} from ${date}`);
  }

  public async exportFormForCustomers(formModel: ICustomFormModel, customerIds: string[]) {
    const usersArr: User[] = await this.userService.getUsersFromIds(customerIds)
      .pipe(take(1)).toPromise();

    const forms: ICustomFormModelData[] = await this.customFormsService
      .getCustomUserFormsByFormId(formModel.id);

    const header: string[] = [
      this.global_address_and_contact.firstname,
      this.global_address_and_contact.lastname,
      this.global_address_and_contact.gender,
      this.global_address_and_contact.birthday,
      this.global_address_and_contact.street,
      this.global_address_and_contact.postalcode,
      this.global_address_and_contact.city,
      this.global_address_and_contact.email,
      this.global_address_and_contact.phone,
      this.global_labels.customer_id
      // this.global_labels.User_created_at
    ];

    const rows: string[][] = usersArr.map(() => []);



    usersArr.forEach((user: User, i: number) => {

      //todo: update address logic in all code
      let address = '';
      if (user.address.street) address += `${user.address.street} `;
      if (user.address.number) address += `${user.address.number} `;
      if (user.address.letter) address += `${user.address.letter} `;

      rows[i].push(user.firstname);
      rows[i].push(user.lastname);
      rows[i].push(user.gender);
      rows[i].push(user.birthday);
      rows[i].push(address);
      rows[i].push(user.address.postalcode);
      rows[i].push(user.address.city);
      rows[i].push(user.email);
      rows[i].push(user.phone ? `'${user.phone}` : '');
      rows[i].push(user.id);
      // rows[i].push(format(user.log.createdAt, 'DD-MM-YYYY'))
    });




    formModel.fields.forEach((field: ICustomFormField) => {
      header.push(field.label);

      usersArr.forEach((user: User, i: number) => {
        const form = find(forms, { userId: user.id });
        if (form) {
          rows[i].push(this.stringifyValue(form.fields[`${field.fieldName}`] || ''));
        } else {
          rows[i].push('');
        }
      });
    });

    const exportName = `${this.global_labels._translate('custom_forms_for_customers', { form: formModel.label })}`;
    this.exportService.downloadArray([header, ...rows], exportName);
  }

  private stringifyValue(val: any) {
    let str: string;
    if (isArray(val)) {
      str = val.join(', ');
    } else if (typeof val === 'boolean') {
      return val ? this.global_labels.yes : this.global_labels.no;
    } else {
      str = `${val}` || '';
    }

    str = str.replace(/(\r\n|\n|\r)/gm, ' ');
    str = str.replace(';', ', ');
    return str;
  }
}
