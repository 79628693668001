import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';
import { map, filter } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/portal';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDialogueComponent, ConfirmDialogConfig } from '@shared/entry-components/confirm-dialogue/confirm-dialogue.component';
import { AlertDialogueComponent } from '@shared/entry-components/alert-dialogue/alert-dialogue.component';
import { PromptDialogueComponent, PromptInputConfig } from '@shared/entry-components/prompt-dialogue/prompt-dialogue.component';
import { ISnackbarInput } from '@shared/entry-components';

export interface IShowDialogConfig {
    width?: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserPromptsService {
    private loading = false;
    public activeMediaQuery = '';
    public activeMediaQuery$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        private media: MediaObserver,
        public dialog: MatDialog,
        private snackBar: MatSnackBar
    ) {
        // set up subscription for the media query
        this.media
            .asObservable()
            .pipe(
                filter((changes: MediaChange[]) => changes.length > 0),
                map((changes: MediaChange[]) => changes[0])
            )
            .subscribe((change: MediaChange) => {
                this.activeMediaQuery = change ? change.mqAlias : '';
                this.activeMediaQuery$.next(this.activeMediaQuery);
            });
    }

    public showLoading() {
        setTimeout(() => {
            this.loading = true;
        }, 0);
    }

    public hideLoading() {
        setTimeout(() => {
            this.loading = false;
        }, 100);
    }

    public isLoading() {
        return this.loading;
    }

    public showToast(message: string, actionText?: string, actionCallbak?: any, dur?: number) {
        const snackbarRef = this.snackBar.open(message, actionText, {
            duration: dur || 4000
        });

        if (actionCallbak) {
            snackbarRef.onAction().subscribe((res) => {
                actionCallbak(res);
            });
        }
    }

    public showToastFromTemplate(
        component: any,
        htmlTemplateStr: string,
        duration?: number
    ) {
        const data: ISnackbarInput = {
            html: htmlTemplateStr,
            duration: duration || 6000 // defaults to 6 seconds
        };

        this.snackBar.openFromComponent(
            component,
            { data, duration: duration || 6000 }
        );
    }

    private getModalHeight(full?: boolean): string {
        const isMobile = this.activeMediaQuery === 'xs';
        let height = 'auto';

        if (full) {
            height = isMobile ? '100%' : '90vh';
        }

        return height;
    }

    public showDialogue<TComponent, TData>(
        dialogComponent: ComponentType<TComponent> | TemplateRef<TComponent>,
        data: TData,
        handler?: any,
        openInFullHeight?: boolean,
        opts?: IShowDialogConfig
    ) {
        opts = opts || {};

        const dialogRef = this.dialog.open(dialogComponent, {
            width: opts.width || '800px',
            height: this.getModalHeight(openInFullHeight),
            maxHeight: this.activeMediaQuery === 'xs' ? '100%' : '90vh',
            maxWidth: this.activeMediaQuery === 'xs' ? '100%' : '90vw',
            data
        });

        dialogRef.afterClosed().subscribe(handler);
    }

    public showConfirmDialogue(title: string, message: string, handler?: any, config?: ConfirmDialogConfig) {
        const dialogRef = this.dialog.open(ConfirmDialogueComponent, {
            width: '400px',
            height: this.getModalHeight(),
            maxWidth: '90vw',
            data: {
                message,
                title,
                config
            }
        });

        dialogRef.afterClosed().subscribe(handler);
    }

    public confirmPromise(title: string, message: string, config?: ConfirmDialogConfig): Promise<boolean> {
        return new Promise(resolve => {
            this.showConfirmDialogue(title, message, res => {
                resolve(res);
            }, config);
        });
    }

    public showPromptDialogue<T>(title: string, message: string, config?: PromptInputConfig): Promise<T> {
        return new Promise(resolve => {
            const dialogRef = this.dialog.open(PromptDialogueComponent, {
                width: '400px',
                height: this.getModalHeight(),
                maxWidth: '90vw',
                data: {
                    message,
                    title,
                    config: config || {}
                }
            });

            dialogRef.afterClosed().subscribe(resolve);
        });
    }

    public showAlertDialogue<T>(title: string, message: string): Promise<T> {
        return new Promise(resolve => {
            const dialogRef = this.dialog.open(AlertDialogueComponent, {
                width: '400px',
                height: this.getModalHeight(),
                maxWidth: '90vw',
                data: {
                    message,
                    title
                }
            });

            dialogRef.afterClosed().subscribe(resolve);
        });
    }

    processAsync(promise: Promise<any>) {
        return new Promise((resolve, reject) => {
            this.showLoading();

            promise
                .then(res => {
                    this.hideLoading();
                    resolve(res);
                })
                .catch(err => {
                    this.hideLoading();
                    reject(err);
                });
        });
    }
}
