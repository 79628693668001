<app-modal>
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{translatedTextWithAddress ? labels.address_duplicate : 
                translatedTextWithEmail ? labels.email_duplicate : labels.name_duplicate}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 10px;">
        <div *ngIf="translatedTextWithEmail || translatedTextWithName">
            <p>{{translatedTextWithEmail || translatedTextWithName}}</p>

            <div fxLayout="column" fxLayoutGap="20px">
                <div class="text-primary">{{labels.some_labels}}</div>

                <div class="details_section" fxLayout="column" fxLayoutGap="5px" *ngFor="let user of data.users">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <span class="info_key">{{labels.address}}</span>
                        <span class="info_value">
                            {{user.address.street}} {{user.address.number}}
                        </span>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px">
                        <span class="info_key">{{labels.birthday}}</span>
                        <span class="info_value">{{getBirthday(user.birthday)}}</span>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px">
                        <span class="info_key">{{labels.phone}}</span>
                        <span class="info_value">{{user.phone}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="translatedTextWithAddress">
            <p>{{translatedTextWithAddress}}</p>

            <div fxLayout="column" fxLayoutGap="20px">
                <div class="text-primary">{{labels.users_with_address}}</div>

                <div class="details_section" fxLayout="column" fxLayoutGap="5px" *ngFor="let user of data.users">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <span class="info_key">{{labels.name}}</span>
                        <span class="info_value">
                            {{user.firstname}} {{user.lastname}}
                        </span>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px">
                        <span class="info_key">{{labels.birthday}}</span>
                        <span class="info_value">{{getBirthday(user.birthday)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <button (click)="continue()" color="primary" mat-raised-button>{{labels.ok}}</button>
        </div>
    </div>
</app-modal>