<app-modal>
  <div class="modal_header" #modal_header>
    <div fxLayout="row">
      <div fxFlex="auto" fxLayoutAlign="start center">
        <h3>
          {{customForm.label}} <br />
          <span class="fullname">{{customer?.fullname}}</span>
        </h3>
      </div>
    </div>
  </div>

  <div class="modal_body" #modal_body>
    <div class="content" id="form_print_area">
      <div class="d-print-block" *ngIf="customer">
        <h4>{{customer.fullname}}</h4>
        <table>
          <tr>
            <td>{{labels.column_address}}  </td>
            <td>{{customer.address?.postalcode}} {{customer.address?.city}}, {{customer.address?.street}},
              {{customer.address?.number}}</td>
          </tr>
          
          <tr>
            <td>{{labels.column_gender}}</td>
            <td>{{ labels[customer.gender]}}</td>
          </tr>
          <tr>
            <td>{{labels.column_birthday}}</td>
            <td>{{customer.birthday}}</td>
          </tr>
          <tr>
            <td>{{labels.column_phone}}</td>
            <td>{{customer.phone}} {{customer.phoneRemark}}</td>
          </tr>
          <tr>
            <td>{{labels.email}}</td>
            <td>{{customer.email}}</td>
          </tr>
          <tr *ngIf="this.userForm.id">
            <td>{{labels.createdAt}}   </td>
            <td>{{userForm.log.createdAt | date:'medium'}}</td>
          </tr>
        </table>
      </div>

      <div class="d-print-none">
        <app-custom-form-group 
          *ngIf="userForm" 
          (formChanged)="onCustomFormUpdated($event)" 
          [customForm]="customForm"
          [formValues]="userForm.fields">
        </app-custom-form-group>
      </div>

      <div class="d-print">
        <app-custom-form-group-print 
          *ngIf="userFormForPrint && customForm" 
          [customForm]="customForm"
          [formValues]="userFormForPrint.fields">
        </app-custom-form-group-print>
      </div>
    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <button mat-button (click)="print()" *ngIf="usePrintBtn">
      <mat-icon>print</mat-icon>
      {{labels.btn_print}}
    </button>
    <button mat-button (click)="dialogRef.close()">{{labels.cancel}}</button>
    <button mat-raised-button color="primary" (click)="save()" *ngIf="useSaveBtn">
      {{labels.save}}
    </button>
  </div>
</app-modal>