import { User } from './user';

export interface ISearchableViewUser {
  source: User;
  search: { fullName: string; district: string; offers: string };
  id: string;
  picture: string;
  firstName: string;
  lastName: string;
  fullName: string;
  offers: string;
}

export class SearchableViewUser implements ISearchableViewUser {
  readonly search: { fullName: string; district: string; offers: string };
  constructor(public source: User) {
    this.search = Object.create(null);
    this.search.fullName = this.fullName.toLowerCase();
    this.search.offers = this.offers.toLowerCase();
    this.search.district = this.district.toLowerCase();
  }

  get id(): string {
    return this.source.id || '';
  }
  get picture(): string {
    return this.source.picture || '';
  }
  get lastName(): string {
    return this.source.lastname || '';
  }
  get fullName() {
    // this.employeeDetails.offers.
    return this.source.fullname || '';
  }
  get firstName() {
    return this.source.firstname || '';
  }

  get district() {
    return this.source.area?.district?.text || '';
  }
  get offers(): string {
    if (this.source.employeeDetails) {
      return this.source.employeeDetails.offers || '';
    }
    return '';
  }
}
