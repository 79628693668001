<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel_title" fxLayoutGap="15px" fxLayoutAlign="start center">
                <mat-icon>touch_app</mat-icon>
                <span fxFlex="stretch">{{labels.network_partner}}</span>
                <span>{{networkPartnersTableConfig?.data.length || 0 | number}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="column">
            <data-table *ngIf="networkPartnersTableConfig?.data.length > 0" [configOptions]="networkPartnersTableConfig"
                (onSelectRow)="handleRowSelect($event)">
            </data-table>

            <div *ngIf="networkPartnersTableConfig?.data.length === 0" class="empty-list">
                {{ labels.empty_network_partners }}
            </div>

            <div fxLayoutAlign="flex-end" fxFlex="stretch">
                <button mat-raised-button color="primary" (click)="addNetworkPartner()">{{labels.add_networkpartner}}</button>
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel_title" fxLayoutGap="15px" fxLayoutAlign="start center">
                <mat-icon>people</mat-icon>
                <span fxFlex="stretch">{{labels.social_network}}</span>
                <span>{{(socialRelationsTableConfig?.data.length || 0) | number}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="column">
            <data-table *ngIf="socialRelationsTableConfig?.data.length > 0" [configOptions]="socialRelationsTableConfig"
                (onSelectRow)="handleRowSelect($event)">
            </data-table>

            <div *ngIf="socialRelationsTableConfig?.data.length === 0" class="empty-list">
                {{ labels.empty_social_network }}
            </div>

            <div fxLayoutAlign="flex-end" fxFlex="stretch">
                <button mat-raised-button color="primary" (click)="addSocialNetwork()">{{labels.add_sacialNetwork}}</button>
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel_title" fxLayoutGap="15px" fxLayoutAlign="start center">
                <mat-icon>store_mall_directory</mat-icon>
                <span fxFlex="stretch">{{labels.institutions}}</span>
                <span>{{(networkInstitutionsTableConfig?.data.length || 0) | number}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="column">
            <data-table *ngIf="networkInstitutionsTableConfig?.data.length > 0"
                [configOptions]="networkInstitutionsTableConfig" (onSelectRow)="handleRowSelect($event)">
            </data-table>

            <div *ngIf="networkInstitutionsTableConfig?.data.length === 0" class="empty-list">
                {{ labels.empty_network_institutions }}
            </div>

            <div fxLayoutAlign="flex-end" fxFlex="stretch">
                <button mat-raised-button color="primary" (click)="addNetworkInstitution()">{{labels.add_institution}}</button>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>