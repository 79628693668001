<app-modal>
    <div class="modal_header" #modal_header fxLayout="column">
        <div class="modal_title">{{labels.open_requests}}</div>
        <div class="modal_subtitle">{{userName}}</div>
    </div>

    <div class="modal_body" #modal_body>
        <div class="content">
            <div *ngIf="dataTableConfig?.data?.length">
                <data-table [configOptions]="dataTableConfig" (onSelectRow)="handleRowSelect($event)">
                </data-table>
            </div>

            <div *ngIf="!dataTableConfig?.data?.length">
                <app-empty-list [title]="labels.no_customer" [description]="labels.this_user_has_no_customer">
                </app-empty-list>
            </div>
        </div>
    </div>
</app-modal>