import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService, UserPromptsService, UserService } from '@core/services';
import { IObjectMap } from '@models/interface';
import { DailyAvailabilitySession, defaultUserAvailability, User, UserAvailabilityTypes, UserDailyAvailability } from '@models/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { format } from 'date-fns';
import { keys } from 'lodash';
import { NgSub } from 'ng-sub';

@Component({
  selector: 'app-user-availability-modal',
  templateUrl: './user-availability-modal.component.html',
  styleUrls: ['./user-availability-modal.component.scss']
})
export class UserAvailabilityModalComponent extends ModalComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public user: User;
  public weekdays: { id: number; name: string; prop: string; }[] = [];
  public availabilityDays: IObjectMap<string[]> = {};
  public availabilitySessions = ['morning', 'afternoon', 'evening', 'night'];
  public availabilityStatus: { status: string, id: number }[] = [];
  private sub = new NgSub();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<UserAvailabilityModalComponent>,
    private labelService: LabelService,
    private userService: UserService,
    private userPromptsService: UserPromptsService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    this.user = this.data.user;
    this.dialogRef.disableClose = true;

    this.labelService.getWeekdayList().then(w => {
      // changes order so that monday is first and sunday is last
      this.weekdays = w.slice(1).concat([w[0]]);
    });

    this.labels = (await this.labelService.getLabels('app-user-availability-modal')).data;

    if (!this.user.availability) {
      this.user.availability = defaultUserAvailability();
    }

    this.prepareData();

    for (const status in UserAvailabilityTypes) {
      if (isNaN(Number(status))) {
        this.availabilityStatus.push({ status: this.labels[status], id: parseInt(UserAvailabilityTypes[status]) });
      }
    }
  }

  public setCurrentDate(id: number) {
    if (id === UserAvailabilityTypes.notAvailable && !this.user.availability.notAvailableUntil) {
      this.user.availability.notAvailableUntil = format(new Date(), 'YYYY-MM-DD');
    }
  }

  private prepareData(): void {
    this.availabilityDays = {};
    keys(this.user.availability.days).forEach(day => {
      const val = this.user.availability.days[day] as UserDailyAvailability;

      this.availabilityDays[day] = this.availabilitySessions.map(s => {
        if (s === 'morning' || s === 'afternoon') {
          return s;
        }
        return (val[s] as DailyAvailabilitySession).available ? s : null;
      }).filter(f => f);
    });
  }

  public async saveAvailability() {
    this.weekdays.forEach(w => {
      const item = this.user.availability.days[w.prop] as UserDailyAvailability;

      // ensures that availability is always false when no period is selected
      if (this.availabilityDays[w.prop].length === 0) {
        item.available = false;
      }

      // updates user availability from model
      this.availabilitySessions.forEach(session => {
        // ensures that all sessions are always false when availability is false
        item[session].available = item.available ? this.availabilityDays[w.prop].indexOf(session) >= 0 : false;
      });
    });
    if (this.user.availability.availability === UserAvailabilityTypes.available) this.user.availability.notAvailableUntil = null;

    await this.userService.updateUser(this.user);
    this.userPromptsService.showToast(this.labels.availability_updated);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
