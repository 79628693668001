<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.membership}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 1em;">

        <form *ngIf="form" [formGroup]="form">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.from}}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="from">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{labels.until}}</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="until">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-select formControlName="membershipKind">
                    <mat-option *ngFor="let membership of memberships" [value]="membership">
                        {{membership}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px">
        <button [disabled]="form?.invalid" mat-raised-button color="primary" (click)="save()">
            {{labels.save}}
        </button>
    </div>
</app-modal>