import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LabelService, OrganizationService, UserPromptsService } from '@core/services';
import { FindCompanyService } from '@core/services/find-company.service';
import { Service, User } from '@models/model';
import { FindCompanyUser, UserStatusTypes } from '@models/model/find-company';
import { NgSub } from 'ng-sub';

@Component({
  selector: 'app-find-company-request',
  templateUrl: './find-company-request.component.html',
  styleUrls: ['./find-company-request.component.scss']
})
export class FindCompanyRequestComponent implements OnInit, OnDestroy {
  @Input() customer: User;
  @Input() service: Service;

  public labels = this.labelService.defaultProvider();
  public loading = true;
  public user: FindCompanyUser;
  public UserStatusTypes = UserStatusTypes;
  public cardRowConfig: { height: number; cols: number; };
  private sub = new NgSub();
  protected isMobile = false;

  constructor(
    private labelService: LabelService,
    private findCompanyService: FindCompanyService,
    private orgService: OrganizationService,
    private userPromptService: UserPromptsService
  ) { }

  async ngOnInit() {
    this.fetchData();

    this.labels = (await this.labelService.getLabels('app-find-company-request')).data;
    this.sub.add(
      this.userPromptService.activeMediaQuery$.subscribe(m => {
        this.updateCardRowData(m);
      })
)
  }

  private async fetchData() {
    if (this.customer.email) {
      const users = await this.findCompanyService.getUsersForService(this.service.id, this.customer.email);

      if (users.length > 1) {
        console.error('Unintentional Error at "GetFindCompanyUserByEmail" found multiple users');
      }

      this.user = users[0];
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  public activateFindCompany(): void {
    const findCompanyUser: FindCompanyUser = {
      firstName: this.customer.firstname,
      lastName: this.customer.lastname,
      postalcode: this.customer.address.postalcode,
      housenumber: this.customer.address.number,
      email: this.customer.email,
      companyId: this.orgService.currentOrgId,
      serviceId: this.service.id || '',
      photoUrl: this.customer.picture || 'https://volunteer4work.web.app/assets/images/user.png',
      profileBgImg: 'https://find-company-9c35b.web.app/assets/images/bg.jpeg',
      status: this.UserStatusTypes.created,
      gender: this.customer.gender,
      location: this.customer.address.geo,
      phoneNumber: this.customer.phone || this.customer.phone2,
      meta: {
        language: 'nl'
      },
      dateOfBirth: this.customer.birthday,
    };

    this.loading = true;
    this.findCompanyService.createOrUpdateUser(findCompanyUser).then(_d => {
      findCompanyUser.status = this.UserStatusTypes.invited;

      this.user = findCompanyUser;
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  }

  public resendInvite(): void {
    this.loading = true;
    this.findCompanyService.resendInviteToUser(this.user).then(() => {
      this.userPromptService.showToast(this.labels.invite_sent);
      this.loading = false;
    }).catch(e => {
      console.log(e);
      this.userPromptService.showToast(this.labels.error_encountered);
      this.loading = false;
    });
  }

  public async changePhoneNumber(user: FindCompanyUser) {
    const phone: string = await this.userPromptService.showPromptDialogue(
      this.labels.phone_number,
      this.labels.edit_phone_text,
      {
        required: false,
        initialValue: user.phoneNumber,
        type: 'phone'
      },
    );

    if (phone) {
      this.loading = true;

      await this.findCompanyService.changeUserPhoneNumber(user, phone);
      this.loading = false;
      user.phoneNumber = phone;
    }
  }

  public deleteUser(profile: FindCompanyUser): void {
    this.userPromptService.showPromptDialogue(
      this.labels.confirm_action,
      this.labels._translate('sure_to_delete_user', { text: profile.firstName }),
      { required: false, requireMatchText: profile.firstName }
    ).then(async (res) => {
      if (res) {
        this.loading = true;
        await this.findCompanyService.deleteUser(profile);

        // this.users = this.users.filter(u => u.id !== profile.id);
        this.loading = false;
      }
    });
  }

  private updateCardRowData(alias: string): void {
    switch (alias) {
      case 'lg': {
        this.cardRowConfig = { height: 250, cols: 4 };
        break;
      }

      case 'md': {
        this.cardRowConfig = { height: 250, cols: 3 };
        break;
      }

      case 'sm': {
        this.cardRowConfig = { height: 250, cols: 2 };
        this.isMobile = true;
        break;
      }

      default: {
        this.cardRowConfig = { height: 250, cols: 1 };
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
