<app-modal>
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
    <span class="modal_title">
      {{labels.export_requests}}
    </span>
  </div>

  <div class="modal_body" #modal_body>
    <div class="filters">
      <div *ngIf="searchQuery.search">
        <span class="label">Filters</span>
        <span class="value">{{searchQuery?.search}}</span>
      </div>
      <div *ngIf="searchQuery.serviceId">
        <span class="label">{{labels.export_service}}</span>
        <span class="value">{{service?.name}}</span>
      </div>
      <div *ngIf="searchQuery.workareaId">
        <span class="label">{{labels.workingArea}}</span>
        <span class="value">{{ workarea?.name}}</span>
      </div>
      <div *ngIf="searchQuery.coordinatorId">
        <span class="label">{{labels.coordinator}}</span>
        <span class="value">{{ coordinator?.fullname}}</span>
      </div>
      <div *ngIf="searchQuery.executorId">
        <span class="label">{{labels.executor}}</span>
        <span class="value">{{executor?.fullname}}</span>
      </div>
      <div *ngIf="searchQuery.municipality">
        <span class="label">{{labels.municipality}}</span>
        <span class="value">{{searchQuery.municipality.text}}</span>
      </div>
      <div *ngIf="searchQuery.isClosed">
        <span class="label">{{labels.show_requests}}</span>
        <span class="value">{{labels[searchQuery?.isClosed]}}</span>
      </div>
      <div *ngIf="searchQuery.status">
        <span class="label">Status</span>
        <span class="value">{{labels[searchQuery?.status]}}</span>
      </div>
      <div *ngIf="searchQuery.activeinYear">
        <span class="label">{{labels.request_in_year}}</span>
        <span class="value">{{searchQuery?.activeinYear}}</span>
      </div>
    </div>

    <div class="export-section">
      <button mat-raised-button (click)="exportServiceRequests()">
        {{labels.export_service_requests}} ({{requestsCount}})
      </button> - 
      <button mat-raised-button (click)="exportServiceRequests(true)">
        {{labels.export_spiegel}} ({{requestsCount}})
      </button>
    </div>
    <div class="export-section">
      <button mat-raised-button (click)="exportCustomers()">
        {{labels.export_customers}} ({{customersCount}})
      </button>
    </div>
    <div class="export-section" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <div>
        <button mat-raised-button [disabled]="!selectedForm" (click)="exportForms()">
          {{labels.export_custom_forms}} ({{customersCount}})
        </button>
      </div>

      <mat-form-field appearance="outline" fxFlex="200px">
        <mat-label>{{labels.select_custom_form}}</mat-label>
        <mat-select [(ngModel)]="selectedForm">
          <mat-option [value]="">{{labels.select_custom_form}}</mat-option>
          <mat-option *ngFor="let form of customForms" [value]="form">
            {{form.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="export-section">
      <button mat-raised-button (click)="exportVolunteer()">
        {{labels.export_executors}} ({{executorsCount}})
      </button>
    </div>
    <mat-progress-bar mode="indeterminate" [style.opacity]="isGenerating ? 1 : 0"></mat-progress-bar>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
  </div>
</app-modal>