import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService, UserService } from '@core/services';
import { InstitutionInfoItem, User } from '@models/model';
import { IDataTableConfig, ITableData } from '@shared/components';
import { Sub } from '@shared/subscriptions';

@Component({
  selector: 'app-contact-person-selector',
  templateUrl: './contact-person-selector.component.html',
  styleUrl: './contact-person-selector.component.scss'
})
export class ContactPersonSelectorComponent implements OnInit{

  public labels = this.labelService.defaultProvider();
  private contactPersons: User[];
  protected isLoading = true;
  public dataTableConfig: IDataTableConfig;
  public form: FormGroup;
  protected sub = new Sub();

  constructor(
    public dialogRef: MatDialogRef<ContactPersonSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {referredByInstitution?: InstitutionInfoItem },
    private labelService: LabelService,
    private userService: UserService,
    private fb: FormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-institution-and-contact-person-selector')).data;
    this.fetchInstitutionPerson();
    this.setupForm();
  }

  private setupForm() {
    this.form = this.fb.group({
      search: ['']
    });

    this.sub.add(this.form.get('search').valueChanges.subscribe(text => this.filterInstitution(text)));
  }

  private fetchInstitutionPerson() {
    this.userService.getInstitutionContactPersons(this.data.referredByInstitution.id).subscribe(contactPersons => {
      this.contactPersons = contactPersons;
      this.updateTableConfig(contactPersons);
    })
  }

  protected clearSearch() {
    this.form.get('search').value('');
  }
  
  private filterInstitution(text: string) {
    this.isLoading = true;
    const filtered = this.contactPersons.filter(person => person.firstname.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
    if (text) this.updateTableConfig(filtered);
    else this.updateTableConfig(this.contactPersons);
  }

  private updateTableConfig(users: User[]) {
    this.dataTableConfig = {
      data: users.map(user => {
        return {
          name: user.firstname + ' ' + user.lastname,
          phone: user.phone || user.phone2,
          address: user.addressline,
          picture: {
            title: user.picture || '/assets/images/user.png',
            width: '80px'
          },
          _metadata: {
            originalData: user
          }
        };
      }),
      displayProperties: ['picture', 'name', 'phone', 'address'],
      headers: {},
      rowOptions: [],
      allowSelection: false,
      displayHeaders: false,
      propertyWithImage: 'picture'
    };
    this.isLoading = false;
  }
  
  protected handleRowSelect(payload: ITableData) {
    const data = payload._metadata.originalData;
    this.dialogRef.close(data);
  }
}
