import { OnDestroy, ViewRef, ChangeDetectorRef, Injector, Type, Inject, Component } from '@angular/core';
import { Subject, from, Observable } from 'rxjs';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { LabelService } from './services';
import { take, takeUntil, pluck, map, share } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-base-component2',
    template: '<h1>Base component2</h1>',
})

export class BaseComponent implements OnDestroy {
    protected destructor = new Subject();
    protected cd: ChangeDetectorRef & ViewRef;
    public labels: ILabelsProvider = Object.create(null);
    public labels$: Observable<ILabelsProvider>;
    protected labelService: LabelService;

    constructor(
        protected injector: Injector,
        @Inject(MAT_DIALOG_DATA) public labelKey?: string,
        @Inject(MAT_DIALOG_DATA) protected skipDetection: boolean = false,
        @Inject(MAT_DIALOG_DATA) protected autoSubscribeLabels: boolean = true,

    ) {
        this.cd = injector.get(ChangeDetectorRef as Type<ChangeDetectorRef & ViewRef>);
        this.labelService = injector.get<LabelService>(LabelService);

        if (labelKey) {
            this.labels$ = from(this.labelService.getLabels(labelKey)).pipe(
                takeUntil(this.destructor),
                take(1),
                pluck('data'),
                map(labels => {
                    this.labels = labels;
                    if (!this.skipDetection) {
                        this.detectChanges();
                    }

                    return labels;
                }),
                share()
            );

            if (autoSubscribeLabels) {
                this.labels$.subscribe();
            }
        }
    }

    ngOnDestroy(): void {
        this.destructor.next();
        this.destructor.complete();
    }
    protected detectChanges(): void {
        if (!this.cd.destroyed) {
            this.cd.detectChanges();
        }
    }
}
