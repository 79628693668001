<app-modal>
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
    <div class="user-picture" fxFlex="100px">
      <img class="picture" [src]="user?.picture || '/assets/images/user.png'" alt=""
        onImgError="/assets/images/user.png">
    </div>
    <div class="user-fullname" fxFlex="grow">
      {{user?.fullname ? user?.fullname : user?.firstname + ' ' + user?.lastname}}
      <span style="background-color: red" *ngIf="user?.decedent"> -
        {{labels.decedent}} -
      </span>
    </div>

    <div class="user-actions" fxFlex="50px">
      <button class="actions-button" mat-icon-button (click)="openEditUserDialog()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>

  <div class="modal_body" #modal_body>
    <app-personal-details [user]="user"></app-personal-details>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px"> </div>
</app-modal>