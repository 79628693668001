import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Activity } from '@models/model';
import { LabelService } from '@core/services';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-visible-for-dialog',
  templateUrl: './visible-for-dialog.component.html',
  styleUrl: './visible-for-dialog.component.scss'
})
export class VisibleForDialogComponent implements OnInit {

  protected activity: Activity;
  public labels: any = {};
  private default = {
    servicePoint: false,
    executor: false,
    coordinator: true,
    professional: true,
    institutionContact: false,
    customer: false
  }
  protected isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { activity: Activity },
    public dialogRef: MatDialogRef<VisibleForDialogComponent>,
    private labelService: LabelService
  ) { }

  async ngOnInit(): Promise<void> {
    this.activity = cloneDeep(this.data.activity);
    this.activity.visibleFor = this.activity.visibleFor || this.default;
    delete this.activity['__doc'];
    this.labels = (await this.labelService.getLabels('app-visible-for-dialog')).data;
  }

  protected save() {
    this.isLoading = true;
    this.dialogRef.close(this.activity);
  }
}
