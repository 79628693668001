import { map, mergeAll } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { IObjectMap } from 'app/shared/interface';
import firebase from 'firebase/compat/app';
import {
    AngularFirestore,
    AngularFirestoreDocument,
    AngularFirestoreCollection,
    Query,
} from '@angular/fire/compat/firestore';
import { SearchIndexService } from './index.service';
import { chunk } from 'lodash';
import { DocumentLog } from '@shared/model';
import { AnalyticsService } from './analytics/analytics.service';
import { TimestampDate } from 'timestamp-date';
import { collection, CollectionReference, DocumentData, Firestore, getCountFromServer, getFirestore, query, QueryConstraint, where, Query as QueryNew, collectionSnapshots, DocumentReference, doc as document, docData, getDocsFromServer, orderBy, startAfter, endBefore, limit, startAt, limitToLast, collectionGroup } from '@angular/fire/firestore';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';

type collectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

export type QueryFN = (ref: Query) => Query;

export interface DocItem {
    id?: string;
    log: DocumentLog;
    [key: string]: any;
}

@Injectable({
    providedIn: 'root',
})
export class FirestoreService {
    private BATCH_SIZE = 500;
    private timestampDate = new TimestampDate();
    private firestore: Firestore;

    constructor(
        private afs: AngularFirestore,
        private localStorageService: LocalStorageService,
        private searchIndexService: SearchIndexService,
        private analytics: AnalyticsService,
    ) { 
        this.firestore = getFirestore();
    }

    col<T>(ref: collectionPredicate<T>, queryfn?): AngularFirestoreCollection<T> {
        return typeof ref === 'string' ? this.afs.collection<T>(ref, queryfn) : ref;
    }

    doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
        return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
    }

    doc$<T>(ref: DocPredicate<T>): Observable<T> {
        return this.doc(ref).snapshotChanges().pipe(map((doc) => {
            let data;
            if (doc.payload.exists) {
                data = doc.payload.data();
            }
            return data as T;
        }));
    }

    docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
        return this.doc(ref).snapshotChanges().pipe(map((doc) => {
            let data;
            if (doc.payload.exists) {
                data = doc.payload.data();
                data.id = doc.payload.id;
            }

            data = this.timestampDate.parseTimestampToDate(data);
            data = this.timestampDate.parseStringToDate(data);

            // log doc fetch analytics event
            this.analytics.logEvent({
                type: 'db_document_fetch',
                note: `Document with ID: ${ref.toString()}`,
            });

            return data as T;
        }));
    }

    docsFromId$<T>(ref: collectionPredicate<T>, ids: string[]): Observable<T[]> {
        if (ids.length === 0) {
            return of([]);
        } else {
            const colRef = this.col(ref).ref.path;
            const refs = ids.map(id => `/${colRef}/${id}`);

            return this.docsFromRefs$(refs);
        }
    }

    docsFromRefs$<T>(docRefs: DocPredicate<T>[]): Observable<T[]> {
        const subjects: Observable<T>[] = docRefs.map(ref => {
            ref = this.doc(ref).ref.path;
            return this.docWithId$(ref) as Observable<T>;
        });

        return combineLatest(
            [...subjects]
        );
    }

    cols$<T>(ref: collectionPredicate<T>, queryfn?): Observable<T[]> {
        return this.col(ref, queryfn).snapshotChanges().pipe(map(docs => {
            // log fetch analytics event
            this.analytics.logEvent({
                type: 'db_collection_fetch',
                note: `Collection fetched ${docs.length || 1} docs at ${ref.toString()}`,
            });

            return docs.map((doc) => {
                return doc.payload.doc.data();
            }) as T[];
        }));
    }

    public colGroupWithIds$<T>(collectionId: string, queryfn?: QueryFN, addDocRef?: boolean): Observable<T[]> {
        return this.afs.collectionGroup(collectionId, queryfn).snapshotChanges().pipe(map(docs => {
            // log fetch analytics event
            this.analytics.logEvent({
                type: 'db_collection_fetch',
                note: `Collection group fetched ${docs.length || 1} docs at ${collectionId}`,
            });

            return docs.map((doc) => {
                let data: any = doc.payload.doc.data();
                data.id = doc.payload.doc.id;

                data = this.timestampDate.parseTimestampToDate(data);
                data = this.timestampDate.parseStringToDate(data);

                if (addDocRef) {
                    data.__doc = doc.payload.doc;
                }

                return data;
            }) as T[];
        }));
    }

    public colWithIds$<T>(ref: collectionPredicate<T>, queryfn?: QueryFN, addDocRef?: boolean): Observable<T[]> {
        return this.col(ref, queryfn).snapshotChanges().pipe(map(docs => {
            // log fetch analytics event
            this.analytics.logEvent({
                type: 'db_collection_fetch',
                note: `Collection fetched ${docs.length || 1} docs at ${ref.toString()}`,
            });

            return docs.map((doc) => {
                let data: any = doc.payload.doc.data();
                data.id = doc.payload.doc.id;

                data = this.timestampDate.parseTimestampToDate(data);
                data = this.timestampDate.parseStringToDate(data);

                if (addDocRef) {
                    data.__doc = doc.payload.doc;
                }

                return data;
            }) as T[];
        }));
    }

    public async colWithIdsNoCache<T>(ref: string, queryfn?: QueryFN, addDocRef?: boolean): Promise<T[]> {
        let query: Query = firebase.firestore().collection(ref);

        if (queryfn) {
            query = queryfn(query);
        }

        const snapshots = await query.get({ source: 'server' });
        const docs = [];

        // log fetch analytics event
        this.analytics.logEvent({
            type: 'db_collection_fetch',
            note: `Collection (no cache) fetched ${docs.length || 1} docs at ${ref.toString()}`,
        });

        snapshots.forEach((doc) => {
            let data: any = doc.data();
            data.id = doc.id;

            data = this.timestampDate.parseTimestampToDate(data);
            data = this.timestampDate.parseStringToDate(data);

            if (addDocRef) {
                data.__doc = doc;
            }

            docs.push(data);
        });

        return docs;
    }

    public async colGroupWithIdsNoCache<T>(collectionId: string, queryfn?: QueryFN, addDocRef?: boolean): Promise<T[]> {
        let query: Query = firebase.firestore().collectionGroup(collectionId);

        if (queryfn) {
            query = queryfn(query);
        }

        const snapshots = await query.get({ source: 'server' });
        const docs = [];

        // log fetch analytics event
        this.analytics.logEvent({
            type: 'db_collection_fetch',
            note: `Collection group (no cache) fetched ${docs.length || 1} docs at ${collectionId}`,
        });

        snapshots.forEach((doc) => {
            let data: any = doc.data();
            data.id = doc.id;

            data = this.timestampDate.parseTimestampToDate(data);
            data = this.timestampDate.parseStringToDate(data);

            if (addDocRef) {
                data.__doc = doc;
            }

            docs.push(data);

        });

        return docs;
    }

    public batchUpdate<T>(docs: DocItem[], colRef: collectionPredicate<T>): Promise<any> {
        return Promise.all(chunk(docs, this.BATCH_SIZE).map(async (batchData) => {
            const batch = firebase.firestore().batch();

            batchData.forEach(data => {
                data.log = this.ensureLog(data);

                const docRef = `${colRef}/${data.id}`;
                console.log(`Updated req: ${data.title}, refz: ${docRef}`);
                data = this.searchIndexService.updateSearchIndex(data);
                batch.update(firebase.firestore().doc(docRef), data);
            });

            return batch.commit();
        }));
    }

    public batchDelete<T>(docIds: string[], colRef: collectionPredicate<T>): Promise<any> {
        return Promise.all(chunk(docIds, this.BATCH_SIZE).map(async (batchData) => {
            const batch = firebase.firestore().batch();

            batchData.forEach(id => {
                const docRef = `${colRef}/${id}`;
                batch.delete(firebase.firestore().doc(docRef));
            });

            return batch.commit();
        }));
    }

    public batchSet<T>(docs: DocItem[], colRef: collectionPredicate<T>): Promise<any> {
        return Promise.all(chunk(docs, this.BATCH_SIZE).map(async (batchData) => {
            const batch = firebase.firestore().batch();

            batchData.forEach(data => {
                data.log = this.ensureLog(data);

                const docRef = `${colRef}/${data.id}`;
                batch.set(firebase.firestore().doc(docRef), data);
            });

            return batch.commit();
        }));
    }

    public add(colRef: collectionPredicate<any>, data: IObjectMap<any>): Promise<any> {
        data = this.searchIndexService.updateSearchIndex(data);
        data.log = this.ensureLog(data);

        return this.col(colRef).add(data);
    }

    public getServerTime(): any {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    public remove(ref: DocPredicate<any>): Promise<any> {
        return this.doc(ref).delete();
    }

    public update(docRef: DocPredicate<any>, data: DocItem): Promise<any> {
        data.log = this.ensureLog(data);
        data = this.searchIndexService.updateSearchIndex(data);

        return this.doc(docRef).update(data);
    }

    private ensureLog(data: { log?: DocumentLog }): DocumentLog {
        const currentUserId = this.localStorageService.getItemSync('user_id');
        if (data.log) {
        // todo: migration to update users with only updated at prop. because of issue we had before.
            data.log.updatedAt = this.getServerTime();
            data.log.modifiedBy = currentUserId;
        } else {
            data.log = {
                createdAt: this.getServerTime(),
                updatedAt: this.getServerTime(),
                createdBy: currentUserId,
                modifiedBy: currentUserId
            }
        }

        return data.log;
    }

    public createLog(): DocumentLog {
        return this.ensureLog({});
    }

    public getNewId(): string {
        return this.afs.createId();
    }

    public setDoc(docRef: DocPredicate<any>, data: DocItem): Promise<any> {
        data.log = this.ensureLog(data);
        data = this.searchIndexService.updateSearchIndex(data);

        return this.doc(docRef).set(data);
    }

    // public timestampToDate(doc: IObjectMap<any>): IObjectMap<any> {
    //     if (doc) {
    //         if (Array.isArray(doc)) {
    //             doc = doc.map(this.checkPropertyValue.bind(this));
    //         }

    //         if (doc.constructor.name === 'Object') {
    //             Object.keys(doc).forEach(key => {
    //                 doc[key] = this.checkPropertyValue(doc[key]);
    //             });
    //         }
    //     }

    //     return doc;
    // }

    // private checkPropertyValue(element: any) {
    //     if (element) {
    //         if ((element._seconds >= 0 || element.seconds >= 0) &&
    //             (element._nanoseconds >= 0 || element.nanoseconds >= 0)) {
    //             element = new Date((element._seconds || element.seconds) * 1000);
    //         } else {
    //             element = timestampToDate(element);
    //         }
    //     }

    //     return element;
    // }

    private colNew(ref: string): CollectionReference<DocumentData> {
        return collection(this.firestore, ref);
    }

    private docNew(ref: string): DocumentReference<DocumentData> {
        return document(this.firestore, ref);
    }

    public docWithIdNew$<T = any>(ref: string): Observable<T> {
        return docData(this.docNew(ref)).pipe(
            map((doc) => {
                return this.timestampDate.parseTimestampToDate(doc);
            }),
        );
    }

    public async ColWithIdsNoCacheNew<T = any>(ref: string, queryFn: () => Where[] = () => [], opts: QueryConstraintOptions<T> = {}): Promise<T[]> {
        const q: QueryNew<DocumentData> = query(this.colNew(ref), ...this.wheres(queryFn()).concat(this.queryConstraintOptions(opts)));

        const s = (await getDocsFromServer(q)).docs;
        const docs: T[] = [];
        s.forEach((doc) => {
            if (doc.exists()) {
                const data: any = doc.data();
                data.id = doc.id;
                docs.push(this.timestampDate.parseTimestampToDate(data));
            }
        });
        // const q = query(this.colNew(ref), ...this.wheres(queryFn()));
        // const querySnapshot = await getDocs(q);
        // const docs: T[] = [];
        // querySnapshot.forEach(doc => {
        //     if (doc.exists()) {
        //         docs.push(doc.data() as T);
        //     }
        // });
        return docs;
    }

    private wheres(args: Where[]): QueryConstraint[] {
        return args.map(arg => {
            return where(arg[0], arg[1], arg[2]);
        });
    }

    private queryConstraintOptions<T>(opts: QueryConstraintOptions<T>) {
        const filters: QueryConstraint[] = []
        if (opts.orderBy) {
            for (const ord of opts.orderBy) {
                filters.push(orderBy(ord.field, ord.val));
            }
        }

        if (opts.startAfter) {
            filters.push(startAfter(opts.startAfter));
        }

        if (opts.endBefore) {
            filters.push(endBefore(opts.endBefore));
        }
        if (opts.limit) {
            filters.push(limit(opts.limit));
        }
        if (opts.startAt) {
            filters.push(startAt(opts.startAt));
        }
        if (opts.limitToLast) {
            filters.push(limitToLast(opts.limitToLast))
        }
        return filters;
    }

    public async getCounts(ref: string, queryFn?: () => Where[], colGroup: boolean = false): Promise<number> {
        let colRef: QueryNew<DocumentData>;
        if (colGroup) {
            colRef = query(collectionGroup(this.firestore, ref), ...this.wheres(queryFn()))
        } else {
            if (queryFn) {
                colRef = query(this.colNew(ref), ...this.wheres(queryFn()));
            } else {
                colRef = this.colNew(ref);
            }
        }
        return (await getCountFromServer(colRef)).data().count;
    }

    public colWithIdsNew$<T = any>(ref: string, queryFn: () => Where[] = () => [], opts: QueryConstraintOptions<T> = {}, addDocRef?: boolean): Observable<T[]> {
        const colRef: QueryNew<DocumentData> = query(this.colNew(ref), ...this.wheres(queryFn()).concat(this.queryConstraintOptions(opts)));

        return collectionSnapshots(colRef).pipe(map(data => {
            return data.map(doc => {
                let data = doc.data() as T;
                data = this.timestampDate.parseTimestampToDate(data);
                data = this.timestampDate.parseStringToDate(data);
                if (addDocRef) (data as any).__doc = doc;
                return data;
            })
        }))

        // let colRef: QueryNew<DocumentData>;
        // if (queryFn) {
        //     colRef = query(this.colNew(ref), ...this.wheres(queryFn()));
        // } else {
        //     colRef = this.colNew(ref);
        // }
        // return collectionSnapshots(colRef).pipe(map(data => {
        //     return data.map(doc => {
        //         let data = doc.data() as T;
        //         data = this.timestampDate.parseTimestampToDate(data);
        //         data = this.timestampDate.parseStringToDate(data);
        //         return data;
        //     })
        // }))
    }

    public colGroupWithIdsNew$<T>(collectionId: string, queryFn: () => Where[] = () => [], opts: QueryConstraintOptions<T> = {}, addDocRef?: boolean) {
        const colRef: QueryNew<DocumentData> = query(collectionGroup(this.firestore, collectionId), ...this.wheres(queryFn()).concat(this.queryConstraintOptions(opts)));

        return collectionSnapshots(colRef).pipe(map(data => {
            return data.map(doc => {
                let data = doc.data() as T;
                data = this.timestampDate.parseTimestampToDate(data);
                data = this.timestampDate.parseStringToDate(data);
                if (addDocRef) (data as any).__doc = doc;
                return data;
            })
        }))
    }

    public docsFromIdNew$<T = any>(ref: string, ids: string[]): Observable<T[]> {
        return combineLatest(chunk(ids, 30).map(ids => this.colWithIdsNew$(ref, () => [['id', 'in', ids]]))).pipe(mergeAll());
    }

}
