<app-modal [alignFooterStart]="false" [hideCloseButton]="false">
  <div class="modal_header" #modal_header fxLayout="column" fxLayoutGap="5px">
    <div class="modal_title">
      title here
    </div>
  </div>

  <div class="modal_body" #modal_body>
    <mat-progress-bar mode="indeterminate" *ngIf="!tableConfig"></mat-progress-bar>

    <div class="p-2">
      <mat-form-field appearance="outline" class="">
        <mat-label>{{labels.search}}</mat-label>
        <input matInput type="search" [formControl]="searchCtrl">
      </mat-form-field>
    </div>

    <ng-container *ngIf="tableConfig">
      <div *ngIf="tableConfig.data.length === 0" class="empty-list">
        {{labels.empty_loan_history_items}}
      </div>

      <div *ngIf="tableConfig.data.length > 0">
        <data-table [configOptions]="tableConfig" (onSelectRow)="handleRowSelect($event)">
        </data-table>
      </div>
    </ng-container>
  </div>

  <!-- <div class="modal_footer" fxLayoutGap="15px" #modal_footer>
    <button mat-raised-button (click)="dialogRef.close()">{{labels.close}}</button>
    <button mat-raised-button color="primary" (click)="save()">{{labels.save}}</button>
  </div> -->
</app-modal>