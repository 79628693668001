import {
    Component,
    OnInit,
    Inject,
    OnDestroy
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, InventoryLoanHistoryService, UtilitiesService, UserService } from '@core/services';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { InventoryItem, IInventoryLoanHistory, User } from '@shared/model';
import { IObjectMap } from '@shared/interface';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSub } from 'ng-sub';

@Component({
    selector: 'app-loan-history-modal',
    templateUrl: './loan-history-modal.component.html',
    styleUrls: ['./loan-history-modal.component.scss']
})
export class LoanHistoryModalComponent extends ModalComponent implements OnInit, OnDestroy {
    public labels: any = {};
    public loanHistoryList: IInventoryLoanHistory[];
    public usersMap: IObjectMap<User> = {};

    private sub = new NgSub();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { inventory?: InventoryItem; customer?: User; },
        private labelService: LabelService,
        public breakpointObserver: BreakpointObserver,
        public dialogRef: MatDialogRef<LoanHistoryModalComponent>,
        private loanHistoryService: InventoryLoanHistoryService,
        private utilitiesService: UtilitiesService,
        private userService: UserService,
    ) {
        super(dialogRef, breakpointObserver);
    }

    async ngOnInit() {
        let obs$: Observable<IInventoryLoanHistory[]>;
        if (this.data.inventory) {
            obs$ = this.loanHistoryService.getLoanHistoriesForInventory(this.data.inventory.id);
        } else if (this.data.customer) {
            obs$ = this.loanHistoryService.getLoanHistoriesForUser(this.data.customer.id);
        }

        obs$.pipe(takeUntil(this.sub)).subscribe(res => {
            this.loanHistoryList = res;

            this.getUsers(
                this.loanHistoryList.map(h => h.volunteerId)
            );
        });

        this.labels = (await this.labelService.getLabels('inventory_loan_history')).data;
    }

    private getUsers(userIds: string[]) {
        const uniqIds = userIds.filter(id => id && !this.usersMap[id]);

        this.sub.add(
            this.userService.getUsersFromIds(uniq(uniqIds)).subscribe(users => {
                users.forEach(user => {
                    this.usersMap[user.id] = user;
                });
            })
        );
    }

    public formatDate(date: Date): string {
        return this.utilitiesService.getLocaleDateWithYear(new Date(date));
    }

    closeModal(): void {
        this.dialogRef.close(false);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
