import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take, filter, map, mergeAll } from 'rxjs/operators';
import { IObjectMap } from '@shared/interface';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { CloudFunctionService } from './functions.service';
import { LocalStorageService } from './local-storage.service';
import { NoticeBoardItem } from 'app/find-company/notice-board/model';
import { Organization, Service } from '@models/model';
import { FindCompanyServiceModel, FindCompanyUser } from '@models/model/find-company';
import { TimestampDate } from 'timestamp-date';

@Injectable({
    providedIn: 'root',
})
export class FindCompanyService {
    private baseUrl = 'https://find-company-9c35b.firebaseapp.com/api/v1';
    private apiKey$: BehaviorSubject<string> = new BehaviorSubject(undefined);
    private timestampDate = new TimestampDate();

    constructor(
        private http: HttpClient,
        private fnService: CloudFunctionService,
        private localStore: LocalStorageService,
    ) {
        this.fnService.getFindCompanyApiKey().then(token => {
            this.apiKey$.next(token.data);
        }).catch(e => {
            console.error(e);
        });
    }

    private getUri(path?: string): string {
        return this.baseUrl + path;
    }

    private getApiKey(): Observable<string> {
        return this.apiKey$.asObservable().pipe(
            filter(s => s != undefined)
        );
    }

    private handleApiRequest<T>(mthd: 'get' | 'post', path: string, data: IObjectMap<any>): Promise<T> {
        return this.getApiKey().pipe(
            map(apiKey => {
                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': apiKey
                });

                const options: IObjectMap<any> = { headers };
                const url = this.getUri(path);
                data.companyId = this.getCompanyId();

                switch (mthd) {
                    case 'get': {
                        if (data) {
                            options.params = data
                        }

                        return this.http.get<T>(url, options);
                    }

                    case 'post': {
                        return this.http.post<T>(url, data, options);
                    }

                    default: {
                        return throwError('Invalid endpoint method call');
                    }
                }
            }),
            take(1),
            mergeAll(),
            map(res => {
                return this.timestampDate.parseStringToDate<T, T>(res);
            }),
        ).toPromise();
    }

    public getCompanyId(): string {
        return this.localStore.getItemSync('user_organization');
    }

    public getServiceById(serviceId: string): Promise<FindCompanyServiceModel> {
        return this.handleApiRequest('get', `/services/${serviceId}`, {});
    }

    public updateService(service: FindCompanyServiceModel, org: Organization): Promise<void> {
        if (!service.logo) {
            service.logo = org?.webLogo || org.fullLogoUrl;
        }


        service.theme = org.theme;


        return this.handleApiRequest('post', `/services/${service.id}`, { service, org });
    }

    public toggleServiceMfa(serviceId: string, status: boolean): Promise<void> {
        return this.handleApiRequest('post', `/services/${serviceId}/mfa/${status ? 'enable' : 'disable'}`, {});
    }

    public getUsersForService(serviceId: string, email: string = ''): Promise<FindCompanyUser[]> {
        return this.handleApiRequest('get', '/users', { serviceId, email });
    }

    public getNoticeboardItems(serviceId: string): Promise<NoticeBoardItem[]> {
        return this.handleApiRequest('get', '/notice-board', { serviceId });
    }

    public deleteNoticeboardItem(serviceId: string, itemId: string): Promise<NoticeBoardItem[]> {
        return this.handleApiRequest('get', '/notice-board/remove', { serviceId, itemId });
    }

    public isFindCompanyActivated(service: Service): boolean {
        return service && service.settings && service.settings.findCompany && service.settings.findCompany.activated;
    }

    public createOrUpdateUser(user: FindCompanyUser): Promise<void> {
        return this.handleApiRequest('post', '/users/upsert', user);
    }

    public changeUserPhoneNumber(user: FindCompanyUser, phone: string): Promise<void> {
        const payload = {
            serviceId: user.serviceId,
            orgId: user.companyId,
            userId: user.id,
            phone: phone,
        };

        return this.handleApiRequest('post', `/users/${user.id}/edit-phone`, payload);
    }

    public deleteUser(user: FindCompanyUser): Promise<void> {
        return this.handleApiRequest('post', '/users/remove', { userId: user.id, serviceId: user.serviceId });
    }

    public resendInviteToUser(user: FindCompanyUser): Promise<void> {
        return this.handleApiRequest('post', '/users/reinvite', { userId: user.id, serviceId: user.serviceId });
    }
}
