import { Injectable } from '@angular/core';
import { FirestoreService, DocItem } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { Observable, combineLatest } from 'rxjs';
import { ServiceRegistration, ServiceRegHistory, ServiceRegStatusTypes } from '@models/model/serviceRegistration';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';
import { FetchType, ServiceRegFilter } from 'app/service-registration/service-registration-module/components';
import { chunk } from 'lodash';
import { mergeAll } from 'rxjs/operators';
// import { SearchService } from './search.service';

@Injectable({
    providedIn: 'root',
})
export class ServiceRegistrationService {
    private colRef: string;

    constructor(
        private afsDb: FirestoreService,
        private localStorageService: LocalStorageService,
        // private searchService: SearchService,
    ) {
        this.updateColRef(this.localStorageService.getItemSync('user_organization'));
        this.localStorageService.getItem('user_organization').subscribe(orgId => {
            this.updateColRef(orgId);
        });
    }

    private updateColRef(orgId: string) {
        this.colRef = `/organizations/${orgId}/serviceRegistrations`;
    }

    public getServiceRegistrations(queryFn?: any): Observable<ServiceRegistration[]> {
        return this.afsDb.colWithIds$(this.colRef, queryFn);
    }

    public getServiceRegistrationsForNetworkPartner(userId: string, serviceId?: string): Observable<ServiceRegistration[]> {
        return this.getServiceRegistrations(ref => {
            if (serviceId) {
                ref = ref.where('serviceId', '==', serviceId);
            }

            return ref.where('log.createdBy', '==', userId);
        });
    }

    public getServiceRegistrationsForWorkingArea(workingAreaId?: string, serviceId?: string, status?: ServiceRegStatusTypes): Observable<ServiceRegistration[]> {
        return this.getServiceRegistrations(ref => {
            if (workingAreaId) {
                ref = ref.where('workAreaId', '==', workingAreaId);
            }

            if (serviceId) {
                ref = ref.where('serviceId', '==', serviceId);
            }

            if (status) {
                ref = ref.where('status', '==', status);
            }

            return ref;
        });
    }

    public getServiceRegistrationHistory(regId: string): Observable<ServiceRegHistory[]> {
        return this.afsDb.colWithIds$(`${this.colRef}/${regId}/history`);
    }

    public createServiceRegistrationHistory(doc: ServiceRegHistory, regId: string): Promise<any> {
        return this.afsDb.add(`${this.colRef}/${regId}/history`, doc);
    }

    public createRegistration(payload: ServiceRegistration): Promise<any> {
        payload.id = this.afsDb.getNewId();
        return this.afsDb.setDoc(`${this.colRef}/${payload.id}`, payload as DocItem);
    }

    public deleteRegistration(id: string): Promise<any> {
        return this.afsDb.remove(`${this.colRef}/${id}`);
    }

    public updateRegistration(payload: DocItem): Promise<any> {
        return this.afsDb.update(`${this.colRef}/${payload.id}`, payload);
    }

    private getServiceRegistrationsWorkingArea<T>(query: ServiceRegFilter, serviceIds: string[], workAreaId: string, opts?: QueryConstraintOptions<T>) {
        return this.afsDb.colWithIdsNew$<T>(this.colRef, () => this.getFilters(query, serviceIds, workAreaId), opts, true);
    }

    private getServiceRegistrationsNetworkPartner<T>(query: ServiceRegFilter, serviceIds: string[], userId: string, opts?: QueryConstraintOptions<T>) {
        return this.afsDb.colWithIdsNew$<T>(this.colRef, () => this.getFilters(query, serviceIds, null, userId), opts, true);
    }

    public getServiceRegistrationModal(query: ServiceRegFilter, fetchTypes: FetchType, serviceIds: string[], workingAreaId?: string, userId?: string) {
        const options: QueryConstraintOptions<ServiceRegistration> = {};
        options.limit = query.limit;
        options.orderBy = [{ field: 'log.createdAt', val: 'desc' }];
        return this.serviceRegistrationModalHelper(query, fetchTypes, false, serviceIds, workingAreaId, userId, options);
    }

    private serviceRegistrationModalHelper(query: ServiceRegFilter, fetchTypes: FetchType, fetchAll: boolean, serviceIds: string[], workingAreaId?: string, userId?: string, options?: QueryConstraintOptions<any>, serviceLimit: number = 30) {
        let observer$: Observable<ServiceRegistration[]>;
        if (fetchAll) {
            observer$ = combineLatest(chunk(serviceIds, 30).map(serviceIds => {
                if (fetchTypes == 'workingArea') {
                    return this.getServiceRegistrationsWorkingArea<ServiceRegistration>(query, serviceIds, workingAreaId, options);
                } else {
                    return this.getServiceRegistrationsNetworkPartner<ServiceRegistration>(query, serviceIds, userId, options);
                }
            })
            ).pipe(mergeAll());
        } else {
            const limitedService = serviceIds.slice(0, serviceLimit);
            if (fetchTypes == 'workingArea') {
                return this.getServiceRegistrationsWorkingArea<ServiceRegistration>(query, limitedService, workingAreaId, options);
            } else {
                return this.getServiceRegistrationsNetworkPartner<ServiceRegistration>(query, limitedService, userId, options);
            }
        }
        return observer$;
    }

    public getServiceRegistrationCount(query: string, fetchTypes: FetchType,) {
        if (fetchTypes == 'workingArea') {
            return this.afsDb.getCounts(this.colRef, () => [['workAreaId', '==', query]])
        } else {
            return this.afsDb.getCounts(this.colRef, () => [['log.createdBy', '==', query]])
        }
    }

    public getAllServiceRegistrationByFilter(query: ServiceRegFilter, fetchTypes: FetchType, serviceIds: string[], workingAreaId?: string, userId?: string) {
        return this.serviceRegistrationModalHelper(query, fetchTypes, true, serviceIds, workingAreaId, userId);
    }

    public getServiceRegistrationByFilter(query: ServiceRegFilter, fetchTypes: FetchType, serviceIds: string[], workAreaId: string, userId: string, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean) {
        const options: Partial<QueryConstraintOptions<ServiceRegistration>> = {};
        if (lastOrFirstRef) {
            options.orderBy = [{ field: 'log.createdAt', val: 'desc' }];
            if (isReloadedContext) {
                options.startAt = lastOrFirstRef;
                options.limit = limit || 30;
            } else {
                if (isForwardNavigation) {
                    options.startAfter = lastOrFirstRef;
                    options.limit = limit || 30;
                } else {
                    options.endBefore = lastOrFirstRef;
                    options.limitToLast = limit || 30;
                }
            }
        } else {
            options.limit = limit || 30
        }
        return this.serviceRegistrationModalHelper(query, fetchTypes, false, serviceIds, workAreaId, userId, options, options.limit);
    }

    public searchServiceRegistrationModal(query: ServiceRegFilter, fetchTypes: FetchType, serviceIds: string[], workingAreaId?: string, userId?: string) {
        return this.serviceRegistrationModalHelper(query, fetchTypes, true, serviceIds, workingAreaId, userId)
    }

    private getFilters(query: ServiceRegFilter, serviceIds?: string[], workingAreaId?: string, userId?: string) {
        const where: Where[] = [];

        if (serviceIds[0]) {
            where.push(['serviceId', 'in', serviceIds]);
        }
        if (query.statusFilter) {
            where.push(['status', 'in', query.statusFilter]);
        }
        if (workingAreaId) {
            where.push(['workAreaId', '==', workingAreaId]);
        }
        if (userId) {
            where.push(['log.createdBy', '==', userId]);
        }
        if (query.textFilter) {
            where.push(['title', '==', query.textFilter]);
        }
        return where;
    }
}
