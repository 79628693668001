import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

export interface SeoConfig {
    title?: string;
    description?: string;
    image?: string;
    url?: string; // the relative route path
    tabTitle?: string;
}

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(
        private meta: Meta,
        private router: Router,
        private titleService: Title
    ) { }

    public updateMetaTags(config: SeoConfig): void {
        // default values
        config = {
            title: 'Volunteer4Work',
            description: 'Volunteer App for people helping people...',
            image: 'https://volunteer4work.com/assets/icons/icon-512x512.png',
            ...config,
            url: 'https://volunteer4work.com' + (config.url || this.router.url)
        };

        this.meta.updateTag({ name: 'twitter:title', content: config.title });
        this.meta.updateTag({ name: 'twitter:description', content: config.description });
        this.meta.updateTag({ name: 'twitter:image', content: config.image });

        this.meta.updateTag({ name: 'description', content: config.description });
        this.meta.updateTag({ name: 'image', content: config.image });

        this.meta.updateTag({ property: 'og:title', content: config.title });
        this.meta.updateTag({ property: 'og:description', content: config.description });
        this.meta.updateTag({ property: 'og:image', content: config.image });
        this.meta.updateTag({ property: 'og:url', content: config.url });

        this.updateBrowserTabTitle(config.tabTitle || config.title);
    }

    public updateBrowserTabTitle(text: string): void {
        this.titleService.setTitle(text);
    }
}
