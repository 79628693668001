import { DocumentLog } from './documentLog';
import { IObjectMap } from '../interface';
import { Service } from './service';
import { WorkingArea } from './workingArea';
import { IArea } from './postcodeAddress';

export enum ActivityKindTypes {
    note = 1,
    contact_live,
    phonecall,
    intake,
    evaluation,
    sale,
    payment,
    contact_online,
    contact_app,
    contact_mail,
    redirect,
    group_activity,
    group_activity_present,
    group_activity_absent,
    group_activity_cancelled
}

export class QuickNote {
    id?: string;
    title: string;
    kind: ActivityKindTypes;
    serviceId: string;
    description?: string;
    timeTracking: boolean; // false default
    defaultMinutes: number; // 0 default
    defaultAmount: number; // 0 default
    titleFixed: boolean; //false default
    minutesFixed: boolean; // false default
    repeatScheduledActivity?: boolean;
    repeatUnit?: RepeatOptions
    repeatUnitNumber?: number
}

export class ActivityReply {
    description: string;
    log?: DocumentLog;
    id?: string;
}

export class RedirectDetails {
    redirectionKind;
}

export interface ActivityAttachment {
    id: string;
    url?: string;
    ref?: string;
    filename: string;
    log?: DocumentLog;
    taggun?: IObjectMap<any>;
}

export class Activity {
    id: string;
    title: string;
    kind: ActivityKindTypes;
    subKind?: string;
    date: Date;
    amount: number;
    serviceId: string;
    replies: ActivityReply[];
    workareaId: string;
    description: string;
    log?: DocumentLog;
    createdByName?: string;
    orgId: string;
    userId: string;
    userName?: string;
    attachments: ActivityAttachment[];
    minutes: number;
    titleFixed: boolean;
    minutesFixed: boolean;
    budgetCode?: string; //todo: remove this line after migration
    requestId: string;
    groupId: string;
    groupActivityId: string;
    redirectDetails: RedirectDetails;
    executorIds: string[];
    scheduleDetails?: ActivityScheduleDetails;
    quickNoteId: string;
    workingAreaName?: string;
    serviceName?: string;
    executorId?: string;
    referenceNumber?: string;
    customerId?: string;
    customerName?: string;
    area?: IArea;
    titleRequest?: string;
    month?: number;
    year?: number;
    requestKind?: string;
    visibleFor?: VisibleFor;
    userActivityScope?: UserActivityScopeType;
    product?: string
    deleteReporting?: boolean;

    constructor() {
        this.date = new Date();
        this.kind = ActivityKindTypes.note;
        this.subKind = '';
        this.replies = [];
        this.attachments = [];
        this.amount = 0;
        this.minutes = 0;
    }
}

interface VisibleFor {
    servicePoint: boolean;
    executor: boolean;
    coordinator: boolean;
    professional: boolean;
    institutionContact: boolean;
    customer: boolean
}
export interface UserConnectedServiceAndWorkingArea {
    services: Service[];
    workingArea: WorkingArea;
    userConnectedServices: string[];
}

export class ActivityScheduleDetails {
    isScheduled: boolean;
    status: ActivityScheduleStatus; // pending default
    planDate: Date;
    assignedTo: string;

    constructor(activityScheduleDetails?: ActivityScheduleDetails) {
        this.isScheduled = activityScheduleDetails?.isScheduled || false;
        this.status = activityScheduleDetails?.status || ActivityScheduleStatus.pending;
        this.planDate = activityScheduleDetails?.planDate || null;
        this.assignedTo = activityScheduleDetails?.assignedTo || ''
    }
}

export enum ActivityScheduleStatus {
    pending = 1,
    completed
}

export type UserActivityScopeType = 'employee' | 'customer';
export type RepeatOptions = 'days' | 'weeks' | 'months' | 'years';