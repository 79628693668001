<div class="bg-primary full-width">
    <div class="app-toolbar-search" [ngClass]="enableFullWidthSearch ? 'expanded bg-primary' : ''">
        <form class="options-form">
            <mat-form-field appearance="outline" class="options-full-width">
                <mat-label>{{placeholder || labels.placeholder}}</mat-label>
                <input #searchInput matInput type="text"
                    [formControl]="myControl" />
                <mat-icon matSuffix *ngIf="!isCancelEnabled && !enableFullWidthSearch">search</mat-icon>
                <mat-icon matSuffix *ngIf="isCancelEnabled || enableFullWidthSearch" (click)="closeSearch()">
                    close
                </mat-icon>
            </mat-form-field>
        </form>
    </div>

    <mat-icon matSuffix class="full-width-search-button" *ngIf="!enableFullWidthSearch"
        (click)="enableFullWidthSearch = true">
        search
    </mat-icon>
</div>