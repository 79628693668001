import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService } from '@core/services';
import { LabelService } from '@core/services/labels.service';
import { ChecklistItem } from '@models/interface';
import { Request } from '@models/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { cloneDeep } from 'lodash';
import { NgSub } from 'ng-sub';

@Component({
  selector: 'app-checklist-modal',
  templateUrl: './checklist-modal.component.html',
  styleUrls: ['./checklist-modal.component.scss']
})
export class ChecklistModalComponent extends ModalComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public items: ChecklistItem[] = [];
  public newItemText = '';

  private sub = new NgSub();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { request: Request; },
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ChecklistModalComponent>,
    private labelService: LabelService,
    private requestService: RequestService,
  ) {
    super(dialogRef, breakpointObserver);
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('400px', 'auto');
    this.items = cloneDeep(this.data.request.checklistItems || []);

    this.labelService.getLabels('app-checklist-modal').then(lbs => this.labels = lbs.data);
  }

  public removeItem(index: number): void {
    this.items.splice(index, 1);
  }

  public saveNewItem(): void {
    const already = this.items.find(f => f.title.toLowerCase() === this.newItemText.toLowerCase());

    if (!already && this.newItemText) {
      this.items.push({
        title: this.newItemText, checked: false,
      });

      this.newItemText = '';
    }
  }

  public save(): void {
    this.data.request.checklistItems = this.items;
    delete this.data.request['__doc'];

    this.requestService.updateRequest(this.data.request);

    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
