<app-modal [alignFooterStart]="true">
    <div class="modal_header" #modal_header>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="modal_title">{{inputData.title}}</span>
        </div>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <ng-container *ngTemplateOutlet="emailRecipientsOutlet; 
                context: {recipients: persons, toggle: 'showFullPersonsList', label: 'recipients'}">
            </ng-container>
        </div>

        <form [formGroup]="emailForm">
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{labels.subject}}</mat-label>
                    <input matInput formControlName="subject">
                </mat-form-field>
            </div>

            <div style="margin-bottom: 10px;">
                <div *ngIf="attachments.length">
                    <mat-chip-listbox aria-label="attachment selection">
                        <mat-chip-option *ngFor="let attachment of attachments" (removed)="remove(attachment)"
                        [title]="attachment.filename">
                            {{attachment.filename.length > 25 ? attachment.filename.substring(0, 25) + '...' :
                            attachment.filename}}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-option>
                    </mat-chip-listbox>
                </div>
            </div>

            <mat-form-field appearance="outline" appearance="outline" class="full-width">
                <mat-label>{{inputData.messageTextPlaceholder}}</mat-label>
                <textarea rows="4" matInput cdkTextareaAutosize
                    formControlName="message"></textarea>
            </mat-form-field>

            <mat-radio-group  fxLayout="row" fxLayoutGap="10px" aria-label="Select an option" formControlName="emailType">
                <mat-radio-button [disabled]="isNotTheSameDomain" value="personal">{{labels.personal}}</mat-radio-button>
                <mat-radio-button *ngIf="userRole.roleType == RoleTypes.coordinator" 
                [disabled]="!service?.email?.sharedMailbox" value="service">{{labels.service}}</mat-radio-button>
                <mat-radio-button *ngIf="userRole.roleType == RoleTypes.professional"
                [disabled]="!workingArea?.email?.sharedMailbox" value="workingArea">{{labels.workingArea}}</mat-radio-button>
                <mat-radio-button value="organization">{{labels.organization}}</mat-radio-button>
            </mat-radio-group>

            <div fxLayout="row" class="pt-4" fxLayoutGap="10px" fxLayout.xs="column">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{labels.email_from_name}}</mat-label>
                    <input matInput readonly formControlName="fromName">
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{labels.email_from_id}}</mat-label>
                    <input matInput readonly formControlName="emailId">
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayoutGap="10px">
            <button mat-raised-button color="primary" (click)="selectFile()">{{labels.attachments}}</button>
            <button mat-stroked-button color="primary" (click)="emailFooter()">{{labels.footer}}</button>
        </div>
        <div fxLayoutGap="10px">
            <button mat-raised-button [disabled]="!canSend" color="primary" (click)="preview()">{{ labels.preview }}</button>
        </div>
    </div>
</app-modal>

<ng-template #emailRecipientsOutlet let-recipients="recipients" let-viewToggle="toggle" let-label="label">
    <mat-form-field appearance="outline" class="full-width">
        <mat-label *ngIf="label">{{label}}</mat-label>
        <mat-chip-grid #chipList>
            <mat-chip-row
                *ngFor="let recipient of recipients | slice:0:(showFullPersonsList ? recipients.length : maxRecipientsInView)"
                [removable]="true" (removed)="removeFromRecipients(recipients, recipient)">
                {{recipient.firstname}} {{recipient.lastname}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>

            <mat-chip-row *ngIf="recipients.length > maxRecipientsInView"
                (click)="showFullPersonsList = !showFullPersonsList" class="cursor-pointer">
                {{ showFullPersonsList
                ? labels.less
                : labels._translate('more_recipients_chip', {size: recipients.length - maxRecipientsInView}) + '+' + (recipients.length - maxRecipientsInView)
                }}
            </mat-chip-row>

            <input [disabled]="true" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="false">
        </mat-chip-grid>
    </mat-form-field>
</ng-template>

<!-- hidden elements -->
<input #userImport type="file" (change)="onFileSelected($event)" [hidden]="true" />