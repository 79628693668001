import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService, UserPromptsService } from '@core/services';
import { SocialRelationKindTypes, UserContactPerson } from '@models/model';
import { cloneDeep } from 'lodash';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-contact-person-details-dialog',
  templateUrl: './contact-person-details-dialog.component.html',
  styleUrls: ['./contact-person-details-dialog.component.scss']
})
export class ContactPersonDetailsDialogComponent implements OnInit {

  public labels = this.labelService.defaultProvider();
  protected contactPerson: UserContactPerson;
  public socialKind = SocialRelationKindTypes;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { contactPerson: UserContactPerson },
    public dialogRef: MatDialogRef<ContactPersonDetailsDialogComponent>,
    private labelService: LabelService,
    private clipboard: Clipboard,
    private userPromptService: UserPromptsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-contact-person-details-dialog')).data;
    this.contactPerson = cloneDeep(this.data.contactPerson);
  }

  protected copyToClipboard(content: string) {
    this.clipboard.copy(content);
    this.userPromptService.showToast(this.labels.copied);
  }

}
