import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { UserPromptsService, CustomFormsService, UserService, LabelService, PrintService } from '@core/services';
import { IObjectMap } from '@shared/interface';
import { ICustomFormField, ICustomFormModelData, User, ICustomFormModel } from '@shared/model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { cloneDeep } from 'lodash';

export interface ICustomFormComponentData {
    customForm: ICustomFormModel;
    customerId?: string;
    usePrintBtn?: boolean;
    useSaveBtn?: boolean;
}

@Component({
    selector: 'app-custom-form',
    templateUrl: './custom-form.component.html',
    styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent extends ModalComponent implements OnInit {
    public form: FormGroup;
    public customForm: ICustomFormModel;
    public userForm: ICustomFormModelData;
    public userFormForPrint: ICustomFormModelData;
    public customer: User;
    public customerId: string;
    public mappedFields: IObjectMap<ICustomFormField[]>;
    public labels: ILabelsProvider = Object.create(null);
    public isUserForm = false;
    public usePrintBtn = true;
    public useSaveBtn = true;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ICustomFormComponentData,
        public breakpointObserver: BreakpointObserver,
        public dialogRef: MatDialogRef<CustomFormComponent>,
        private userPromptsService: UserPromptsService,
        private customFormService: CustomFormsService,
        private userService: UserService,
        private labelService: LabelService,
        private printService: PrintService
    ) {
        super(dialogRef, breakpointObserver);
    }

    async ngOnInit(): Promise<void> {
        this.customForm = this.data.customForm;
        this.customerId = this.data.customerId;
        this.isUserForm = !!this.data.customerId;
        if (this.data.usePrintBtn === false) {
            this.usePrintBtn = false;
        }

        if (this.data.useSaveBtn === false || !this.isUserForm) {
            this.useSaveBtn = false;
        }

        this.labels = (await this.labelService.getLabels('app-custom-form')).data;
        if (this.isUserForm) {
            this.userForm = await this.customFormService.getUserCustomFormById(
                this.customerId,
                this.customForm.id
            );

            this.initPrintForm();
            this.customer = await this.userService
                .getUserById(this.customerId)
                .pipe(take(1))
                .toPromise();
        }

        if (!this.userForm) {
            this.userForm = {
                fields: {},
                userId: this.customerId,
                formId: this.customForm.id
            };

            this.initPrintForm();
        }


        if (!this.isUserForm || this.userForm) {
            this.customForm = this.customFormService.extendDefaultForm(this.customForm, this.userForm);
        }
    }

    async save(): Promise<void> {
        if (this.form.pending) {
            return;
        }

        if (!this.form.valid || !this.form.dirty) {
            this.form.markAllAsTouched();
            this.userPromptsService.showToast(this.labels.error_invalid_form);
            return;
        }

        this.form.markAsPending();
        this.userForm.fields = this.form.value;

        try {
            if (this.userForm.id) {
                await this.customFormService.updateUserCustomForm(this.userForm);
            } else {
                await this.customFormService.createUserCustomForm(this.userForm);
            }
            this.form.markAsPristine();
        } catch (error) {
            this.form.markAsPristine();
            throw error;
        }

        this.userPromptsService.showToast('CustomForm updated successfully');
        this.dialogRef.close();
    }

    private initPrintForm() {
        if (!this.userFormForPrint) {
            this.userFormForPrint = cloneDeep(this.userForm);
        }
    }

    public onCustomFormUpdated(payload: { form: FormGroup; init: boolean; }) {
        this.form = payload.form;

        this.initPrintForm();
        this.userFormForPrint.fields = this.form.value;
    }

    public print() {
        this.printService.printElement('form_print_area');
    }
}
