<app-modal *ngIf="addressData">
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.wrong_address_data}}</span>
    </div>

    <div class="modal_body" #modal_body>
        <div *ngFor="let data of addressData; let i=index" fxLayout="row" fxLayoutAlign="space-between" class="p-2">
            <div>{{i+1}}. {{labels.postalCode}}: {{data.postalCode}}</div>
            <div>{{labels.number}}: {{data.number}}</div>
            <div>{{labels.letter}}: {{data.letter}}</div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer fxLayoutGap="10px"></div>
</app-modal>