import { MediaMarshaller } from '@ngbracket/ngx-layout';

export class CommonMaterialModule {
  lastValue;

  public constructor(
    m: MediaMarshaller,
  ) {
    // hack until resolve: https://github.com/angular/flex-layout/issues/1201
    // @ts-ignore
    m.subject.subscribe((x) => {
      // @ts-ignore
      if (m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
        // @ts-ignore
        this.lastValue = [...m.activatedBreakpoints];
      } else {
        // @ts-ignore
        m.activatedBreakpoints = [...this.lastValue];
        // @ts-ignore
        m.hook.collectActivations = () => { };
        // @ts-ignore
        m.hook.deactivations = [...this.lastValue];
      }
    });
  }
}
