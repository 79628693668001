<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <span class="modal_title">{{labels.loan_history}}</span>
    </div>

    <div class="modal_body" #modal_body style="padding: 1em;">
        <div class="empty_list" *ngIf="loanHistoryList?.length === 0">
            {{labels.empty_list}}
        </div>

        <div class="loan_item" fxLayout="column" fxLayoutGap="10px" *ngFor="let loanHistory of loanHistoryList">
            <div fxLayout="row">
                <div class="info_key">{{labels.inventory_title}}</div>
                <div class="info_value carriage_returns" fxFlex="stretch">{{loanHistory.inventoryTitle}}</div>
            </div>

            <div fxLayout="row">
                <div class="info_key">{{labels.startdate}}</div>
                <div class="info_value carriage_returns" fxFlex="stretch">{{formatDate(loanHistory.startdate)}}</div>
            </div>

            <div fxLayout="row">
                <div class="info_key">{{labels.enddate}}</div>
                <div class="info_value carriage_returns" fxFlex="stretch">{{formatDate(loanHistory.startdate)}}</div>
            </div>

            <div fxLayout="row">
                <div class="info_key">{{labels.returndate}}</div>
                <div class="info_value carriage_returns" fxFlex="stretch">{{formatDate(loanHistory.startdate)}}</div>
            </div>

            <div fxLayout="row">
                <div class="info_key">{{labels.volunteer}}</div>
                <div class="info_value carriage_returns" fxFlex="stretch">{{usersMap[loanHistory.volunteerId]?.fullname
                    || '-'}}</div>
            </div>

            <div fxLayout="row">
                <div class="info_key">{{labels.loan_note}}</div>
                <div class="info_value carriage_returns" fxFlex="stretch">{{loanHistory.loanNote}}</div>
            </div>

            <div fxLayout="row">
                <div class="info_key">{{labels.return_note}}</div>
                <div class="info_value carriage_returns" fxFlex="stretch">{{loanHistory.returnNote}}</div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>
</app-modal>