import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { InstitutionService, LabelService } from '@core/services';
import { Institution } from '@models/model';
import { IDataTableConfig, ITableData } from '@shared/components';
import { Sub } from '@shared/subscriptions';

@Component({
  selector: 'app-institution-selector',
  templateUrl: './institution-selector.component.html',
  styleUrl: './institution-selector.component.scss'
})
export class InstitutionSelectorComponent implements OnInit, OnDestroy {

  public labels = this.labelService.defaultProvider();
  public dataTableConfig: IDataTableConfig;
  private institutions: Institution[];
  public form: FormGroup;
  private sub = new Sub();
  protected isLoading = true;

  constructor(
    public dialogRef: MatDialogRef<InstitutionSelectorComponent>,
    private labelService: LabelService,
    private institutionService: InstitutionService,
    private fb: FormBuilder,
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-institution-selector')).data;
    this.setupForm();
    this.fetchInstitution();
  }

  private setupForm() {
    this.form = this.fb.group({
      search: ['']
    });

    this.sub.add(this.form.get('search').valueChanges.subscribe(text => this.filterInstitution(text)));
  }

  protected clearSearch(): void {
    this.form.get('search').setValue('');
  }

  private fetchInstitution() {
    this.institutionService.getInstitutions().subscribe(institutions => {
      this.institutions = institutions;
      this.getTableConfig(this.institutions);
    })
  }

  private filterInstitution(text: string) {
    this.isLoading = true;
    const filtered = this.institutions.filter(institution => institution.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
    if (text) this.getTableConfig(filtered);
    else this.getTableConfig(this.institutions);
  }

  private getTableConfig(data: Institution[]) {
    this.dataTableConfig = {
      data: data.map(institution => {
        return {
          name: institution.name,
          logo: institution.logoUrl,
          city: institution.address.city,
          street: institution.address.street,
          number: institution.address.number,
          _metadata: {
            originalData: institution
          }
        };
      }),
      displayProperties: ['name', 'city', 'street', 'number', 'logo'],
      headers: {
        name: this.labels.name,
        logo: this.labels.logo,
        city: this.labels.city,
        street: this.labels.street,
        number: this.labels.number
      },
      rowOptions: [],
      propertyWithImage: 'logo',
      noImageDefaultImage: false,
      allowSelection: false,
      displayHeaders: true,
    };
    this.isLoading = false;
  }

  protected handleRowSelect(payload: ITableData) {
    const data = payload._metadata.originalData;
    this.dialogRef.close(data);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
