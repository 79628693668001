import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';
import { cloneDeep } from 'lodash';

export interface WrongAddressData {
  postalCode: string;
  number: number;
  letter: string
}

@Component({
  selector: 'app-wrong-postal-code-dialog',
  templateUrl: './wrong-postal-code-dialog.component.html',
  styleUrls: ['./wrong-postal-code-dialog.component.scss']
})
export class WrongPostalCodeDialogComponent implements OnInit {

  public labels: any = {};
  protected addressData: WrongAddressData[];
  
  constructor(
    public dialogRef: MatDialogRef<WrongPostalCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {data: WrongAddressData[]},
    private labelService: LabelService
  ) { }
  
  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-wrong-postal-code-dialog')).data
    this.addressData = cloneDeep(this.data.data);
  }
}
