<div fxLayout="column">
  <div class="section" style="margin-bottom: -7px;" *ngIf="remarksForm && customer && customer.customerDetails">
    <form [formGroup]="remarksForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.customer_note}}</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows minRows="10"
          formControlName="remarks"></textarea>
      </mat-form-field>
    </form>
  </div>

  <div class="section">
    <div class="section_title text-primary">{{labels.appointments}}</div>

    <div class="content">
      <mat-chip-listbox>
        <mat-chip-option (click)="newAppointment()" class="chip_style" selected="true" [color]="'primary'">
          <mat-icon>add</mat-icon>
        </mat-chip-option>

        <mat-chip-option [matTooltip]="appointment.title" class="chip_style" *ngFor="let appointment of appointments"
          selected="true" [color]="'default'">
          <ng-container *ngIf="appointment.id">
            <span class="chip_text">
              {{ appointment.date | date:'short'}}
            </span>
            <mat-icon class="close" [matMenuTriggerFor]="menu">more_vert</mat-icon>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editAppointment(appointment)">
                <mat-icon>edit</mat-icon>
                <span>{{ labels.edit }}</span>
              </button>
              <button mat-menu-item (click)="deleteAppointment(appointment)">
                <mat-icon>delete</mat-icon>
                <span>{{ labels.delete }}</span>
              </button>
            </mat-menu>
          </ng-container>

          <ng-container *ngIf="!appointment.id">
            {{labels.loading}}...
          </ng-container>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>

  <div class="section">
    <div class="section_title text-primary">
      {{labels.network}}
    </div>

    <div class="section" *ngIf="customer" fxLayoutGap="10px">
      <div class="content">
        <app-customer-network [customer]="customer"></app-customer-network>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
    <div class="section_card section full-width border-primary">
      <div class="section_title text-primary">
        {{labels.documents}}
      </div>

      <div class="content">
        <mat-chip-listbox>
          <mat-chip-option (click)="selectFile()" class="chip_style" selected="true" [color]="'primary'">
            <mat-icon>add</mat-icon>
          </mat-chip-option>

          <mat-chip-option matTooltip="{{doc.name}}" class="chip_style" *ngFor="let doc of customerDocs" selected="true"
            [color]="'default'">
            <mat-icon color="primary">insert_drive_file</mat-icon>
            <span (click)="openDoc(doc.url)" class="chip_text">
              {{ doc.name.substr(0, 7) }} {{ doc.name.length > 7 ? '...' : '' }}
            </span>
            <mat-icon class="close" [matMenuTriggerFor]="menu">more_vert</mat-icon>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="showFileInfo(doc)">
                <mat-icon>info</mat-icon>
                <span>{{ labels.info }}</span>
              </button>
              <button mat-menu-item (click)="deleteFile(doc)">
                <mat-icon>delete</mat-icon>
                <span>{{ labels.delete }}</span>
              </button>
            </mat-menu>
          </mat-chip-option>

          <mat-chip-option *ngIf="docLoading" (click)="selectFile()" class="chip_style" selected="true" [color]="'default'">
            <mat-icon color="primary">insert_drive_file</mat-icon>
            {{labels.doc_loading}}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>

    <div class="section_card section full-width border-primary">
      <div class="section_title text-primary">
        {{labels.forms}}
      </div>

      <div class="content" *ngIf="customForms && customForms.length">
        <mat-chip-listbox>
          <mat-chip-option *ngFor="let form of customForms" selected="true" (click)="openCustomFormModal(form)"
            [color]="getCustomFormColor(form)">
            {{form.label}}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="section_title text-primary">
      {{labels.related_requests}}
    </div>

    <div class="content">
      <mat-expansion-panel *ngFor="let area of workAreas" [disabled]="!isAreaEnabled(area)">
        <mat-expansion-panel-header>
          <b>({{ areasRequestsCount[area.id] || 0 }})</b>&nbsp;{{ area.name }}
          <div class="title-date">
            {{ lastRequestByAreaMap[area.id].log.createdAt | date : 'mediumDate'}}
          </div>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <div class="accordion-content">
            <mat-expansion-panel *ngFor="let service of servicesMap[area.id]" [disabled]="!isServiceEnabled(service)">
              <mat-expansion-panel-header>
                (<b>{{requestsMap[service.id]?.length}}</b>)&nbsp;{{ service.name }}
                <div class="title-date">
                  {{ lastRequestByServiceMap[service.id].log.createdAt | date : 'mediumDate'}}
                </div>
              </mat-expansion-panel-header>
              <mat-panel-description>
                <div class="accordion-content">
                  <mat-expansion-panel *ngFor="let request of requestsMap[service.id]" class="request"
                    [ngClass]="['status-' + request.status]">
                    <mat-expansion-panel-header>
                      {{ executorsMap[request.management?.executorId]?.fullname }} ({{
                      labels[RequestStatus[request.status]] }})
                      <div class="title-date">{{ request.log.createdAt | date : 'mediumDate'}}
                      </div>
                    </mat-expansion-panel-header>
                    <mat-panel-description>
                      <div class="accordion-content">
                        <div class="title">{{ request.title }}</div>
                        <div class="title">{{ request.description }}</div>

                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </mat-panel-description>
      </mat-expansion-panel>
    </div>
  </div>
</div>

<input #userImport type="file" (change)="onFileSelected($event)" [hidden]="true" />