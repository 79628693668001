import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() actionText: string;
  @Input() actionDisabled: boolean = false;
  
  @Output() onAction = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void { }

  public sendAction(): void {
    this.onAction.emit();
  }
}
