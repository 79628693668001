import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { MenuItem, Role, UserData } from '@shared/model';
import { RoleTypes } from '@shared/enum';
import { inventoryIsActivated } from '../guards/inventory.guard';
import { AuthService } from './auth.service';
import { IRoute, IObjectMap } from '@shared/interface';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { LocalStorageService } from './local-storage.service';
import { CoordinatorService } from './coordinator.service';
import { cloneDeep, values } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  public mainMenuItemSource$ = new BehaviorSubject<MenuItem>(new MenuItem());
  public submenuItemCallback$ = new Subject<string>();

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private coordinatorService: CoordinatorService,
    private afas: FirestoreService
  ) { }

  public getRouteParams(route: ActivatedRoute | null, depth: number = 10000): Observable<IObjectMap<string>> {
    const result: Observable<IObjectMap<string>>[] = [];
    let current = route;

    for (let a = 0; a < depth; a++) {
      if (current) {
        result.push(current.params);
        current = current.parent;
      } else {
        break;
      }
    }

    return combineLatest(result.reverse()).pipe(
      map(r => Object.assign({}, ...r)),
    );
  }

  public setMainMenuItem(mainMenuItem: MenuItem) {
    this.mainMenuItemSource$.next(mainMenuItem);
  }

  public submenuClicked(menuId: string) {
    this.submenuItemCallback$.next(menuId);
  }

  public async configureRoutes(role: Role, labels: ILabelsProvider, userData: UserData): Promise<IRoute[]> {
    if (!role) {
      return [];
    }

    let routes: IRoute[] = [];

    switch (role.roleType) {
      case RoleTypes.user: {
        this.authService.logout();
        break;
      }

      case RoleTypes.orgAdmin: {
        routes = [
          { name: labels.workingAreas, type: 'path', path: 'workingareas/list', icon: 'group_work', section: labels.configuration },
          { name: labels.users, type: 'path', path: 'users/user-list', icon: 'people_outline', section: '' },
          { name: labels.settings, type: 'path', path: 'settings', icon: 'settings', section: labels.configuration },
          { name: labels.expiring_users, type: 'path', path: 'users/expiring', icon: 'view_carousel_outline', section: labels.configuration },
          { name: labels.reporting, type: 'path', path: 'reporting', icon: 'assessment', section: '' },
          { name: labels.custom_form, type: 'path', path: 'custom-form', icon: 'apps', section: labels.configuration },
          { name: labels.service_catalog, type: 'path', path: 'service-registration', icon: 'offline_pin', section: '' },
          { name: labels.products, type: 'path', path: 'products', icon: ' inventory_2', section: '' },
          { name: labels.api_docs, type: 'path', path: 'api-docs', icon: 'api', section: labels.configuration },
          { name: labels.institutions, type: 'path', path: 'institutions/list', icon: 'store', section: labels.general },
          { name: labels.events, type: 'path', path: 'events/list', icon: 'event', section: labels.general },
        ];
        const count = await this.afas.getCounts(`organizations/${this.localStorageService.getItemSync('user_organization')}/apiCalls`);
        if (count) routes.push({ name: labels.api_calls, type: 'path', path: 'api-calls', icon: 'call_to_action', section: labels.configuration },)
        break;
      }

      case RoleTypes.professional: {
        routes = [
          { name: labels.users, type: 'path', path: 'users/user-list', icon: 'people_outline', section: '' },
          { name: labels.requests, type: 'path', path: 'requests/list', icon: 'access_alarm', section: '' },
          { name: labels.services, type: 'path', path: 'services/list', icon: 'room_service', section: labels.configuration },
          { name: labels.expiring_requests, type: 'path', path: 'requests/expiring', icon: 'view_carousel_outline', section: labels.configuration },
          { name: labels.groups, type: 'path', path: 'groups/list', icon: 'group', section: labels.workingArea },
          { name: labels.reporting, type: 'path', path: 'reporting/list', icon: 'assessment', section: labels.workingArea },
          { name: labels.institutions, type: 'path', path: 'institutions/list', icon: 'store', section: labels.general },
          { name: labels.events, type: 'path', path: 'events/list', icon: 'event', section: labels.general },
          { name: labels.activities_and_notes, type: 'path', path: 'activities/list', icon: 'line_style', section: labels.workingArea },
          { name: labels.service_registrations, type: 'path', path: 'service-registration/list', icon: 'offline_pin', section: labels.workingArea, id: 'service-registrations' },
          { name: labels.custom_form, type: 'path', path: 'custom-form/page', icon: 'apps', section: labels.configuration }
        ];

        break;
      }

      case RoleTypes.coordinator: {
        routes = [
          { name: labels.users, type: 'path', path: 'users/user-list', icon: 'people_outline', section: '' },
          { name: labels.requests, type: 'path', path: 'requests/list', icon: 'access_alarm', section: '' },
          // { name: labels.service, type: 'path', path: 'services/page', icon: 'room_service', section: labels.configuration },
          // { name: labels.expiring_requests, type: 'path', path: 'requests/expiring', icon: 'view_carousel_outline', section: labels.configuration },
          { name: labels.groups, type: 'path', path: 'groups/list', icon: 'group', section: labels.general },
          { name: labels.reporting, type: 'path', path: 'reporting/list', icon: 'assessment', section: labels.service },
          { name: labels.institutions, type: 'path', path: 'institutions/list', icon: 'store', section: labels.general },
          { name: labels.events, type: 'path', path: 'events/list', icon: 'event', section: labels.general },
          { name: labels.activities_and_notes, type: 'path', path: 'activities/list', icon: 'line_style', section: labels.service },
          { name: labels.service_registrations, type: 'path', path: 'service-registration/list', icon: 'apps', section: labels.service, id: 'service-registrations' }
        ];

        if (inventoryIsActivated(this.localStorageService)) {
          routes.push({ name: labels.inventory, type: 'path', path: 'inventory', icon: 'build', section: labels.service });
        }

        if (this.coordinatorService.isFindCompanyActivated()) {
          routes.push({
            name: labels.find_company_users,
            type: 'path',
            path: 'find-company/users',
            icon: 'nature_people',
            section: labels.find_company
          });

          routes.push({
            name: labels.find_company_notice_board,
            type: 'path',
            path: 'find-company/notice-board',
            icon: 'description',
            section: labels.find_company
          });
        }

        break;
      }
      case RoleTypes.servicePoint: {
        routes = [
          { name: labels.users, type: 'path', path: 'users/user-list', icon: 'people_outline', section: '' },
          { name: labels.requests, type: 'path', path: 'requests/list', icon: 'access_alarm', section: '' },
          // { name: labels.service, type: 'path', path: 'services/page', icon: 'room_service', section: labels.configuration },
          // { name: labels.expiring_requests, type: 'path', path: 'requests/expiring', icon: 'view_carousel_outline', section: labels.configuration },
          { name: labels.groups, type: 'path', path: 'groups/list', icon: 'group', section: labels.general },
          // { name: labels.reporting, type: 'path', path: 'reporting/list', icon: 'assessment', section: labels.service },
          { name: labels.institutions, type: 'path', path: 'institutions/list', icon: 'store', section: labels.general },
          { name: labels.events, type: 'path', path: 'events/list', icon: 'event', section: labels.general },
          // { name: labels.activities_and_notes, type: 'path', path: 'activities/list', icon: 'line_style', section: labels.service },
          // { name: labels.service_registrations, type: 'path', path: 'service-registration/list', icon: 'apps', section: labels.service, id: 'service-registrations' }
        ];
        break;
      }

      case RoleTypes.excecutor: {
        routes = [
          { name: labels.requests, type: 'path', path: 'requests/volunteer', icon: 'access_alarm', section: '' },
          { name: labels.users, type: 'path', path: 'users/user-list-executor', icon: 'people_outline', section: '' },
          { name: labels.groups, type: 'path', path: 'groups', icon: 'group', section: '' },
          { name: labels.institutions, type: 'path', path: 'institutions', icon: 'store', section: labels.general },
          { name: labels.events, type: 'path', path: 'events/list', icon: 'event', section: labels.general },
          { name: labels.reporting, type: 'path', path: 'reporting/activity-reporting/details', icon: 'assessment', section: '' },
        ];
        break;
      }

      case RoleTypes.networkPartner: {
        routes = [
          { name: labels.service_catalog, type: 'path', path: 'service-registration', icon: 'offline_pin', section: '' },
          { name: labels.service_registrations, type: 'path', path: 'service-registration/list', icon: 'apps', section: '', id: 'service-registrations' }
        ];
        break;
      }

      case RoleTypes.coordinatorReservations: {
        routes = [
          { name: labels.reservations, type: 'path', path: 'inventory/reservations', icon: 'queue', section: labels.reservations, id: 'inventory_reservations' },
          { name: labels.inventory, type: 'path', path: 'inventory/page', icon: 'build', section: labels.reservations },
          { name: labels.users, type: 'path', path: 'users/user-list', icon: 'people_outline', section: '' },
          {
            name: labels.reservations_overview,
            type: 'path',
            path: 'inventory/reservations-overview',
            icon: 'streetview',
            section: labels.reservations
          },
        ];
        break;
      }

      case RoleTypes.institutionContact: {
        routes = [
          { name: labels.calendar, type: 'path', path: 'events/list', icon: 'event', section: labels.events },
          { name: labels.listview, type: 'path', path: 'events/list-management', icon: 'list', section: labels.events },
        ]
      }
        break;

      case RoleTypes.customer: {
        routes = [
          { name: labels.events, type: 'path', path: 'events/list', icon: 'event', section: labels.general }
        ]
      }
    }

    if (userData?.permissions?.superAdmin) {
      routes = routes.concat([
        { name: labels.organizations, type: 'path', path: '/admin', icon: 'building', section: labels.control_panel },
        { name: labels.feedbacks, type: 'path', path: '/admin/feedbacks', icon: 'chat', section: labels.control_panel },
        { name: labels.users, type: 'path', path: '/admin/users', icon: 'people_outline', section: labels.control_panel },
        { name: labels.register_org, type: 'path', path: '/admin/register-org', icon: 'app_registration', section: labels.control_panel }
      ]);
    }

    return routes;
  }

  private getInitRoute(route: IRoute): IRoute {
    const r: IRoute = cloneDeep(route);
    r.name = r.section;
    r.children = [];

    return r;
  }

  public setGroupRoutes(routes: IRoute[]): IRoute[] {
    const categorized: IObjectMap<IRoute> = {};
    const uncategorized: IRoute[] = [];

    routes.forEach((r) => {
      if (r.section) {
        categorized[r.section] = categorized[r.section] || this.getInitRoute(r);
        categorized[r.section].children.push(r);
      } else {
        uncategorized.push(r);
      }
    });

    return uncategorized.concat(values(categorized));
  }
}
