import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LabelService } from 'app/core/services/labels.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ModalComponent } from 'app/shared/components/modal/modal.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { User, Organization } from '@shared/model';
import { UserPromptsService } from '@core/services/user-prompt.service';
import { EmailService } from '@core/services/email.service';
import { OrganizationService } from '@core/services/organization.service';
import { FileUploadService, OrgServiceService, UserService, WorkingAreaService } from '@core/services';
import { EmailAttachmentData, Role, RoleTypes, Service, WorkingArea } from '@models/index';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { NgSub } from 'ng-sub';
import { EmailFooterComponent } from '../email-footer/email-footer.component';
import { EmailPreviewComponent } from '../email-preview/email-preview.component';

export interface EmailConfigInput {
    recipients: User[];
    actionButton?: string;
    title?: string;
    messageTextPlaceholder?: string;
    subject?: string;
}

@Component({
    selector: 'app-email-dialogue',
    styleUrls: ['./email-dialogue.component.css'],
    templateUrl: './email-dialogue.component.html',
})
export class EmailDialogueComponent extends ModalComponent implements OnInit, OnDestroy {

    @ViewChild('userImport', { static: true }) fileInput: ElementRef;

    public emailForm: FormGroup;
    public labels: any = {};
    public maxRecipientsInView = 3;
    public persons: User[] = [];
    public showFullPersonsList: boolean;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    private currentOrg: Organization;
    public attachments: EmailAttachmentData[] = [];
    public canSend: boolean;
    private subscription: Subscription;
    private currentUser: User;
    private sub = new NgSub();
    public isNotTheSameDomain: boolean;
    protected service: Service;
    protected workingArea: WorkingArea;
    protected userRole: Role;
    protected RoleTypes = RoleTypes;

    constructor(
        public dialogRef: MatDialogRef<EmailDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) public inputData: EmailConfigInput,
        private fb: FormBuilder,
        public breakpointObserver: BreakpointObserver,
        private labelService: LabelService,
        private userPromptService: UserPromptsService,
        private emailService: EmailService,
        private orgService: OrganizationService,
        private fileUploadService: FileUploadService,
        private userService: UserService,
        private workingAreaService: WorkingAreaService,
        private orgServiceService: OrgServiceService
    ) {
        super(dialogRef, breakpointObserver);
    }

    async ngOnInit() {
        this.userRole = this.userService.getCurrentUserRole();
        this.createForm();
        this.labels = (await this.labelService.getLabels('app-email-dialogue')).data;

        this.persons = this.inputData.recipients;
        this.inputData.actionButton = this.inputData.actionButton || this.labels.send_email;
        this.inputData.messageTextPlaceholder = this.inputData.messageTextPlaceholder
            || this.labels.type_email_message;
        this.inputData.title = this.inputData.title
            || this.labels.email_sender;

        this.sub.add(
            this.orgService.getCurrentOrganization().subscribe(org => {
                this.currentOrg = org;
                this.setFormValue();
                this.disablePersonalField();
            }),
            this.userService.getCurrentUser().subscribe(user => {
                this.currentUser = user;
                this.disablePersonalField();
            })
        )
        if (this.userRole.serviceId) {
            this.orgServiceService.getServiceById(this.userRole.serviceId).pipe(take(1)).toPromise().then(s => this.service = s);
        }
        if (this.userRole.workingAreaId) {
            this.workingAreaService.getWorkingAreaById(this.userRole.workingAreaId).pipe(take(1)).toPromise().then(w => this.workingArea = w);
        }
    }

    private disablePersonalField() {
        this.isNotTheSameDomain = !(this.currentOrg?.email.substr(this.currentOrg.email.indexOf('@'))
            === this.currentUser?.email.substr(this.currentUser.email.indexOf('@')));
        this.dialogRef.disableClose = true;
    }

    private formatMessageBodyWithLineBreaks(msg: string): string {
        return msg
            .replace(new RegExp('\n', 'g'), '<br>')
            .replace(new RegExp('\r', 'g'), '<br>');
    }

    public async preview() {
        // check validity
        const recipients: User[] = this.persons;

        if (recipients.length === 0) {
            return this.userPromptService.showToast(this.labels.recipients_list_cannot_be_empty);
        }
        const emails = await this.emailService.buildPersonsEmail(
            recipients,
            this.formatMessageBodyWithLineBreaks(this.emailForm.value.message),
            // special individual message info can be placed here
            recipients.map(_recipient => ''),
            {
                bodySubject: this.emailForm.value.subject,
                fromName: this.emailForm.value.fromName || this.currentOrg.organizationName,
                fromEmail: this.emailForm.value.emailId,
                forFooter: await this.getWorkingAreaOrService()
            },
            this.emailForm.value.emailType,
            this.attachments
        );
        this.userPromptService.showDialogue(EmailPreviewComponent, emails, (data: boolean) => {
            // Give a significant amount to duration
            if (data) {
                setTimeout(() => {
                    this.dialogRef.close();
                }, 500);
            }
        }, true);
    }

    private async getWorkingAreaOrService() {
        const emailType = this.emailForm.value.emailType;
        let data: any = {};
        if (emailType == 'organization') {
            data.org = await this.orgService.getCurrentOrganization().pipe(take(1)).toPromise();
        } else if (emailType == 'workingArea') {
            data.wa = await this.workingAreaService.getWorkingAreaById(this.userRole.workingAreaId).pipe(take(1)).toPromise()
        } else if (emailType == 'service') {
            data.service = await this.orgServiceService.getServiceById(this.userRole.serviceId).pipe(take(1)).toPromise()
        } else {
            data.user = await this.userService.getCurrentUser().pipe(take(1)).toPromise();
        }
        return data;
    }

    createForm() {
        this.emailForm = this.fb.group({
            subject: [this.inputData.subject || ''],
            message: [''],
            emailId: ['', Validators.required],
            fromName: ['', Validators.required],
            emailType: ['organization']
        });

        this.listenToFormChanges();
    }

    private formValueChanges(field?: string, duration?: number): Observable<any> {
        const ctrl = field ? this.emailForm.get(field) : this.emailForm;
        return ctrl.valueChanges.pipe(
            distinctUntilChanged(),
            debounceTime(duration || 100)
        );
    }

    private listenToFormChanges() {
        this.subscription = this.formValueChanges().subscribe((_data) => {
            const model = this.emailForm.value;
            if (model.subject && model.message && model.emailId && model.fromName) this.canSend = true;
            else this.canSend = false;
        });

        this.formValueChanges('emailType').subscribe(value => {
            this.setFormValue(value);
        })
    }

    private setFormValue(value?: string) {
        if (value == 'organization' || !value) {
            this.emailForm.patchValue({ fromName: this.currentOrg.organizationName });
            this.emailForm.patchValue({ emailId: `noreply${this.currentOrg.email.substr(this.currentOrg.email.indexOf('@'))}` });
        } else if (value == 'service') {
            this.emailForm.patchValue({ fromName: this.service?.name });
            this.emailForm.patchValue({ emailId: this.service?.email?.sharedMailbox });
        } else if (value == 'workingArea') {
            this.emailForm.patchValue({ fromName: this.workingArea?.name });
            this.emailForm.patchValue({ emailId: this.workingArea?.email?.sharedMailbox });
        } else {
            this.emailForm.patchValue({ fromName: this.currentUser.fullname });
            this.emailForm.patchValue({ emailId: this.currentUser.email });
        }
    }

    public removeFromRecipients(recipients: User[], recipient: User) {
        const index = recipients.indexOf(recipient);

        if (index >= 0) {
            recipients.splice(index, 1);
        }
    }

    public selectFile() {
        this.fileInput.nativeElement.click();
    }

    public async onFileSelected(evt: any) {
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>evt.target;
        const file = target.files[0]

        this.attachments.push({ content: await this.fileUploadService.convertFileToBase64(file), filename: file.name, type: file.type });
    }

    public remove(attachment: any): void {
        const index = this.attachments.indexOf(attachment);

        if (index >= 0) {
            this.attachments.splice(index, 1);
        }
    }

    public emailFooter() {
        const modal = this.emailForm.value;
        const emailType = modal.emailType;
        const message = this.getFooterMessage(emailType);
        let save: Promise<any>;
        const disableEmailFooterField = emailType == 'organization' && this.currentUser.isOrgAdmin;

        this.userPromptService.showDialogue(EmailFooterComponent, { footerKind: modal.emailType, message, disableEmailFooterField }, async (message) => {
            if (message) {
                if (emailType == 'organization') {
                    this.currentOrg.settings.emailSettings = { defaultFooter: message };
                    save = this.orgService.updateOrganization(this.currentOrg);
                } else if (emailType == 'service') {
                    this.service.sharedMailboxFooter = message;
                    save = this.orgServiceService.updateService(this.service);
                } else if (emailType == 'workingArea') {
                    this.workingArea.email.sharedMailboxFooter = message;
                    save = this.workingAreaService.updateWorkingArea(this.workingArea);
                } else {
                    this.currentUser.settings.emailFooter = message;
                    save = this.userService.updateUser(this.currentUser);
                }
                try {
                    await save;
                    this.userPromptService.showToast(this.labels.settings_updated);
                } catch (error) {
                    this.userPromptService.showToast(this.labels.error_updating_settings);
                }
            }
        }, false, { width: '40%' })
    }

    private getFooterMessage(emailType: string) {
        if (emailType == 'organization') return this.currentOrg.settings.emailSettings?.defaultFooter;
        else if (emailType == 'service') return this.service?.sharedMailboxFooter;
        else if (emailType == 'workingArea') return this.workingArea?.email.sharedMailboxFooter;
        else return this.currentUser.settings?.emailFooter;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.sub.unsubscribe();
    }
}
