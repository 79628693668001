import { Injectable } from '@angular/core';

import { Role } from '@shared/model';
import { LocalStorageService } from '@core/services/local-storage.service';

export function inventoryIsActivated(localStorageService: LocalStorageService): boolean {
  const val = localStorageService.getItemSync('user_role');
  let role: Role;
  if (val) {
    role = JSON.parse(val);
  }
  return (
    role &&
    role.service &&
    role.service.settings &&
    role.service.settings.inventory &&
    role.service.settings.inventory.activated
  );
}

@Injectable({
  providedIn: 'root'
})
export class InventoryGuard  {
  constructor(private localStorageService: LocalStorageService) {}
  canActivate(): boolean {
    return inventoryIsActivated(this.localStorageService);
  }
}
