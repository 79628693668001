<app-modal [alignFooterStart]="true">
  <div class="modal_header" #modal_header>
    <div class="modal_title">{{labels.appointment}}</div>
    <div class="modal_subtitle">{{editMode ? labels.edit_appointment : labels.create_appointment}}</div>
  </div>

  <div class="modal_body" #modal_body>
    <div class="p-3">
      <form [formGroup]="form" fxLayout="column">
        <mat-form-field appearance="outline" fxFlex="stretch" color="primary">
          <mat-label>{{labels.appointment_title}}</mat-label>
          <input matInput type="text" formControlName="title" />
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="stretch" color="primary">
          <mat-label>{{labels.location}}</mat-label>
          <input matInput type="text" formControlName="location" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width" color="primary">
          <mat-label>{{labels.description}}</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows minRows="3" type="text"
            formControlName="description">
          </textarea>
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{labels.date}}</mat-label>
            <input matInput type="date" formControlName="date">
          </mat-form-field>
          <div class="full-width">{{getDayName(form.value.date)}}</div>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field appearance="outline" class="full-width">
            <!-- <input matInput type="time" formControlName="startTime" [placeholder]="labels.start_time"> -->
            <mat-label>{{labels.start_time}}</mat-label>
            <mat-select formControlName="startTime">
              <ng-container *ngFor="let time of timeList">
                <mat-option [value]="time.time" *ngIf="!occupiedTimes[time.cellIndex]">
                  {{time.timeText}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <!-- <input matInput type="time" formControlName="endTime" [placeholder]="labels.end_time"> -->
            <mat-label>{{labels.end_time}}</mat-label>
            <mat-select formControlName="endTime">
              <mat-option [value]="time.time" *ngFor="let time of validEndDates">
                {{time.timeText}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div>
    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px">
    <button [disabled]="form.invalid || form.pristine" mat-raised-button color="primary"
      (click)="save()">{{labels.save}}</button>
  </div>
</app-modal>