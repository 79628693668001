<mat-card [class.no-header]="configOptions.displayHeaders === false">
   <mat-table #table [dataSource]="matTableDataSource">
      <ng-container [matColumnDef]="property" *ngFor="let property of displayProperties">
         <ng-container [ngSwitch]="property">
            <ng-container *ngSwitchCase="'select'">
               <mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                     [disabled]="!matTableDataSource.filteredData.length" [checked]="isMasterToggleChecked()"
                     [indeterminate]="isMasterToggleIndeterminate()">
                  </mat-checkbox>
               </mat-header-cell>
               <mat-cell *matCellDef="let row"
                  [ngClass]="[row?._metadata && row._metadata.disabled ? 'disabled_element' : '']">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                     [checked]="selection.isSelected(row)">
                  </mat-checkbox>
               </mat-cell>
            </ng-container>

            <ng-container *ngSwitchCase="'status-row'">
               <mat-header-cell class="status-cell custom-cell--header" *matHeaderCellDef>
                  <ng-container *ngTemplateOutlet="statusTableNumberTmpl;context: {data: {}}"></ng-container>
               </mat-header-cell>

               <mat-cell class="status-cell" *matCellDef="let row">
                  <ng-container *ngIf="row._statusValue">
                     <ng-container *ngTemplateOutlet="statusTableNumberTmpl;context: {data: row._statusValue}">
                     </ng-container>
                  </ng-container>
               </mat-cell>
            </ng-container>

            <ng-container *ngSwitchCase="'customCell'">
               <mat-header-cell class="custom-cell  custom-cell--header" *matHeaderCellDef></mat-header-cell>
               <mat-cell class="custom-cell" *matCellDef="let row">
                  <ng-container *ngIf="row?.customCell?.body">
                     <ng-container *ngTemplateOutlet="row.customCell.body; context:{row:row}"> </ng-container>
                  </ng-container>
               </mat-cell>
            </ng-container>

            <ng-container *ngSwitchDefault>
               <mat-header-cell *matHeaderCellDef>
                  <span *ngIf="configOptions.displayHeaders">
                     {{ configOptions.headers ? configOptions.headers[property] : false || property }}
                  </span>
               </mat-header-cell>

               <mat-cell [fxFlex]="row[property] ? row[property].width || '' : ''"
                  [matTooltip]="configOptions.showTooltip ? row.tooltip : ''" *matCellDef="let row"
                  (click)="handleRowSelect(row)" [ngClass]="[property === 'status' && configOptions.highlightStatuses ? 'status-' + row.originalRequest[property] : '',
                            row?._metadata && row._metadata.disabled ? 'disabled_element' : '']">

                  <span *ngIf="property === configOptions.propertyWithImage" fxLayout="row"
                     fxLayoutAlign="start center">
                     <thumbnail [image]="row[property] ? row[property].title || row[property] : row[property]" [noImageDefaultImage]="configOptions.noImageDefaultImage">
                     </thumbnail>
                  </span>

                  <span *ngIf="property !== configOptions.propertyWithImage && row[property]">
                     <span *ngIf="!row[property].subtitle">
                        {{row[property].title || row[property]}}
                     </span>
                     <span fxLayout="column" fxLayoutGap="5px" *ngIf="row[property].subtitle">
                        <span class="cell_title">{{row[property].title}}</span>
                        <span class="cell_subtitle">{{row[property].subtitle}}</span>
                     </span>
                  </span>
               </mat-cell>
            </ng-container>

            <ng-container *ngSwitchCase="'_btn_menu_option'">
               <mat-header-cell *matHeaderCellDef fxFlex="70px"> </mat-header-cell>
               <mat-cell class="custom-cell" *matCellDef="let row" fxLayoutAlign="end center" fxFlex="70px">
                  <button mat-icon-button *ngIf="row.showRowBtn"
                   (click)="rowButtonMenuClicked(row)" [color]="configOptions.rowButton?.color">
                     <mat-icon>
                        {{configOptions.rowButton?.icon || ''}}
                     </mat-icon> {{configOptions.rowButton?.text || ''}}
                  </button>
               </mat-cell>
            </ng-container>

            <ng-container *ngSwitchCase="'_actions_'">
               <mat-header-cell *matHeaderCellDef fxFlex="70px"> </mat-header-cell>
               <mat-cell *matCellDef="let row" fxLayoutAlign="end" fxFlex="70px">
                  <button mat-icon-button (click)="rowOptionMenuClicked(row)" [matMenuTriggerFor]="controls" [matMenuTriggerData]="createMenuData(row)">
                     <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #controls="matMenu">
                     <ng-template matMenuContent let-menuRefItems="menuItems">
                        <button *ngFor="let option of menuRefItems; let idx = index"
                           (click)="selectRowAction(option, row, menuRefItems, idx)" mat-menu-item
                           [disabled]="!!option.disabled" [class.display-none]="option.hidden">
                           <mat-icon>{{option.icon}}</mat-icon>
                           <span>{{option.title}}</span>
                        </button>
                     </ng-template>
                  </mat-menu>
               </mat-cell>
            </ng-container>
         </ng-container>
      </ng-container>

      <mat-header-row [ngClass]="configOptions.displayHeaders ? '' : 'display-none'"
         *matHeaderRowDef="displayProperties">
      </mat-header-row>

      <mat-row *matRowDef="let row; columns: displayProperties;" [class.active]="selection.isSelected(row)"
         [ngClass]="[row._rowClass ? row._rowClass : '']" class="user-row">
      </mat-row>
   </mat-table>

   <div class="footer" *ngIf="configOptions.actionButtonLabel">
      <button [disabled]="selection.selected.length === 0" mat-raised-button color="primary"
         (click)="actionButtonClick()">
         {{configOptions.actionButtonLabel}}
      </button>
   </div>
</mat-card>

<ng-template #statusTableNumberTmpl let-data="data">
   <div style="margin: 0 auto;">
      <div [class]="'status-impl-parent status-bg-'+data.val"
         fxLayout="row" fxLayoutAlign="start center">
         <div [class]="statusNumColor(data)" [style]="{'background-color': data.innerColor ? 'red' : 'white'}" fxLayout="row"
            fxLayoutAlign="center center">
            {{data.val}}
         </div>
      </div>
   </div>
</ng-template>