import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LabelService } from '@core/services';
import { ExpiryItemFieldModel } from '@models/interface';
import { NgSub } from 'ng-sub';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-expiry-item-field',
  templateUrl: './expiry-item-field.component.html',
  styleUrls: ['./expiry-item-field.component.scss']
})
export class ExpiryItemFieldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: ExpiryItemFieldModel;

  @Output() onChange = new EventEmitter<ExpiryItemFieldModel>();

  public form: FormGroup;
  public timeframes: { text: string; id: string; }[];
  public labels = this.labelService.defaultProvider();

  private sub = new NgSub();

  constructor(
    private fb: FormBuilder,
    private labelService: LabelService
  ) { }

  async ngOnInit() {
    this.labels = (await this.labelService.getLabels('app-expiry-item-field')).data;

    this.timeframes = 'day,week,month,year'.split(',').map(id => {
      return {
        id, text: this.labels[`timeframe_${id}s`],
      };
    });
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.data?.timeframe && !this.form) {
      this.form = this.fb.group({
        value: [this.data.value, [Validators.required, Validators.min(1)]],
        timeframe: [this.data.timeframe, [Validators.required]]
      });

      this.form.valueChanges.pipe(
        debounceTime(1500),
        distinctUntilChanged(),
      ).subscribe(model => {
        const val = parseInt(model.value) || 1;

        if (val !== model.value) {
          this.form.get('value').setValue(val);
        } else {
          model.isActive = true
          this.onChange.emit(model);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
