import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';

export interface FooterDetails {
  message: string;
  footerKind: string;
  disableEmailFooterField: boolean;
}
@Component({
  selector: 'app-email-footer',
  templateUrl: './email-footer.component.html',
  styleUrls: ['./email-footer.component.scss']
})

export class EmailFooterComponent implements OnInit {

  public labels: any = {};
  public footerMessage: string;

  constructor(
    private labelService: LabelService,
    @Inject(MAT_DIALOG_DATA) public data: FooterDetails,
    public dialogRef: MatDialogRef<EmailFooterComponent>
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-footer-dialogue')).data;
    this.footerMessage = this.data.message;
  }

  public htmlEditor() {
    window.open('https://html-online.com/editor/', '_blank');
  }

  public save() {
    this.dialogRef.close(this.footerMessage);
  }
}
