import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Organization, Role } from 'app/shared/model';
import { LabelService } from 'app/core/services/labels.service';
import { RoleTypes } from '@shared/enum';
import { LocalStorageService } from '@core/services/local-storage.service';
import { take, takeUntil } from 'rxjs/operators';
import { RoleService } from '@core/services/role.service';
import { NgSub } from 'ng-sub';
import { AuthService } from '@core/services/auth.service';
import { OrganizationService } from '@core/services';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesDialogComponent implements OnInit, OnDestroy {
  public roles: Role[];
  public userRole: Role;
  public labels: any = {};
  public RoleTypes = RoleTypes;
  private org: Organization;
  private sub = new NgSub();

  constructor(
    public labelService: LabelService,
    public dialogRef: MatDialogRef<RolesDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data,
    private roleService: RoleService,
    private storageService: LocalStorageService,
    private authService: AuthService,
    private orgganizationService: OrganizationService
  ) {
    dialogRef.disableClose = true;
  }

  async ngOnInit() {
    const roles = this.data.roles || [];
    this.userRole = this.data.currentRole;

    if (roles.length === 0) {
      const userId = this.storageService.getItemSync('user_id');

      this.roleService.getRoles(userId).pipe(takeUntil(this.sub)).subscribe(roles => {
        this.roles = roles ?? [];
        this.handleIsOnlyRole();
      });
    }

    this.handleIsOnlyRole();
    await Promise.all([
      this.labelService.getLabels('app-roles-list'),
      this.orgganizationService.getCurrentOrganization().pipe(take(1)).toPromise()
    ]).then(payloads => {
      this.labels = payloads[0].data;
      this.org = payloads[1];
    })
  }

  private handleIsOnlyRole(): void {
    if (this.roles?.length === 1) {
      this.continue(this.roles[0]);
    }
  }

  public async continue(role: Role): Promise<void> {
    if (!this.org) this.org = await this.orgganizationService.getCurrentOrganization().pipe(take(1)).toPromise();
    let customLabels = this.org?.customLabels;
    if (role.workingArea?.settings?.customLabelsWorkingArea) {
      const workingAreaCustomLabels = role.workingArea.settings.customLabelsWorkingArea;
      const availableWorkingAreaLabels = Object.entries(workingAreaCustomLabels).map(entry => {
        if (entry[1]) return { [entry[0]]: entry[1] }
        else return null;
      }).filter(l => !!l);

      customLabels = Object.assign(this.org?.customLabels, ...availableWorkingAreaLabels);
    }
    // console.log(customLabels);
    this.labelService.updateCustomLabels(customLabels);
    this.dialogRef.close(role);
  }

  isSelected(role: Role): boolean {
    if (!this.userRole) {
      return false;
    }
    if (role.roleType !== this.userRole.roleType) {
      return false;
    }
    if (role.workingAreaId && (role.workingAreaId !== this.userRole.workingAreaId)) {
      return false;
    }
    if (role.serviceId && (role.serviceId !== this.userRole.serviceId)) {
      return false;
    }

    return true;
  }

  public async goBack() {
    await this.authService.logout();

    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
