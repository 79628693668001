import { DocumentLog } from "../../interfaces";

export enum AppointmentTypes {
  activity = 1,
  training,
}

export class AppointmentModel {
  id: string;
  title: string;
  description: string;
  type: AppointmentTypes;
  startTime: number;
  endTime: number;
  date: Date;
  calendarEventId?: string;
  location: string;
  requestId?: string;
  trainingId?: string;
  docRef?: string; // points to connected activity or training
  attendees: string[];
  customerId: string;
  log?: DocumentLog;

  constructor(type: AppointmentTypes) {
    this.date = new Date();
    // this.endDate = addHours(new Date(), 2);
    this.type = type;
    this.description = '';
  }
}
