import { LabelService, UtilitiesService } from '@core/services';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ICustomFormModel, ICustomFormField } from '@shared/model';
import { IObjectMap } from '@shared/interface';
import { Sub } from '@shared/subscriptions';
import { groupBy } from 'lodash';

@Component({
    selector: 'app-custom-form-group-print',
    templateUrl: './custom-form-group-print.component.html',
    styleUrls: ['./custom-form-group-print.component.scss']
})
export class CustomFormGroupPrintComponent implements OnInit, OnChanges, OnDestroy {
    @Input() customForm: ICustomFormModel;
    @Input() formValues: IObjectMap<any>;

    public labels: any = {};
    public mappedFields: IObjectMap<ICustomFormField[]>;

    private sub: Sub = new Sub();

    constructor(
        private labelService: LabelService,
        private utilitiesService: UtilitiesService
    ) { }

    async ngOnInit() {
        this.labels = (await this.labelService.getLabels('app-custom-form-group-print')).data;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.utilitiesService.isNewChange(changes.customForm) || this.utilitiesService.isNewChange(changes.formValues)) {
            this.mappedFields = groupBy(this.customForm.fields, 'groupName');
            this.customForm.fields.forEach((field: ICustomFormField) => {
                field.value = this.getValidValue(this.formValues[field.fieldName], field.value);
            });
        }
    }

    private getValidValue(val1: any, val2: any) {
        if (!val1) {
            return val2;
        }

        if (!val2) {
            return val1;
        }

        if (typeof val1 === typeof val2) {
            return val1;
        } else {
            return val2;
        }
    }

    public getFieldWidth(field: ICustomFormField): string {
        const width: number = field.width || 4; // default is 4
        const minus: number = width === 4 ? 0 : 1; // -1% of width for not full-width block

        return `${25 * width - minus}%`;
    }

    public getIsRequiredField(field: ICustomFormField): boolean {
        if (field.hidden) {
            return false;
        }
        return field.required;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
