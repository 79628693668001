<app-modal [hideCloseButton]="true">
  <div class="modal_header" #modal_header>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <thumbnail [image]="customer?.picture"></thumbnail>

      <div fxFlex="stretch" class="modal_title" fxLayout="row" fxLayoutGap="10px">
        <div>{{customer?.fullname}}</div>
        <span style="background-color: red" *ngIf="customer?.decedent"> -
          {{labels.decedent}} - </span>
      </div>

      <button mat-icon-button (click)="closeRequestModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div style="margin: 9px -10px -10px;">
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
  </div>

  <div class="modal_body" #modal_body>
    <form style="padding: 15px;" [formGroup]="form" fxLayout="column" role="form" *ngIf="form">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="0px" fxLayoutGap="20px">
        <mat-form-field appearance="outline" class="full-width"
          [ngClass]="{'disabled_element': isWorkingAreaSelectDisabled()}">
          <mat-label>{{labels.workingarea}}</mat-label>
          <mat-select formControlName="workareaId">
            <mat-option *ngFor="let area of workingAreas" [value]="area.id">
              {{ area.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width"
          [ngClass]="{'disabled_element': isServiceSelectDisabled()}">
          <mat-label>{{labels.service}}</mat-label>
          <mat-select formControlName="serviceId">
            <mat-option *ngFor="let service of getServices(form.value.workareaId) | sort:'name'" [value]="service?.id">
              {{ service?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="mb-4">
        <mat-chip-set aria-label="Default request titles">
          <mat-chip *ngFor="let drt of defaultRequestTitles" (click)="requestTitleChipSelected(drt)">{{drt}}</mat-chip>
        </mat-chip-set>
      </div>

      <div fxLayout="row" fxLayoutGap="15px">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.title}}</mat-label>
          <input autocomplete="off" matInput maxlength="75" #inputT
            [placeholder]="currentService?.settings?.placeholders?.requestTitle" type="text" formControlName="title">
          <mat-hint align="end">{{inputT.value.length}}/75</mat-hint>
        </mat-form-field>

        @if (isRequestDurationEditable) {
        <mat-form-field appearance="outline" style="width: 20%;">
          <mat-label>{{labels.request_duration}}</mat-label>
          <mat-select formControlName="requestDurationType">
            <ng-container *ngFor="let durationType of requestDurationTypes">
              <mat-option [value]="durationType">
                {{labels[RequestDurationTypes[durationType]]}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        }
      </div>

      <div *ngIf="currentService?.settings?.goTogether?.activated" formGroupName="goTogetherDetails" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between" class="mb-4">
          <div style="margin: auto 0;">
            <span style="margin: 0 5px;">{{distanceMatrix.distance?.text || ''}}</span>
            <span style="margin: 0 5px;">{{distanceMatrix.duration?.text || ''}}</span>

          </div>

          <div style="margin: auto 0;">
            <mat-slide-toggle color="primary" formControlName="roundtrip">{{labels.round_trip}}</mat-slide-toggle>
          </div>
        </div>
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.from_address}}</mat-label>
              <input readonly autocomplete="off" matInput type="text" formControlName="fromAddress">
              <button mat-icon-button matSuffix color="primary" (click)="openAddressModal('fromAddress')"><mat-icon
                  matSuffix>search</mat-icon></button>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.to_address}}</mat-label>
              <input readonly autocomplete="off" matInput type="text" formControlName="toAddress">
              <button mat-icon-button matSuffix color="primary" (click)="openAddressModal('toAddress')"><mat-icon
                  matSuffix>search</mat-icon></button>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" style="width: 100%;">
          <mat-form-field appearance="outline" style="width: 33%;">
            <mat-label>{{labels.scheduledFor_date}}</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="scheduledForDate">
            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 33%;">
            <mat-label>{{labels.pick_up_time}}</mat-label>
            <mat-select formControlName="pickupTime">
              <ng-container *ngFor="let time of pickUpTimeList">
                <mat-option [value]="time.time" *ngIf="!occupiedTimes[time.cellIndex]">
                  {{time.timeText}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance="outline" style="width: 33%;">
            <mat-label>{{labels.appointment_time}}</mat-label>
            <mat-select formControlName="appointmentTime">
              <mat-option [value]="null">
              </mat-option>
              <ng-container *ngFor="let time of appointmentTimeList">
                <mat-option [value]="time.time">
                  {{time.timeText}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance="outline" style="width: 33%;">
            <mat-label>{{labels.return_time}}</mat-label>
            <mat-select formControlName="returnTime">
              <mat-option [value]="null">
              </mat-option>
              <mat-option [value]="time.time" *ngFor="let time of returnTimeList">
                {{time.timeText}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field appearance="outline" style="width: 49%;">
            <mat-label>{{labels.mode_of_transport}}</mat-label>
            <mat-select formControlName="modeOfTransport" (ngModelChange)="updateModeOfTransport()">
              <mat-option *ngFor="let modeOfTransport of modeOfTransports" [value]="modeOfTransport">
                {{labels[ModeOfTransport[modeOfTransport]]}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.description}}</mat-label>
        <textarea matInput cdkTextareaAutosize minRows="3" formControlName="description" #inputD maxlength="2000"
          [placeholder]="currentService?.settings?.placeholders?.requestDescription"></textarea>
        <mat-hint align="end">{{inputD.value.length}}/2000</mat-hint>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap.xs="0px" fxLayoutGap="20px">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.referred_by}}</mat-label>
          <mat-select formControlName="referredBy">
            <mat-option [value]="null">
            </mat-option>
            <mat-option *ngFor="let referredByValue of currentArea?.settings?.referredByValues"
              [value]="referredByValue">
              {{ referredByValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.request_kind}}</mat-label>
          <mat-select formControlName="requestKind">
            <mat-option [value]="null">
            </mat-option>
            <mat-option *ngFor="let value of currentArea?.settings?.requestKind || []" [value]="value">
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div fxLayout="row" fxLayoutGap.xs="0px" fxLayoutGap="20px">

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.referredByNote}}</mat-label>
          <textarea matInput cdkTextareaAutosize minRows="2" autocomplete="off" formControlName="referredByNote"
            #inputRe maxlength="255"></textarea>
          <mat-hint align="end">{{inputRe.value.length}}/255</mat-hint>
        </mat-form-field>

      </div>
    </form>
  </div>

  <div class="modal_footer" #modal_footer>
    <button mat-raised-button color="primary" [disabled]="!form?.valid || !form.dirty || loading"
      (click)="save()">{{labels.save}}
    </button>
  </div>
</app-modal>