import {
  CustomFormsService,
  UserPromptsService,
  FileUploadService,
  UserService,
  FileUploadResponse,
  LabelService,
  LocalStorageService,
  RequestService,
  WorkingAreaService,
  OrgServiceService,
  UtilitiesService
} from '@core/services';
import {
  ICustomFormModel,
  Request,
  Management,
  User,
  AttachedDoc,
  Role,
  CustomerDetails,
  WorkingArea,
  Service,
  ICustomFormModelData
} from 'app/shared/model';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { take, distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { filter, sortBy, compact, groupBy, uniq, keyBy, isEqual, map, each, keys } from 'lodash';
import { IObjectMap } from '@shared/interface';
import { CustomFormComponent } from '@shared/entry-components/custom-form/custom-form.component';
import { DocInfoComponent } from '@shared/entry-components/doc-info/docinfo.component';
import { RoleTypes, RequestStatus } from '@shared/enum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { isBefore } from 'date-fns';
import { AppointmentModel, AppointmentTypes } from '@models/model/appointment.model';
import { EditAppointmentModalComponent } from '@shared/entry-components/edit-appointment-modal/edit-appointment-modal.component';
import { AppointmentService } from '@core/services/appointment/appointment.service';
import { NgSub } from 'ng-sub';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnChanges, OnInit {
  @Input() management: Management;
  @Input() requestId: string;

  @ViewChild('userImport', { static: true }) fileInput: ElementRef;

  @Output() formChangesOut: EventEmitter<CustomerDetails> = new EventEmitter();

  public customer: User;
  public userRole: Role = JSON.parse(this.localStorageService.getItemSync('user_role'));
  public readOnly: boolean;
  public customerDocs: AttachedDoc[] = [];
  public docLoading = false;
  public labels: ILabelsProvider = Object.create(null);
  public customForms: ICustomFormModel[] = [];
  public userCustomForms: IObjectMap<ICustomFormModelData> = {};
  public remarksForm: FormGroup;
  public customerRequests: Request[];
  public workAreas: WorkingArea[] = [];
  public areasRequestsCount: IObjectMap<number> = {};
  public services: Service[] = [];
  public servicesMap: IObjectMap<Service[]> = {};
  public requestsMap: IObjectMap<Request[]> = {};
  public executorsMap: IObjectMap<User> = {};
  public lastRequestByAreaMap: IObjectMap<Request> = {};
  public lastRequestByServiceMap: IObjectMap<Request> = {};
  public districtCoaches$: Observable<User[]>;
  public customerCoach: User;
  public RequestStatus = RequestStatus;
  public appointments: AppointmentModel[] = [];

  private areaServices: Service[] = [];
  private sub = new NgSub();

  constructor(
    private localStorageService: LocalStorageService,
    private labelService: LabelService,
    private userService: UserService,
    private fileUploadService: FileUploadService,
    private customFormsService: CustomFormsService,
    private requestService: RequestService,
    private orgServiceService: OrgServiceService,
    private workingAreaService: WorkingAreaService,
    private userPromptsService: UserPromptsService,
    private fb: FormBuilder,
    private utilitiesService: UtilitiesService,
    private appointmentService: AppointmentService,
  ) { }

  async ngOnInit() {
    this.readOnly = this.userRole.roleType === RoleTypes.excecutor;
    this.labels = (await this.labelService.getLabels('app-customer-details')).data;
    this.districtCoaches$ = this.userService.getCoaches();

    this.appointmentService.getAppointmentsForUser(this.management.customerId)
      .pipe(takeUntil(this.sub))
      .subscribe({
        next: appts => {
          this.appointments = appts;
        },
        error: e => console.log(e),
      });

    if (this.utilitiesService.rolesMatch(RoleTypes.coordinator)) {
      this.areaServices = await this.orgServiceService.getServicesFromRefs(
        keys(this.userRole.workingArea.services)
      ).pipe(take(1)).toPromise();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isNewChange(changes.management)) {
      if (this.management) {
        this.initCustomForms();
        this.initCustomerDetails();
        this.initCustomerRequests();
      }
    }
  }

  updateCoachOnCustomer(user: User) {
    this.customerCoach = user;
    this.outputChanges(this.remarksForm.value.remarks);
  }

  initRemarksForm() {
    this.customer.customerDetails = this.customer.customerDetails || new CustomerDetails();
    this.remarksForm = this.fb.group({
      remarks: [this.customer.customerDetails.remarks]
    });

    this.remarksForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300)
      )
      .subscribe(payload => {
        this.outputChanges(payload.remarks);
      });
  }

  public editAppointment(appointment: AppointmentModel): void {
    this.userPromptsService.showDialogue(EditAppointmentModalComponent, {
      appointment,
      requestId: this.requestId,
      management: this.management,
    }, null, true);
  }

  public deleteAppointment(appointment: AppointmentModel): void {
    if (appointment.docRef) {
      this.userPromptsService.showToast('Cannot delete due to a connected reference');
    } else {
      this.userPromptsService.confirmPromise(this.labels.confirm, this.labels.delete_appointment_warning).then(sure => {
        if (sure) {
          this.appointmentService.deleteAppointment(appointment.id);
        }
      });
    }
  }

  public newAppointment(): void {
    const appointment = new AppointmentModel(AppointmentTypes.activity);
    this.editAppointment(appointment);
  }

  private outputChanges(remark: string) {
    this.formChangesOut.emit({
      remarks: remark,
      districtCoach: this.customerCoach ? this.customerCoach.id : '',
      attachedDocs: this.customer.customerDetails.attachedDocs || {}
    });
  }

  private isNewChange(change: any) {
    const prev = change.previousValue;
    const current = change.currentValue;

    return !isEqual(prev, current);
  }

  private isManagementValid(): boolean {
    return this.management && !!this.management.customerId;
  }

  async initCustomerDetails() {
    if (this.isManagementValid()) {
      this.userService.getUserById(this.management.customerId).subscribe(async user => {
        this.customer = user;
        this.customerDocs = [];

        if (this.customer.customerDetails.districtCoach) {
          // Get the customerCoach here
          this.customerCoach = await this.userService
            .getUserById(this.customer.customerDetails.districtCoach)
            .pipe(take(1))
            .toPromise();
        }

        this.userService.getUserDocs(this.customer.id).subscribe((docs: AttachedDoc[]) => {
          this.customerDocs = docs;
          if (this.userRole.roleType === RoleTypes.professional) {
            this.customerDocs = filter(this.customerDocs, (doc: AttachedDoc) => {
              return doc.areadId[this.userRole.workingAreaId] === true;
            });
          }

          if (this.userRole.roleType === RoleTypes.coordinator) {
            this.customerDocs = filter(this.customerDocs, (doc: AttachedDoc) => {
              if (keys(doc.serviceId).length === 0 || this.customerDocs['undefined'] == true) {
                return doc.areadId[this.userRole.workingAreaId] === true;
              } else {
                return doc.serviceId[this.userRole.serviceId] === true;
              }
            });
          }

          if (this.userRole.roleType === RoleTypes.excecutor) {
            this.customerDocs = filter(this.customerDocs, (doc: AttachedDoc) => {
              if (keys(doc.serviceId).length === 0) {
                return doc.areadId[this.management.workareaId] === true;
              } else {
                return doc.serviceId[this.management.serviceId] === true;
              }
            });
          }
        });

        this.initRemarksForm();
      });
    }
  }

  async initCustomerRequests() {
    if (this.isManagementValid()) {
      this.customerRequests = await this.requestService
        .getRequests({
          customerId: this.management.customerId
        })
        .pipe(take(1))
        .toPromise();
      this.customerRequests = sortBy(this.customerRequests, request => -request.log.createdAt);
      const serviceIds: string[] = uniq(
        map(this.customerRequests, (request: Request) => request.management.serviceId)
      );
      const workareaIds: string[] = uniq(
        map(this.customerRequests, (request: Request) => request.management.workareaId)
      );
      const executorIds: string[] = uniq(
        map(this.customerRequests, (request: Request) => request.management.executorId)
      );
      const executors: User[] = await this.userService
        .getUsersFromIds(executorIds)
        .pipe(take(1))
        .toPromise();
      this.executorsMap = keyBy(executors, 'id');

      this.services = await this.orgServiceService
        .getServicesFromRefs(serviceIds)
        .pipe(take(1))
        .toPromise();
      this.workAreas = await this.workingAreaService
        .getWorkareasFromIds(workareaIds)
        .pipe(take(1))
        .toPromise();
      const servicesByIds: IObjectMap<Service> = keyBy(this.services, 'id');
      each(this.workAreas, (area: WorkingArea) => {
        this.servicesMap[area.id] = compact(
          map(keys(area.services), (id: string) => servicesByIds[id])
        );
      });
      this.initLastRequests(this.customerRequests);
      this.requestsMap = groupBy(
        this.customerRequests,
        (request: Request) => request.management.serviceId
      );
      this.calculateRequestsCountForAreas();
    }
  }

  initLastRequests(requests: Request[]) {
    each(requests, (request: Request) => {
      const lastForArea: Request = this.lastRequestByAreaMap[request.management.workareaId];
      const lastForService: Request = this.lastRequestByServiceMap[request.management.serviceId];

      if (!lastForArea || isBefore(lastForArea.log.createdAt, request.log.createdAt)) {
        this.lastRequestByAreaMap[request.management.workareaId] = request;
      }

      if (!lastForService || isBefore(lastForService.log.createdAt, request.log.createdAt)) {
        this.lastRequestByServiceMap[request.management.serviceId] = request;
      }
    });
  }

  private initCustomForms() {
    if (this.management && this.management.customerId) {
      this.customFormsService.getCustomFormsForManagement(
        'asdad', 'asd'
      ).subscribe(async (customForms) => {
        this.customForms = customForms ? customForms.filter(i => !!i) : [];

        const userForms: ICustomFormModelData[] = await this.customFormsService
          .getUserCustomForms(this.management.customerId)
          .pipe(take(1))
          .toPromise();
        this.userCustomForms = keyBy(userForms, 'formId');
      });
    }
  }

  async openCustomFormModal(customForm: ICustomFormModel) {
      this.userPromptsService.showDialogue(
        CustomFormComponent,
        {
          customForm: customForm,
          customerId: this.management.customerId
        },
        () => {
          this.initCustomForms();
        }
      );
    }

  public selectFile() {
    this.fileInput.nativeElement.click();
  }

  async onFileSelected(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    const isRightFormat = this.fileUploadService.fileMatchesFormat(target.files[0], [
      'csv',
      'xls',
      'xlsx',
      'doc',
      'docx',
      'png',
      'jpg',
      'bpm',
      'pdf',
      'txt'
    ]);

    if (isRightFormat) {
      // here we upload the file to storage then we take the reference and save it to the user
      try {
        this.docLoading = true;
        const res: FileUploadResponse = await this.fileUploadService.uploadFile(
          target.files[0],
          this.customer.id
        );

        const currentAreaId: string = this.userRole.workingAreaId || this.management.workareaId;
        const currentServiceId: string = this.userRole.serviceId || this.management.serviceId;

        const doc = {
          name: res.fileName,
          ref: res.fileRef,
          url: res.fileUrl,
          isCustomerDoc: true,
          type: res.metaData.contentType,
          areadId: {
            [currentAreaId]: true
          },
          serviceId: {}
        };

        if (currentServiceId) {
          doc.serviceId[currentServiceId] = true;
        }

        await this.userService.addDoc(
          doc,
          this.customer.id
        );

        this.docLoading = false;
        this.userPromptsService.showToast(this.labels.document_added);
      } catch (e) {
        this.userPromptsService.showToast(this.labels.upload_failed);
        this.docLoading = false;
      }
    } else {
      this.userPromptsService.showToast(this.labels.wrong_format_uploaded);
    }
  }

  deleteFile(file: AttachedDoc) {
    this.userPromptsService.showConfirmDialogue(
      this.labels.delete_doc_message_title,
      this.labels.delete_doc_message_body,
      async confirm => {
        if (confirm) {
          try {
            this.fileUploadService.deleteFile(file.ref).catch();
            await this.userService.deleteDoc(file.id, this.customer.id);
            this.userPromptsService.showToast(this.labels.document_deleted);
          } catch (e) {
            this.userPromptsService.showToast(this.labels.cannot_delete_doc);
          }
        }
      }
    );
  }

  async showFileInfo(file: AttachedDoc) {
    if (!file) {
      return;
    }

    const fileMeta = await this.fileUploadService.getFileInfo(file.ref);

    this.userPromptsService.showDialogue(DocInfoComponent, {
      fileName: file.name,
      serviceName: this.userRole.service?.name || '',
      areaName: this.userRole.workingArea?.name || '',
      uploadedAt: fileMeta.timeCreated,
    });
  }

  openDoc(url: string) {
    window.open(url, '_blank');
  }

  public isAreaEnabled(area: WorkingArea): boolean {
    if (this.userRole.roleType === RoleTypes.orgAdmin) {
      return true;
    }

    if (this.userRole.roleType === RoleTypes.professional) {
      const userId = this.localStorageService.getItemSync('user_id');
      return keys(area.professionals).indexOf(userId) > -1;
    }

    if (this.userRole.roleType === RoleTypes.coordinator) {
      for (const service of this.areaServices.filter(s => !!s)) {
        if (this.isServiceEnabled(service)) {
          return true;
        }
      }

      return false;
    }

    if (this.userRole.roleType === RoleTypes.excecutor) {
      let asExecutor = false;
      each(this.servicesMap[area.id], (service: Service) => {
        asExecutor = this.isServiceEnabled(service);
      });
      return asExecutor;
    }

    return false;
  }

  public isServiceEnabled(service: Service): boolean {
    if (this.userRole.roleType === RoleTypes.orgAdmin) {
      return true;
    }

    if (this.userRole.roleType === RoleTypes.professional) {
      return true;
    }

    if (this.userRole.roleType === RoleTypes.coordinator) {
      const userId = this.localStorageService.getItemSync('user_id');
      return keys(service.coordinators).indexOf(userId) > -1;
    }

    if (this.userRole.roleType === RoleTypes.excecutor) {
      const userId = this.localStorageService.getItemSync('user_id');
      return keys(service.excecutors).indexOf(userId) > -1;
    }

    return false;
  }

  onTabChange(e) {
    if (e.index === 3) {
      this.initCustomerRequests();
    }
  }

  calculateRequestsCountForAreas() {
    each(this.workAreas, (area: WorkingArea) => {
      let sum = 0;
      each(keys(area.services), (serviceId: string) => {
        sum += this.requestsMap[serviceId] ? this.requestsMap[serviceId].length : 0;
      });
      this.areasRequestsCount[area.id] = sum;
    });
  }

  getCustomFormColor(form: ICustomFormModel): string {
    if (form && this.userCustomForms[form.id]) {
      return 'primary';
    }

    return 'default';
  }
}
