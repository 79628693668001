import { Injectable } from '@angular/core';
import {
    Reservation,
    ReservationStatusTypes, ReservationStatusClass,
    SearchIndex, ReservationRepeatTypes, ReservationSeriesConfig, ReservationRepeatFormatTypes
} from '@shared/model';
import {
    startOfDay, addHours,
    endOfDay, parse,
    isValid, isBefore,
    isEqual, addWeeks,
    addMonths, addDays,
    getDay, startOfMonth, endOfMonth, subDays, getDate
} from 'date-fns';
import { Observable } from 'rxjs';
import { FirestoreService, DocItem } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { UtilitiesService } from './utilities.service';
import { UserService } from './user.service';
import { RoleTypes } from '@shared/enum';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs/operators';

export interface GeneratedReservationModel {
    valid: boolean;
    reservation: Reservation;
}

@Injectable({
    providedIn: 'root',
})
export class ReservationService {
    public gridCellIndices: number;
    public gridTimesStep: number;

    private colRef: string;

    constructor(
        private afDb: FirestoreService,
        private localStorageService: LocalStorageService,
        private utilitiesService: UtilitiesService,
        private userService: UserService
    ) {
        this.colRef = `organizations/${this.localStorageService.getItemSync('user_organization')}/reservations`;

        this.localStorageService.getItem('user_organization').subscribe(orgId => {
            this.colRef = `organizations/${orgId}/reservations`;
        });
    }

    public getStatusClass(reservation?: Reservation): ReservationStatusClass {
        if (reservation) {
            switch (reservation.status) {
                case ReservationStatusTypes.reservation_booked: {
                    return 'status_booked';
                }

                case ReservationStatusTypes.reservation_option: {
                    return 'status_option';
                }

                default: {
                    return 'status_empty';
                }
            }
        } else {
            return 'status_empty';
        }
    }

    public getReservationsList(
        status?: ReservationStatusTypes, dir?: 'past' | 'upcoming', search?: string, lastDoc?: any
    ): Observable<Reservation[]> {
        return this.afDb.colWithIds$(this.colRef, ref => {
            if (status) {
                ref = ref.where('status', '==', status);
            }

            if (!this.utilitiesService.rolesMatch(RoleTypes.coordinatorReservations)) {
                ref = ref.where('log.createdBy', '==', this.userService.getCurrentUserId());
            }

            if (search) {
                ref = ref.where(`searchIndex.index.${search.toLowerCase()}`, '==', true).limit(200);
            } else {
                // infinity scroll is used only without search
                ref = ref.orderBy('date', dir === 'upcoming' ? 'asc' : 'desc').limit(30);
                if (dir === 'past') {
                    ref = ref.where('date', '<=', new Date());
                } else if (dir === 'upcoming') {
                    ref = ref.where('date', '>=', new Date());
                }

                if (lastDoc) {
                    ref = ref.startAfter(lastDoc.__doc);
                }
            }

            return ref;
        }, true);
    }

    public getReservationsForInventory(inventoryId: string, start: Date, end: Date): Observable<Reservation[]> {
        const startDate = startOfDay(start);
        const endDate = endOfDay(end);
        return this.afDb.colWithIds$(this.colRef, ref => {
            return ref.where('inventoryId', '==', inventoryId)
                .where('date', '>=', startDate)
                .where('date', '<=', endDate);
        });
    }

    public getReservationsForSeries(repeatKey: string): Promise<Reservation[]> {
        return this.afDb.colWithIdsNoCache(this.colRef, ref => {
            return ref.where('seriesConfig.repeatKey', '==', repeatKey);
        });
    }

    public getSearchIndex(): SearchIndex {
        return {
            properties: ['inventoryTitle'],
            index: {}
        };
    }

    public timeToIndex(hourTime: number): number {
        return hourTime / this.gridTimesStep;
    }

    public createReservation(reservation: Reservation): Promise<any> {
        if (reservation.id) {
            return this.afDb.setDoc(`${this.colRef}/${reservation.id}`, reservation as DocItem);
        } else {
            return this.afDb.add(this.colRef, reservation);
        }
    }

    public batchUpdate(docs: DocItem[]): Promise<any> {
        return this.afDb.batchUpdate(docs, this.colRef);
    }

    public updateReservation(update: DocItem): Promise<any> {
        return this.afDb.update(`${this.colRef}/${update.id}`, update);
    }

    public batchDelete(ids: string[]): Promise<void> {
        return this.afDb.batchDelete(ids, this.colRef);
    }

    public deleteReservation(id: string): Promise<void> {
        return this.afDb.remove(`${this.colRef}/${id}`);
    }

    public generateSeriesReservation(reservation: Reservation): Promise<GeneratedReservationModel[]> {
        return new Promise(async (resolve) => {
            let rs: GeneratedReservationModel[] = [];

            const startDate: Date = endOfDay(parse(reservation.date));
            const endDate: Date = endOfDay(parse(reservation.seriesConfig.endDate));
            let dateCursor: Date = startDate;
            const repeatKey = reservation.seriesConfig.repeatKey || this.afDb.getNewId();

            if (isValid(dateCursor) && isValid(endDate)) {
                while (isBefore(dateCursor, endDate) || isEqual(dateCursor, endDate)) {
                    const newR: Reservation = cloneDeep(reservation);
                    newR.id = isEqual(startDate, dateCursor) ? repeatKey : this.afDb.getNewId();
                    newR.date = addHours(startOfDay(dateCursor), reservation.startTime);;
                    newR.seriesConfig.repeatKey = repeatKey;

                    rs.push({ valid: false, reservation: newR });
                    dateCursor = this.getNextDate(dateCursor, newR.seriesConfig);
                }

                rs = await this.handleReservationsValidity(rs);
            }

            resolve(rs);
        });
    }

    private handleReservationsValidity(rs: GeneratedReservationModel[]): Promise<GeneratedReservationModel[]> {
        const promises: Promise<GeneratedReservationModel>[] = rs.map(r => {
            return new Promise(resolve => {
                this.checkReservationDateValidity(r.reservation).then(status => {
                    r.valid = status;

                    resolve(r);
                }).catch(e => {
                    throw Error(e);
                });
            });
        });

        return Promise.all(promises);
    }

    public async checkReservationDateValidity(r: Reservation): Promise<boolean> {
        const reservations = await this.getReservationsForInventory(
            r.inventoryId, r.date, r.date
        ).pipe(take(1)).toPromise();

        for (const prev of reservations) {
            if (r.id !== prev.id && this.doesTimeOverlap(prev, r)) {
                return false;
            }
        }

        return true;
    }

    private doesTimeOverlap(rs1Val: Reservation, rs2Val: Reservation): boolean {
        const rs1 = cloneDeep(rs1Val);
        const rs2 = cloneDeep(rs2Val);

        // subtract millisecond from endTime to prevent overlap with next reservation's starttime
        rs1.endTime -= 0.01;
        rs2.endTime -= 0.01;

        const startIssue1 = rs2.startTime > rs1.startTime && rs2.startTime < rs1.endTime;
        const endIssue1 = rs2.endTime > rs1.startTime && rs2.endTime < rs1.endTime;

        const startIssue2 = rs1.startTime >= rs2.startTime && rs1.startTime <= rs2.endTime;
        const endIssue2 = rs1.endTime >= rs2.startTime && rs1.endTime <= rs2.endTime;

        return startIssue1 || endIssue1 || startIssue2 || endIssue2;
    }

    // gets x index weekday from specified month
    private getDayXFromMonth(weekDay: number, freq: number, monthDate: Date): Date {
        const firstDayOfMonth = startOfMonth(monthDate);
        const lastDayOfMonth = endOfMonth(monthDate);

        let cursor = firstDayOfMonth;
        let occurences = 0;

        // iterates from first month day to get the number of occurences of week day in month
        while (isBefore(cursor, lastDayOfMonth) || isEqual(cursor, lastDayOfMonth)) {
            const weekDayCursor = getDay(cursor);

            if (weekDay === weekDayCursor) {
                occurences++;
            }

            if (occurences === freq) {
                break;
            } else {
                cursor = addDays(cursor, 1);
            }
        }

        return cursor;
    }

    private getNextMonthlyDate(date: Date, config: ReservationSeriesConfig): Date {
        const newMonth = addMonths(date, 1);
        const weekDay = getDay(date);

        switch (config.repeatFormat) {
            case ReservationRepeatFormatTypes.first: {
                return this.getDayXFromMonth(weekDay, 1, newMonth);
            }

            case ReservationRepeatFormatTypes.second: {
                return this.getDayXFromMonth(weekDay, 2, newMonth);
            }

            case ReservationRepeatFormatTypes.third: {
                return this.getDayXFromMonth(weekDay, 3, newMonth);
            }

            case ReservationRepeatFormatTypes.fourth: {
                return this.getDayXFromMonth(weekDay, 4, newMonth);
            }

            case ReservationRepeatFormatTypes.last: {
                let w = startOfDay(endOfMonth(newMonth));

                for (let i = 6; i >= 0; i--) {
                    if (getDay(w) === weekDay) {
                        return w;
                    } else {
                        w = subDays(w, 1);
                    }
                }

                return this.getDayXFromMonth(weekDay, 1, newMonth);
            }

            default: {
                return newMonth;
            }
        }
    }

    private getNextDate(curDate: Date, config: ReservationSeriesConfig): Date {
        const repeatType: ReservationRepeatTypes = config.repeatType;

        switch (repeatType) {
            case ReservationRepeatTypes.daily: {
                return addDays(curDate, 1);
            }

            case ReservationRepeatTypes.weekly: {
                return addWeeks(curDate, 1);
            }

            case ReservationRepeatTypes.two_weeks: {
                return addWeeks(curDate, 2);
            }

            case ReservationRepeatTypes.monthly: {
                if (config.repeatMonthOnceCtrl) {
                    const targetDate = getDate(curDate);
                    let counter = 1;
                    let newMonth = addMonths(curDate, counter);

                    while (true) {
                        const day = getDate(newMonth);


                        if (day === targetDate) {
                            break;
                        } else {
                            newMonth = addMonths(curDate, counter);
                        }

                        counter++;
                        if (counter > 24) {
                            console.log('Why is counter > 24?');
                            break;
                        }
                    }

                    return newMonth;
                } else {
                    return this.getNextMonthlyDate(curDate, config);
                }
            }

            default: {
                throw Error('Invalid repeat type selected');
            }
        }
    }
}
