<app-modal>
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{data.title}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
        <p [innerHtml]="data.message"></p>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <button mat-stroked-button (click)="cancel()">{{config.cancelBtn || labels.no}}</button>
            <button color="primary" mat-raised-button (click)="continue()">{{config.confirmBtn || labels.yes}}</button>
        </div>
    </div>
</app-modal>