import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, from } from 'rxjs';
import { pluck, share } from 'rxjs/operators';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { LabelService } from '@core/services';
import { ModalComponent } from '@shared/components/modal/modal.component';
import {
  IInventoryItem,
  IInventoryLoanDetails,
  IInventoryLoanToFreeResponseModel
} from '@shared/model';

@Component({
  selector: 'app-inventory-loan-to-free-dialog',
  templateUrl: './inventory-loan-to-free-dialog.component.html',
  styleUrls: ['./inventory-loan-to-free-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryLoanToFreeDialogComponent extends ModalComponent implements OnInit {
  model: IInventoryItem;
  labels$: Observable<ILabelsProvider>;
  form: FormGroup;
  readonly maxNoteLen = 100;
  private readonly keys = {
    endDate: 'endDate',
    returnDate: 'returnDate',
    returnNote: 'returnNote'
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { model: IInventoryItem },
    private labelService: LabelService,
    private fb: FormBuilder,
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<InventoryLoanToFreeDialogComponent>
  ) {
    super(dialogRef, breakpointObserver);
    this.model = this.data.model;
  }
  ngOnInit(): void {
    this.labels$ = from(this.labelService.getLabels('inventory_loan_form')).pipe(
      pluck('data'),
      share()
    );
    const k = this.keys;
    const val: IInventoryLoanDetails = this.model.loanDetails || Object.create(null);
    console.log(val);

    this.form = this.fb.group({
      [k.endDate]: this.fb.control(new Date(val.endDate)),
      [k.returnDate]: this.fb.control(new Date()),
      [k.returnNote]: ''
    });
  }
  onSave(): void {
    const val = this.form.value as IInventoryLoanToFreeResponseModel;
    this.dialogRef.close(val);
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }
}
