import { Injectable } from '@angular/core';
import { QueryFn } from '@angular/fire/compat/firestore';
import { AppointmentModel } from '@models/model/appointment.model';
import { startOfDay, addHours, addMinutes, subDays } from 'date-fns';
import { Observable } from 'rxjs';
import { LocalStorageService, FirestoreService } from '..';
import { DocItem } from '../../../../../functions/src/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private orgId: string;
  public gridCellIndices: number;
  public gridTimesStep: number;

  constructor(
    private localStorageService: LocalStorageService,
    private db: FirestoreService,
  ) {
    this.localStorageService.getItem('user_organization').subscribe(res => {
      this.orgId = res;
    });
  }

  public combineDateAndTime(date: string, time: string): Date {
    let d = startOfDay(new Date(date));
    const parts = time.split(':');

    d = addHours(d, parseInt(parts[0] || '0'));
    d = addMinutes(d, parseInt(parts[1] || '0'));

    return d;
  }

  public getAppointmentsForUser(userId: string): Observable<AppointmentModel[]> {
    return this.db.colWithIds$(`organizations/${this.orgId}/appointments`, ref => {
      const ago = subDays(new Date(), 7);
      return ref
        .where('attendees', 'array-contains', userId)
        .where('date', '>=', ago)
        .limit(50);
    });
  }

  public saveAppointment(appointment: AppointmentModel): Promise<void> {
    appointment.id = appointment.id || this.db.getNewId();
    return this.db.setDoc(`organizations/${this.orgId}/appointments/${appointment.id}`, appointment as DocItem);
  }

  public deleteAppointment(appointmentId: string): Promise<void> {
    return this.db.remove(`organizations/${this.orgId}/appointments/${appointmentId}`);
  }

  /**
   * getAppointments
   */
  public getAppointments(appId: string, queryFn: QueryFn) {
    return this.db.colGroupWithIds$(appId, queryFn);
  }
}
