import { CountryApi } from "@models/model";

export const supportedCountries: CountryApi[] = [
    {
        "name": "netherlands",
        "iso3": "NLD",
        "iso2": "NL"
    },
    {
        "name": "belgium",
        "iso3": "BEL",
        "iso2": "BE"
    },
    {
        "name": "germany",
        "iso3": "DEU",
        "iso2": "DE"
    },
    {
        "name": "luxembourg",
        "iso3": "LUX",
        "iso2": "LU"
    },
    {
        "name": "austria",
        "iso3": "AUT",
        "iso2": "AT"
    },
    {
        "name": "switzerland",
        "iso3": "CHE",
        "iso2": "CH"
    },
    {
        "name": "france",
        "iso3": "FRA",
        "iso2": "FR"
    },
    {
        "name": "united Kingdom",
        "iso3": "GBR",
        "iso2": "GB"
    },
    {
        "name": "spain",
        "iso3": "ESP",
        "iso2": "ES"
    }
]