<div fxLayout="row" class="main">

  <div style="padding-right: 20px;">
    <mat-card class="no-header" style="margin: 0 auto; max-width: fit-content; min-width: 650px;  padding-top: 0px">
      @if (isLoading) {<mat-progress-bar mode="indeterminate"></mat-progress-bar>}
      <mat-card-header>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
          <mat-form-field appearance="outline" style="width: 150px; padding-right: 10px;">
            <mat-label>{{labels.search}}</mat-label>
            <input matInput [(ngModel)]="searchText" (ngModelChange)="checkTextFilterValid()"
              (keydown.enter)="handleSearch()">
          </mat-form-field>

          <div style="padding-left: 5px; padding-right: 10px; margin-top: 4px;">
            <button mat-mini-fab [disabled]="!searchText" (click)="clearFilters()">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <div fxFlex></div> <!-- This will push the button to the right -->

          <!-- <button style="margin-top: 4px;" mat-raised-button color="primary"
              (click)="openAddUserDialog()">{{labels.add_request}}</button> -->
        </div>
      </mat-card-header>
      <mat-selection-list>
        <mat-list-item *ngIf="tableData?.length === 0">
          <mat-list-item>
            <mat-icon matListItemIcon>information</mat-icon>
            <div matListItemTitle>{{labels.no_requests_found}}
            </div>
            <div matListItemLine>{{labels.empty_requests_executor_desc}}</div>
          </mat-list-item>

        </mat-list-item>
        <div class="grid-container" *ngIf="tableData.length">
          <div class="grid-item" *ngFor="let data of tableData"
            style="display: grid; grid-template-columns: 290px 230px 100px; gap: 0px;">

            <div>
              <mat-divider></mat-divider>
              <mat-list-item (click)="handleRowSelect(data)" lines="3">
                <mat-icon matListItemIcon [class]="'status-impl-parent status-color-'+data.status">
                  <span class="material-symbols-rounded">
                    {{getStatusNum(data.status)}}
                  </span>
                </mat-icon>
                <span matListItemTitle>{{data.customer}}</span>
                <span>{{data.title}}</span>
                @if (data.originalRequest?.activity?.firstScheduledActivity){
                <span matListItemLine style="color:red">{{data.originalRequest?.activity?.firstScheduledActivity |
                  date:'MMM dd'}}
                </span>
                }
              </mat-list-item>

            </div>
            <div>
              <mat-divider></mat-divider>
              <mat-list-item (click)="handleRowSelect(data)" lines="3">
                <div matListItemTitle>{{data.service}}</div>
                <div matListItemLine>{{data.team}}</div>
              </mat-list-item>
            </div>
            <div>
              <mat-divider></mat-divider>

              <div>
              </div>
            </div>
          </div>
        </div>
      </mat-selection-list>
    </mat-card>
  </div>

  <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '90%', 'min-height': '150px',  'max-width': '500px'}"
      fxLayoutAlign.xs="space-around">

      <div fxLayout="column" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.status}}</mat-label>
          <mat-select multiple [(ngModel)]="selectedStatuses" name="statuses" (valueChange)="onFilterChange()">
            <mat-option *ngFor="let status of defaultStatuses; " [value]="status">
              {{labels[RequestStatus[status]]}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.service}}</mat-label>
          <mat-select multiple [(ngModel)]="selectedServices" name="statuses" (valueChange)="onFilterChange()">
            <mat-option *ngFor="let service of services; " [value]="service.id">
              {{ service.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>
  </div>