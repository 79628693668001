<div fxLayout="row" class="main">

  <div style="padding-right: 20px;">
    <mat-card class="no-header" style="margin: 0 auto; max-width: fit-content; min-width: 650px;  padding-top: 0px">
      @if (isLoading) {<mat-progress-bar mode="indeterminate"></mat-progress-bar>}
      <mat-card-header>
        <div fxLayout="row" fxFlex="stretch">
          <mat-form-field appearance="outline" style="width: 150px; padding-right: 10px;">
            <mat-label>{{labels.search}}</mat-label>
            <input matInput [(ngModel)]="requestFilter.textFilter" (ngModelChange)="checkTextFilterValid()"
              (keydown.enter)="handleFilterSubmit()">
          </mat-form-field>

          <div style="padding-left: 5px; padding-right: 10px; margin-top: 4px;">
            <button mat-mini-fab [disabled]="!anyNonSearchTextFiltersSet() && !requestFilter.textFilter"
              (click)="clearFilters()">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <div fxFlex></div> <!-- This will push the button to the right -->

          <button style="margin-top: 4px;" mat-raised-button color="primary"
            (click)="openAddUserDialog()">{{labels.add_request}}</button>
        </div>

      </mat-card-header>
      <mat-selection-list>
        <mat-list-item *ngIf="dataTableConfig?.length === 0">
          <mat-list-item>
            <mat-icon matListItemIcon>information</mat-icon>
            <div matListItemTitle>{{isAllEmpty ? labels.no_requests_found : labels.no_requests_found }}
            </div>
            <div matListItemLine>{{isAllEmpty ? labels.empty_requests_desc : labels.empty_requests_desc_alt}}</div>
          </mat-list-item>

        </mat-list-item>
        <div class="grid-container">
          <div class="grid-item" *ngFor="let data of dataTableConfig"
            style="display: grid; grid-template-columns: 290px 230px 100px; gap: 0px;">

            <div>
              <mat-divider></mat-divider>
              <mat-list-item (click)="handleRowSelect(data)" lines="3">
                <mat-icon matListItemIcon [class]="'status-impl-parent status-color-'+data.status">
                  <span class="material-symbols-rounded">
                    {{getStatusNum(data.status)}}
                  </span>
                </mat-icon>
                <span matListItemTitle>{{data.customer}}</span>
                <span>{{data.title}}</span>
                @if (data.originalRequest?.activity?.hasOverDueActivity ){
                <span matListItemLine style="color:red">{{labels.activity_past_due_date}}:
                  {{data.originalRequest?.activity?.firstScheduledActivity |
                  date:'MMM dd'}}
                </span>
                }
              </mat-list-item>

            </div>
            <div>
              <mat-divider></mat-divider>
              <mat-list-item (click)="handleRowSelect(data)" lines="3">
                <div matListItemTitle>{{data.executor}}</div>
                <div matListItemLine>{{data.team}}</div>
              </mat-list-item>
            </div>
            <div>
              <mat-divider></mat-divider>

              <div>

                <div fxLayout="column" fxLayoutAlign="center" style="padding-top: 5px;">
                  <div fxLayout="row" fxLayoutAlign="center">
                    <button mat-icon-button (click)="openUsersInfoDialog(data.originalRequest)">
                      <mat-icon>
                        <span class="material-symbols-outlined">contact_page</span>
                      </mat-icon>
                    </button>
                    <button mat-icon-button (click)="openChecklistDialog(data.originalRequest)">
                      <mat-icon>checklist</mat-icon></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-selection-list>
    </mat-card>
  </div>

  <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="20px">
    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '90%', 'min-height': '150px',  'max-width': '500px'}"
      fxLayoutAlign.xs="space-around">
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline">
          <mat-label>{{labels.status}}</mat-label>
          <mat-select (selectionChange)="handleFilterSubmit()" [(ngModel)]="requestFilter.statusFilter" name="status">
            <mat-option class="status-item status-default" [value]="null">
              <div fxLayout="row" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="start center">
                <ng-container *ngTemplateOutlet="statusNumberTmpl;context: {num: 0}"></ng-container>
                <div fxFlex="stretch">{{labels.show_all}}</div>
              </div>
            </mat-option>
            <mat-option [ngClass]="['status-item status-'+status]" *ngFor="let status of getStatuses()"
              [value]="status">
              <div fxLayout="row" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="start center">
                <ng-container *ngTemplateOutlet="statusNumberTmpl;context: {num: status}"></ng-container>
                <div fxFlex="stretch">{{labels[RequestStatus[status]]}}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button color="primary" aria-label="Advance" (click)="advance()">
          {{!advanceFilterEnabled ? labels.more_filters : labels.less_filters}}
        </button>

      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.year}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.activeInYearFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let y of yearsList" [value]="y">
              {{y}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.month}}</mat-label>
          <mat-select multiple [(ngModel)]="requestFilter.activeInMonthFilter"
            [disabled]="!requestFilter.activeInYearFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let m of monthNames" [value]="m.id">
              {{m.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </mat-card>

    <mat-card fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '90%', 'min-height': '150px',  'max-width': '500px'}"
      fxLayoutAlign.xs="space-around">
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.working_area}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.workingAreaFilter"
            [disabled]="userRole.roleType === 2 || userRole.roleType === 3 ">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let v of workingAreas" [value]="v?.id">
              {{v?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.service}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.serviceFilter" [disabled]="userRole.roleType === 2"
            (ngModelChange)="getServices()" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let s of services | sort:'name'" [value]="s.id">
              {{s.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.executor}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.executorFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let exec of executors" [value]="exec.id">
              {{exec.fullname}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.coordinator}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.coordinatorFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let coord of coordinators" [value]="coord.id">
              {{coord.fullname}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
        @if (requestFilter.executorFilter) {
        <mat-checkbox color="primary" style="padding-bottom: 5px;" [(ngModel)]="requestFilter.teamMemberFilter"
          (change)="handleFilterSubmit()">
          {{labels.include_team_member}}
        </mat-checkbox>
        }

        @if (requestFilter.teamMemberFilter) {
        <mat-checkbox color="primary" style="padding-bottom: 5px;" [(ngModel)]="requestFilter.onlyTeamMemberFilter"
          (change)="handleFilterSubmit()">
          {{labels.as_team_member_only}}
        </mat-checkbox>
        }
      </div>

      @if (advanceFilterEnabled) {
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.referred_by}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.referredByFilter"
            [disabled]="!selectedWorkingArea?.settings?.referredByValues" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let v of selectedWorkingArea.settings.referredByValues" [value]="v">
              {{v}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.result}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.resultFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let rr of requestResult" [value]="rr.id">
              {{rr.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.referred_by_institution}}</mat-label>
          <input matInput [value]="requestFilter.referredByInstitution?.name || ''" type="text" readonly>

          <mat-icon style="max-height: fit-content;" matSuffix class="cursor_pointer" color="primary"
            (click)="openInstitutionAndContactPersonSelector('referredByInstitution')">store</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.referred_by_contact_person}}</mat-label>
          <input matInput [value]="requestFilter.referredByContactPerson?.firstname" type="text" readonly>

          <mat-icon style="max-height: fit-content;" matSuffix color="primary" class="cursor_pointer"
            [ngStyle]="{'color': requestFilter.referredByInstitution ? '' : 'gray'}"
            (click)="openInstitutionAndContactPersonSelector('referredByContactPerson')">
            account_box_multiple
          </mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.kind}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.kindFilter" [disabled]="!requestFilter.workingAreaFilter"
            (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let kind of kindsList" [value]="kind">
              {{kind}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{labels.product}}</mat-label>
          <mat-select [(ngModel)]="requestFilter.productFilter" (selectionChange)="handleFilterSubmit()">
            <mat-option [value]="null">
              {{labels.show_all}}
            </mat-option>
            <mat-option *ngFor="let prod of productsList" [value]="prod.code">
              {{prod.name}} ({{prod.code}})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      }

    </mat-card>

    <mat-card *ngIf="advanceFilterEnabled" fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '90%', 'min-height': '150px',  'max-width': '500px'}"
      fxLayoutAlign.xs="space-around">
      @if (fetchingMunicipalities) {<mat-progress-bar style="margin-bottom: 5px;"
        mode="indeterminate"></mat-progress-bar>}
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{labels.municipality}}</mat-label>
            <input [disabled]="municipalities?.length === 0" type="text" matInput
              [(ngModel)]="requestFilter.municipalityFilter" [matAutocomplete]="auto"
              (ngModelChange)="onMunicipality($event)">
            <mat-icon matSuffix style="cursor: pointer" *ngIf="canClearText && municipalities?.length"
              (click)="clearSelection()">close
            </mat-icon>
            <mat-icon matSuffix style="cursor: pointer" *ngIf="!municipalities?.length"
              (click)="loadMunicipalities()">refresh</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayMunicipalityName">
              <mat-option *ngFor="let m of filterMunicipalities()" [value]="m">
                {{m.text}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{labels.district}}</mat-label>
            <mat-select [disabled]="!requestFilter.municipalityFilter" [(ngModel)]="requestFilter.districtFilter"
              name="district" (selectionChange)="handleFilterSubmit()">
              <mat-option [value]="null">{{labels.show_all}}</mat-option>
              <mat-option *ngFor="let district of districts;" [value]="district.code">{{district.text}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{labels.neighborhood}}</mat-label>
            <mat-select [disabled]="!requestFilter.districtFilter" [(ngModel)]="requestFilter.neighborhoodFilter"
              name="neighborhood" (selectionChange)="handleFilterSubmit()">
              <mat-option [value]="null">{{labels.show_all}}</mat-option>
              <mat-option *ngFor="let neighborhood of getNeighborhoodList()" [value]="neighborhood.code">
                {{neighborhood.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>

    <mat-card *ngIf="advanceFilterEnabled" fxLayout="column"
      [style]="{width: isExtraSmall ? '100%' : '90%', 'min-height': '160px',  'max-width': '250px'}"
      fxLayoutAlign.xs="space-around">
      <div *ngIf="advanceFilterEnabled">
        <mat-checkbox color="primary" [(ngModel)]="requestFilter.overDueFilter" (change)="handleFilterSubmit()">
          {{labels.over_due}}
        </mat-checkbox>
      </div>
      <div *ngIf="advanceFilterEnabled">
        <mat-checkbox color="primary" [(ngModel)]="requestFilter.publishedFilter" (change)="handleFilterSubmit()">
          {{labels.published}}
        </mat-checkbox>
      </div>
      <div *ngIf="advanceFilterEnabled">
        <mat-checkbox color="primary" [(ngModel)]="requestFilter.signalFilter" (change)="handleFilterSubmit()">
          {{labels.signal}}
        </mat-checkbox>
      </div>
    </mat-card>
    <button mat-mini-fab color="accent" aria-label="Export Requests" (click)="exportRequests()">
      <mat-icon>download</mat-icon>
    </button>
    <!-- <button mat-stroked-button (click)="updateActivities()">
      update activity
    </button> -->
  </div>

  <div style="margin-top: -10px;" class="footer">
    <mat-paginator (page)="onPaginationChange($event)" [length]="paginationOptions.totalSize"
      [pageSize]="paginationOptions.requestsPerPage" [pageIndex]="paginationOptions.pageIndex"
      [pageSizeOptions]="[5, 10, 30, 50]" aria-label="Select page">
    </mat-paginator>
  </div>

  <ng-template #statusNumberTmpl let-num="num">
    <div [class]="'status-impl-parent status-bg-'+num" fxLayout="row" fxLayoutAlign="center center">
      <div [class]="'status-tmpl status-color-'+num" fxLayout="row" fxLayoutAlign="center center">
        {{num}}
      </div>
    </div>
  </ng-template>

  <ng-template #customChecklistBtnBody let-row="row">
    <span *ngIf="canShowChecklistIcon(row.originalRequest?.management.serviceId)" [class]="checklistBtnColor(row)"
      (click)="openChecklistDialog(row.originalRequest)">
      <mat-icon>checklist</mat-icon>
    </span>
  </ng-template>