export enum AuthPhase {
    email = 1,
    login,
    register,
    userNotFound,
    emailUnverified,
    resetPassword,
    secondFA,
    secondFACode,
}
