<div class="card-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>


    <mat-card-content>
      <p [innerHtml]="description"></p>

      <ng-content select="[belowDescription]"></ng-content>
    </mat-card-content>

    <mat-card-actions *ngIf="actionText">
      <button mat-button color="primary" (click)="sendAction()" [disabled]="actionDisabled">{{actionText}}</button>
    </mat-card-actions>
  </mat-card>
</div>