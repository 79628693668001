import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';

export interface RepeatConfig {
  daily: {
    repeatTimes: number;
  };
  weekly: {
    repeatTimes: number;
    repeatInterval: number;
    repeatOn: { text: string; value: number }[];
  }
}

@Component({
  selector: 'app-repeat-dialog',
  templateUrl: './repeat-dialog.component.html',
  styleUrls: ['./repeat-dialog.component.scss']
})
export class RepeatDialogComponent implements OnInit {

  public labels: any = {};
  protected repeatType: 'daily' | 'weekly' = 'daily';
  protected repeatOn = [{ text: 'Sun', value: 0 }, { text: 'Mon', value: 1 }, { text: 'Tues', value: 2 }, { text: 'Wed', value: 3 }, { text: 'Thurs', value: 4 }, { text: 'Fri', value: 5 }, { text: 'Sat', value: 6 }];
  protected inputData: RepeatConfig;
  protected canProceed: boolean;
  protected isCreating: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { fn: (data: { repeat: RepeatConfig, type: 'daily' | 'weekly' }) => Promise<void> },
    private labelService: LabelService,
    private dialogRef: MatDialogRef<RepeatDialogComponent>,
  ) { }

  async ngOnInit(): Promise<void> {
    this.inputData = { daily: { repeatTimes: null }, weekly: { repeatInterval: null, repeatOn: null, repeatTimes: null } }
    this.labels = (await this.labelService.getLabels('app-repeat-dialog')).data;
  }

  protected canCreate() {
    const obj = this.inputData[this.repeatType];
    this.canProceed = Object.keys(obj).every(key => {
      if (obj[key] != null || obj[key] != undefined) return true;
      else return false;
    })
  }

  protected closeModal() {
    this.dialogRef.close();
  }

  protected async create() {
    this.isCreating = true;
    await this.data.fn({ repeat: this.inputData, type: this.repeatType });
    this.isCreating = false;
    this.dialogRef.close();
  }
}
