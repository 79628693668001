import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService, QueryFN } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { Service, ServiceCatalogActionTypes } from 'app/shared/model';
import { chunk, flatten } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class OrgServiceService {
  private orgId: string;

  constructor(
    private afsDB: FirestoreService,
    private localStorageService: LocalStorageService,
  ) {
    this.localStorageService.getItem('user_organization').subscribe((res) => {
      this.orgId = res;
    });
  }

  public getServices(active: Boolean, workingAreaId?: string): Observable<Service[]> {
    return this.afsDB.colWithIds$(`/organizations/${this.orgId}/services`, (refObj) => {
      let ref = refObj;
      ref = ref.where('active', '==', active);
      if (workingAreaId) {
        ref = ref.where('workingAreaId', '==', workingAreaId);
      }
      return ref;
    });
  }

  public getServicesNoCacheWithFilters(queryfn?: QueryFN): Promise<Service[]> {
    return this.afsDB.colWithIdsNoCache(`/organizations/${this.orgId}/services`, queryfn);
  }

  public getServicesNoCache(active: boolean, workingAreaId?: string): Promise<Service[]> {
    return this.getServicesNoCacheWithFilters((refObj) => {
      let ref = refObj;
      ref = ref.where('active', '==', active);
      if (workingAreaId) {
        ref = ref.where('workingAreaId', '==', workingAreaId);
      }
      return ref;
    });
  }
  
  public async getServicesByIds(serviceIds: string[]) {
    const services: Service[] = [];
    const data = await Promise.all(
      chunk(serviceIds, 30).map(ids => {
        return this.getServicesNoCacheWithFilters(ref => ref.where('id', 'in', ids))
      })
    )
    services.push(...flatten(data));
    return services;
  }

  public getServicesForExecutor(executorId: string): Promise<Service[]> {
    return this.getServicesNoCacheWithFilters((ref) => {
      return ref.where('active', '==', true)
        .where(`excecutors.${executorId}`, '==', true);
    });
  }

  public getServicesForCoordinator(coordinatorId: string): Promise<Service[]> {
    return this.getServicesNoCacheWithFilters((ref) => {
      return ref.where('active', '==', true)
        .where(`coordinators.${coordinatorId}`, '==', true);
    });
  }

  public getServicesWithActiveCatalog(workingAreaId?: string, type?: ServiceCatalogActionTypes, redirect?: boolean): Observable<Service[]> {
    return this.afsDB.colWithIds$(`/organizations/${this.orgId}/services`, (refObj) => {
      let ref = refObj;
      ref = ref.where('active', '==', true)

      if (workingAreaId) {
        ref = ref.where('workingAreaId', '==', workingAreaId);
      }

      if (type) {
        ref = ref.where('settings.catalog.action', '==', type);
      }

      if (redirect) {
        ref = ref.where('settings.catalog.redirectActivated', '==', true);
      } else {
        ref = ref.where('settings.catalog.activated', '==', true);
      }

      return ref;
    });
  }

  public getServicesFromRefs(ids: string[]): Observable<Service[]> {
    return this.afsDB.docsFromId$(`/organizations/${this.orgId}/services`, ids);
  }

  public getSharedServices(serviceId: string): Observable<Service[]> {
    return this.afsDB.colWithIds$(`/organizations/${this.orgId}/services`, ref => {
      return ref.where('settings.shareWithServices', 'array-contains', serviceId);
    });
  }

  getServiceById(id: string): Observable<Service> {
    return this.afsDB.docWithId$(`/organizations/${this.orgId}/services/${id}`);
  }

  updateService(service) {
    return this.afsDB.update(`/organizations/${this.orgId}/services/${service.id}`, service);
  }

  createService(service) {
    return this.afsDB.add(`/organizations/${this.orgId}/services`, service);
  }

  deleteService(serviceId: string) {
    return this.afsDB.doc(`/organizations/${this.orgId}/services/${serviceId}`).delete();
  }
}
