import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss']
})

export class ProgressComponent {
    @Input() width: string;
    @Input() percentage: number;
    @Input() color: string;
    @Input() message: string;
}
