import { Injectable } from '@angular/core';
import { UtilitiesService } from './utilities.service';
import { LocalStorageService } from './local-storage.service';
import { RoleTypes } from '@shared/enum';
import { Role } from '@shared/model';

@Injectable({
    providedIn: 'root',
})
export class CoordinatorService {
    constructor(
        private utilitiesService: UtilitiesService,
        private localStorageService: LocalStorageService
    ) { }

    public isFindCompanyActivated(): boolean {
        const isCoord = this.utilitiesService.rolesMatch(RoleTypes.coordinator);

        if (!isCoord) {
            return false;
        }

        const val = this.localStorageService.getItemSync('user_role');
        let role: Role = JSON.parse(val);

        return role.service
            && role.service.settings.findCompany
            && role.service.settings.findCompany.activated;
    }
}
