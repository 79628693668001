import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService } from '@core/services/labels.service';
import { User, Address } from '@shared/model';
import { UtilitiesService } from '@core/services';
import { parse, isValid } from 'date-fns';

interface InputPayload {
    email: string;
    users: User[];
    firstname?: string;
    lastname?: string;
    address?: Address;
}

@Component({
    selector: 'app-confirm-new-user',
    styleUrls: ['./confirm-new-user.component.css'],
    templateUrl: './confirm-new-user.component.html',
})
export class ConfirmNewUserComponent implements OnInit {
    public labels: any = {};
    public translatedTextWithName: string;
    public translatedTextWithAddress: string;
    public translatedTextWithEmail: string;

    constructor(
        private dialogRef: MatDialogRef<ConfirmNewUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputPayload,
        private labelService: LabelService,
        private utilitiesService: UtilitiesService
    ) { }

    async ngOnInit() {
        this.labels = (await this.labelService.getLabels('app-confirm-new-user')).data;

        if (this.data.address) {
            this.translatedTextWithAddress = this.labels._translate('double_address_body_text',
                { postalcode: this.data.address.postalcode, number: this.data.address.number }
            );
        } else if (this.data.email) {
            this.translatedTextWithEmail = this.labels._translate('double_email_body_text',
                { email: this.data.email }
            );
        } else {
            this.translatedTextWithName = this.labels._translate('double_name_body_text',
                { firstname: this.data.firstname, lastname: this.data.lastname }
            );
        }
    }

    public getBirthday(date: Date): string {
        const dateVal = parse(date);
        return isValid(dateVal) ? this.utilitiesService.getLocaleDateString(dateVal) : '-';
    }

    public continue() {
        this.dialogRef.close();
    }
}
