import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from 'app/../environments/environment';
import Rollbar from 'rollbar';

let rollbar: Rollbar;

@Injectable({
    providedIn: 'root',
})
export class RollbarErrorHandler extends ErrorHandler {
    constructor() {
        super();
    }

    handleError(err: any) {
        const isRollbarEnabled = environment.platform === 'staging'
            || environment.platform === 'live';

        if (isRollbarEnabled) {
            let meta: any = {};
            let person: any = {};

            if (localStorage.getItem('user_id')) {
                meta = localStorage;

                person = {
                    id: localStorage.getItem('user_id'),
                    email: localStorage.getItem('user_email'),
                };
            }

            // initialize rollbar error handler here if not already initialized
            rollbar = rollbar || new Rollbar({
                accessToken: '97e0ec883efc48d9810f640b0e677764',
                code_version: 'git@gitlab.com:hansanker/volunteer4work.git',
                codeVersion: 'git@gitlab.com:hansanker/volunteer4work.git',
                captureUncaught: true,
                captureUnhandledRejections: true,
                payload: {
                    person,
                    client: {
                        javascript: {
                            code_version: environment.platform,
                            guess_uncaught_frames: true
                        }
                    }
                },
                environment: environment.platform
            });

            rollbar.error(
                err.originalError || err,
                meta
            );
        }

        // This handles error locally when we are in dev environment
        super.handleError(err);
    }
}
