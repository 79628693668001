import { Injectable } from '@angular/core';
import { IObjectMap } from '@models/interface';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private data: IObjectMap<any> = {};

  constructor() { }

  public setData<T>(key: string, val: T): void {
    this.data[key] = val;
  }

  public getData<T>(key: string): T {
    return this.data[key];
  }
}
