import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, LocalStorageService, OrganizationService, OrgServiceService, UserPromptsService, WorkingAreaService } from '@core/services';
import { Role, Service, WorkingArea } from '@models/model';
import { take } from 'rxjs/operators';
import { ModalComponent } from '../modal/modal.component';

export enum CreationDialogTypes {
  service = 1,
  workingArea
}

@Component({
  selector: 'app-creation-dialog',
  templateUrl: './creation-dialog.component.html',
  styleUrls: ['./creation-dialog.component.scss']
})
export class CreationDialogComponent extends ModalComponent implements OnInit {

  public labels: any = {}
  public creationForm: FormGroup;
  public userRole: Role;
  public isLoading = false;
  
  constructor(
    private labelService: LabelService,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, type: CreationDialogTypes },
    private fb: FormBuilder,
    private orgServiceService: OrgServiceService,
    private workingAreaService: WorkingAreaService,
    private localStorageService: LocalStorageService,
    public dialogRef: MatDialogRef<any>,
    public breakpointObserver: BreakpointObserver,
    private userPromptsService: UserPromptsService,
    private orgService: OrganizationService
  ) {
    super(dialogRef, breakpointObserver);
   }

  async ngOnInit(): Promise<void> {
    this.dialogRef.disableClose = true;
    this.labels = (await this.labelService.getLabels('app-creation-dialog')).data;

    this.creationForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required]
    })
    // load the working area this user belongs to
    this.userRole = JSON.parse(this.localStorageService.getItemSync('user_role'));

  }


  public async save() {
    this.isLoading = true;
    if (this.data.type === CreationDialogTypes.service) this.createService();
    else this.createWorkingArea();
  }

  private async createService() {
    const model = this.creationForm.value;
    const workingArea = await this.workingAreaService.getWorkingAreaById(this.userRole.workingAreaId).pipe(take(1)).toPromise();

    const org = await this.orgService.getCurrentOrganization().pipe(take(1)).toPromise();
    const service: Service = JSON.parse(JSON.stringify(Object.assign(new Service, model)))
    service.workingAreaId = workingArea.id;
    service.settings.requestsExpiry = org?.expiry?.requests;
    const res = await this.orgServiceService.createService(service);

    // here we add the service id to the working area services
    workingArea.services[res.id] = true;
    this.workingAreaService.updateWorkingArea(workingArea);
    service.id = res.id;
    this.closeDialog(service);
  }

  private async createWorkingArea() {
    const model = this.creationForm.value;
    const workingArea: WorkingArea = JSON.parse(JSON.stringify(Object.assign(new WorkingArea, model)))
    const res = await this.workingAreaService.createWorkingArea(workingArea);
    workingArea.id = res.id
    this.closeDialog(workingArea);
  }

  private closeDialog(data: any) {
    this.userPromptsService.showToast(this.labels.details_created);
    this.dialogRef.close(data);
  }

}
