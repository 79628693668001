<app-modal [alignFooterStart]="true">
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
    <span class="modal_title" *ngIf="!mailinglist">{{labels.export_employees}}</span>
    <span class="modal_title" *ngIf="mailinglist">{{labels.export_mailinglist}} {{mailinglist.name}}</span>
  </div>

  <div class="modal_body" #modal_body>
    <div class="export-section">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{labels.workingarea}}</mat-label>
        <mat-select multiple [disabled]="userRole.workingArea && !data.mailinglist"
          [(ngModel)]="selectedWorkAreas">
          <mat-option *ngFor="let workarea of workareas" [value]="workarea">
            {{workarea.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="checkbox">
        <mat-checkbox color="primary" [(ngModel)]="exportCoordinators">
          {{labels.coordinators}}
        </mat-checkbox>
      </div>
      <div class="checkbox">
        <mat-checkbox color="primary" [(ngModel)]="exportExcecutors">
          {{labels.executors}}
        </mat-checkbox>
      </div>
      <div class="checkbox">
        <mat-checkbox color="primary" [(ngModel)]="exportProfessionals">
          {{labels.professionals}}
        </mat-checkbox>
      </div>
      <div class="checkbox" *ngIf="mailinglist">
        <mat-checkbox color="primary" [(ngModel)]="exportOthers">
          {{labels.others}}
        </mat-checkbox>
      </div>

      <div class="checkbox">
        <mat-slide-toggle color="primary" [(ngModel)]="uniqueUsers">{{labels.unique_users}}</mat-slide-toggle>
      </div>
      <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{labels.filter_birthday_month}}</mat-label>
          <input matInput type="number" min="1" max="12" [(ngModel)]="birthdayMonth" value=1>
        </mat-form-field>

      </div>
      <mat-progress-bar mode="indeterminate" [style.opacity]="isGenerating ? 1 : 0"></mat-progress-bar>

    </div>
  </div>

  <div class="modal_footer" #modal_footer fxLayoutGap="10px" fxLayout="row">

    <div fxLayout="row" fxLayoutGap="10px">
      <button mat-raised-button [disabled]="!usersMap" (click)="exportUsers()">
        {{labels.export}}
      </button>
    </div>
  </div>
</app-modal>