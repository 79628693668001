import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Role
} from '@shared/model';
import { ApiService } from './api.service';
import { IObjectMap } from '@models/interface';


@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private roles$: IObjectMap<BehaviorSubject<Role[]>> = {};

  public constructor(
    private apiService: ApiService
  ) { }

  public getRoles(userId: string, refresh: boolean = false): Observable<Role[]> {
    if (refresh) this.roles$[userId] = null;
    if (!this.roles$[userId]) {
      this.roles$[userId] = new BehaviorSubject(null);
    }

    this.apiService.get<Role[]>(`users/${userId}/roles/list`).pipe(take(1)).subscribe(res => {
      const roles = res.success ? res.data : [];
      this.roles$[userId].next(roles);
    });

    return this.roles$[userId].pipe(
      filter(r => !!r)
    );
  }
}
