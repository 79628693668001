import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';

export interface ImageViewConfig {
  url: string;
  width: string;
  height: string;
  alt?: string;
}

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {

  public labels: any = {};
  
  constructor(
    private labelService: LabelService,
    @Inject(MAT_DIALOG_DATA) public config: ImageViewConfig,
    public dialogRef: MatDialogRef<ImageViewComponent>,
  ) { }

  async ngOnInit(): Promise<void> {
    this.labels = (await this.labelService.getLabels('app-event-list')).data;
  }

  public cancel() {
    this.dialogRef.close(false)
  }

  public edit() {
    this.dialogRef.close(true);
  }

}
