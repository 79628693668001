import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilitiesService, UserService } from '@core/services';
import { RoleTypes } from '@shared/enum';
import { User } from '@shared/model';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ReservationsListGuard  {
    public constructor(
        private utilitiesService: UtilitiesService,
        private router: Router,
        private userService: UserService
    ) { }

    public async canActivate(): Promise<boolean> {
        let match = this.utilitiesService.rolesMatch(
            RoleTypes.coordinatorReservations
        );

        let user: User;
        if (!match) {
            user = await this.userService.getCurrentUser().pipe(take(1)).toPromise();

            if (user && user.moduleReservations && user.moduleReservations.user) {
                match = true;
            }
        }

        if (!match) {
            this.router.navigateByUrl('/');
        }

        return match;
    }
}
