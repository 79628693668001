import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { Feedback } from 'app/shared/model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {
    constructor(
        private afsDB: FirestoreService
    ) { }

    public saveFeedback(feedback: Feedback): Promise<any> {
        return this.afsDB.col('/feedback').add(feedback);
    }

    public getFeedbackList(): Observable<Feedback[]> {
        const colRef = '/feedback';
        return this.afsDB.colWithIds$(colRef, (ref) => {
            return ref.orderBy('date.timeStamp', 'desc');
        });
    }

    public updateFeedback(feedback: Feedback): Promise<any> {
        return this.afsDB.doc(`/feedback/${feedback.id}`).update(feedback);
    }
    public deleteFeedback(feedback: Feedback): Promise<any> {
        return this.afsDB.doc(`/feedback/${feedback.id}`).delete();
    }
}
