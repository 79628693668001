import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard  {
    public constructor(private auth: AuthService,
        private router: Router,
        private localStorageService: LocalStorageService
    ) { }

    public canActivate(): Promise<boolean> {
        return new Promise(async (resolve) => {
            const isLoggedIn = await this.auth.isLoggedIn();

            if (isLoggedIn) {
                const orgName = this.localStorageService.getItemSync('user_organization');
                const role = this.localStorageService.getItemSync('user_role');
                const userId = this.localStorageService.getItemSync('user_id');

                if (orgName && role && userId) {
                    const lastPath: string = localStorage.getItem('last_path');
                    const url = lastPath ? lastPath : `/${orgName}/dashboard`;

                    this.router.navigateByUrl(url);
                } else {
                    this.auth.logout();
                }
            }

            return resolve(!isLoggedIn);
        });
    }
}
