import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LabelService, UserPromptsService, UserService } from '@core/services';
import { IObjectMap } from '@models/interface';
import { Request, User, UserInfoItem } from '@models/model';
import { AvailabilitySelectorComponent } from '@shared/entry-components/availability-selector/availability-selector.component';
import { keys, uniq } from 'lodash';
import { NgSub } from 'ng-sub';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-executor-team',
  templateUrl: './executor-team.component.html',
  styleUrls: ['./executor-team.component.scss']
})
export class ExecutorTeamComponent implements OnInit, OnDestroy {
  @Input() request: Request;
  @Input() executors: User[];

  @Output() onChange = new EventEmitter<IObjectMap<UserInfoItem>>();

  public team: User[] = [];
  public labels = this.labelService.defaultProvider();

  private sub = new NgSub();

  constructor(
    private labelService: LabelService,
    private userPromptsService: UserPromptsService,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    this.fetchUsers();

    this.labels = (await this.labelService.getLabels('app-executor-team')).data;
  }

  private fetchUsers(): void {
    const userIds = keys(this.request.management.team || {});

    if (userIds.length > 0) {
      this.userService.getUsersFromIds(uniq(userIds)).pipe(takeUntil(this.sub)).subscribe({
        next: users => {
          this.team = users;
        },
        error: e => console.error(e),
      });
    }
  }

  private outputResult(): void {
    const payload: IObjectMap<UserInfoItem> = {};

    for (const u of this.team) {
      payload[u.id] = {
        userId: u.id,
        firstname: u.firstname,
        lastname: u.lastname,
        picture: u.picture,
        phone: u.phone || u.phone2,
        remark: ''
      };
    }

    this.onChange.emit(payload);
  }

  public openAvailabilityDialog(): void {
    this.userPromptsService.showDialogue(AvailabilitySelectorComponent, {
      users: this.executors,
      selected: this.team,
      customer: null,
    }, (selected: User) => {
      if (selected) {
        const index = this.team.findIndex(m => m.id === selected.id);

        if (index >= 0) {
          this.team[index] = selected;
        } else {
          this.team.push(selected);
        }

        this.outputResult();
      }
    }, true);
  }

  public removeMember(member: User): void {
    const index = this.team.findIndex(m => m.id === member.id);

    if (index >= 0) {
      this.team.splice(index, 1);
      this.outputResult();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
