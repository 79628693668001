import { DocumentLog } from './documentLog';
import { QuickNote } from './activity';
import { RequestStatus } from '../enum';
import { ChecklistItem, ExpiryItemFieldModel, IObjectMap } from '../interface';
import { IGoTogetherSettings } from './go-together';

type CloseRequestEmailToCoordinatorTo = "coordinator" | "sharedMailBox";

export enum RequestDurationTypes {
  onetime = 1,
  ongoing
}

export interface IInventorySettings {
  activated: boolean;
  categories: string[];
  defaultLoanDays?: number;
}

export interface IFindCompanySettings {
  activated: boolean;
  isMfaEnabled: boolean;
}

export enum ServiceCatalogActionTypes {
  none = 1,
  add_service_registration,
}

export enum RepeatRequestTypes {
  always = 1,
  optinal_default_true,
  optinal_default_false
}

export interface IServiceSettingsCatalog {
  activated: boolean;
  redirectActivated?: boolean
  customFormId?: string;
  emailNotification?: string;
  action: ServiceCatalogActionTypes;
  defaultRequestDurationType?: RequestDurationTypes;
  requestDurationTypeEditable?: boolean;
}

export interface ICopiablePropertyItem {
  status: boolean;
  key: string;
  labelId?: string;
}

export interface IRepeatRequestSettings {
  activated: boolean;
  repeatOption: RepeatRequestTypes;
  defaultStatus: RequestStatus;
  copiableProperties: ICopiablePropertyItem[];
}

export interface ICSRSettings {
  activated: boolean;
  introText: string;
  newRequestEmail: string;
  defaultTitle: string;
  redirectUrl: string;
  customFormId: string;
  embeddedCode: string;
  csrId: string;
}

export interface ISRSettings {
  activated: boolean;
  activeForExecutor: boolean;
}

export interface IServiceSettings {
  sendRequestEmailToExecutor?: boolean;
  sendRequestEmailToCoordinator?: boolean;
  sendCloseRequestEmailToCoordinator?: boolean;
  closeRequestEmailToCoordinator?: string;
  requestEmailToExecutor?: string;
  requestEmailToCoordinator?: string;
  sendEmailAfterSalesActivity?: boolean;
  salesEmailToCustomer?: string;
  defaultStatusCreated?: RequestStatus;
  defaultStatusUpdatedCoordinator?: RequestStatus;
  defaultStatusUpdatedExecutor?: RequestStatus;
  defaultStatusList?: RequestStatus[];
  inventory?: IInventorySettings;
  findCompany?: IFindCompanySettings;
  catalog: IServiceSettingsCatalog;
  quickNotes: QuickNote[];
  placeholders: IPlaceholders;
  repeatRequest?: IRepeatRequestSettings;
  csr?: ICSRSettings;
  isr?: ISRSettings;
  shareWithServices: string[]; // array of service ids
  requestsExpiry: ExpiryItemFieldModel;
  defaultChecklist: { items: ChecklistItem[]; activated: boolean; };
  closeRequestEmailToCoordinatorTo?: CloseRequestEmailToCoordinatorTo;
  audience: string[];
  showEvents: boolean;
  onlyPredefinedQuickNotes?: boolean;
  goTogether?: IGoTogetherSettings;
  subServices: string[];
}

export interface IInactiveUserContainer {
  inactiveUsers?: {
    [userId: string]: boolean;
  };
}
export interface IInactiveUserContainer {
  inactiveUsers?: {
    [userId: string]: boolean;
  };
}
export interface IPlaceholders {
  requestTitle: string;
  requestDescription: string;
  defaultRequestTitles: string[];
}

export interface Mailbox {
  sharedMailbox: string;
  sharedMailboxFooter?: string;
}

export class Service {
  id?: string;
  name: string;
  description: string;
  coordinators: IObjectMap<boolean>;
  excecutors: IObjectMap<boolean>;
  log: DocumentLog;
  active: boolean;
  customForms: string[];
  settings: IServiceSettings;
  workingAreaId: string;
  inactiveUsers?: IObjectMap<boolean>;
  usersCanSeeAllRequests: IObjectMap<boolean>;
  labels: string[];
  email: Mailbox;
  sharedMailboxFooter: string;

  // here we init default values
  constructor() {
    this.name = '';
    this.active = true;
    // this.log = JSON.parse(JSON.stringify(new DocumentLog()));
    this.coordinators = {};
    this.excecutors = {};
    this.usersCanSeeAllRequests = {};
    this.inactiveUsers = {};
    this.customForms = [];
    this.labels = [];
    this.email = {
      sharedMailbox: ""
    };
    this.settings = {
      closeRequestEmailToCoordinatorTo: "coordinator",
      sendRequestEmailToExecutor: false,
      sendRequestEmailToCoordinator: false,
      sendCloseRequestEmailToCoordinator: false,
      requestEmailToExecutor: `New service request`,
      closeRequestEmailToCoordinator: 'Request closed',
      requestEmailToCoordinator: `New service request`,
      defaultStatusCreated: RequestStatus.waitingList,
      defaultStatusUpdatedCoordinator: null,
      defaultStatusUpdatedExecutor: null,
      quickNotes: [],
      defaultChecklist: { items: [], activated: true },
      shareWithServices: [],
      requestsExpiry: null,
      inventory: {
        activated: false,
        categories: []
      },
      findCompany: {
        activated: false,
        isMfaEnabled: false,
      },
      catalog: {
        action: ServiceCatalogActionTypes.none,
        activated: false,
        defaultRequestDurationType: RequestDurationTypes.ongoing,
        requestDurationTypeEditable: true
      },
      placeholders: {
        requestDescription: '',
        requestTitle: '',
        defaultRequestTitles: []
      },
      repeatRequest: {
        activated: false,
        repeatOption: 2,
        defaultStatus: 1,
        copiableProperties: [
          { status: true, key: 'title', labelId: 'title' },
          { status: true, key: 'description', labelId: 'description' },
          { status: false, key: 'note', labelId: 'note' },
          { status: false, key: 'management.coordinatorId', labelId: 'coordinator' },
          { status: false, key: 'management.executorId', labelId: 'executor' },
          { status: false, key: 'date', labelId: 'date' },

          // always removed from copy. Don't specify labelId
          { status: false, key: 'management.executor' },
        ]
      },
      showEvents: false,
      audience: [],
      subServices: []
    };
  }
}
