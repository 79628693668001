import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User, Request, Service, WorkingArea, SocialRelationKindTypes } from '@shared/model';
import { LabelService } from '@core/services/labels.service';
import { UtilitiesService } from '@core/services/utilities.service';
import { UserService } from '@core/services/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-print-request',
  templateUrl: './print-request.component.html',
  styleUrls: ['./print-request.component.scss']
})
export class PrintRequestComponent implements OnInit, OnChanges {
  @Input() customer: User;
  @Input() request: Request;
  @Input() area: WorkingArea;
  @Input() service: Service;

  public labels = this.labelService.defaultProvider();
  public coordinator: User;
  public executor: User;
  public referredBy: User;
  public socialKind = SocialRelationKindTypes;

  constructor(
    private labelService: LabelService,
    private utilitiesService: UtilitiesService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.labelService.getLabels$('app-print-request').subscribe(lbs => this.labels = lbs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.utilitiesService.isNewChange(changes.request)) {
      // coordinator
      if (!this.coordinator || (this.coordinator.id !== this.request.management.coordinatorId && this.request.management.coordinatorId)) {
        this.userService.getUserById(this.request.management.coordinatorId).pipe(take(1)).subscribe(user => this.coordinator = user);
      }

      // executor
      if (!this.executor || (this.executor.id !== this.request.management.executorId && this.request.management.executorId)) {
        this.userService.getUserById(this.request.management.executorId).pipe(take(1)).subscribe(user => this.executor = user);
      }

      // referredBy
      if (!this.referredBy && this.request.log.createdBy) {
        this.userService.getUserById(this.request.log.createdBy).pipe(take(1)).subscribe(user => this.referredBy = user);
      }
    }
  }
}
