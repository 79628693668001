/* 3rd party libraries */
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
/* our own custom services  */
// import {
//     AuthService,
//     FirestoreService,
//     LabelService,
//     LocalStorageService,
//     OrganizationService,
//     OrgServiceService,
//     RouterService,
//     SearchService,
//     ToolbarSearchService,
//     UserPromptsService,
//     UserService,
//     WorkingAreaService,
//     RequestService,
//     ExportService,
//     ExportUsersService,
//     ExportFormsService,
//     CustomFormsService,
//     FileUploadService,
//     RequestNotificationsService,
//     SettingsService,
//     FeedbackService,
//     UtilitiesService,
//     LayoutService,
//     CloudFunctionService,
//     UsageService,
//     InstitutionService,
//     SearchIndexService,
//     PhotoUploadService,
//     FileService,
//     CustomerNetworkService,
//     GroupService,
//     GroupActivityService,
//     GroupActivitiesGeneratorService,
//     ServiceRegistrationService,
//     RollbarErrorHandler,
//     ExportInventoryService,
//     ActivityService,
//     ReservationService,
//     PrintService,
//     RoleService,
// } from './services';
// import { AuthGuard, NoAuthGuard, InstitutionsListGuard, ReservationsListGuard, GroupsListGuard } from './guards';
// import { CoordinatorService } from './services/coordinator.service';
// import { FindCompanyService } from './services/find-company.service';
// import { MailinglistService } from './services/mailinglist/mailinglist.service';
// import { EmailService } from './services/email.service';
// import { SeoService } from './services/seo.service';
// import { ApiClientService } from './services/api-client/api-client.service';
// import { ApiService } from './services/api.service';
// import { CustomerServiceRegistrationService } from './services/csr.service';

@NgModule({ exports: [],
    declarations: [], imports: [BrowserModule], providers: [
        // ApiClientService,
        // ApiService,
        // FirestoreService,
        // LabelService,
        // SeoService,
        // AuthService,
        // UserService,
        // SearchService,
        // RouterService,
        // CoordinatorService,
        // FindCompanyService,
        // LocalStorageService,
        // OrganizationService,
        // UserPromptsService,
        // ToolbarSearchService,
        // WorkingAreaService,
        // OrgServiceService,
        // RequestService,
        // ExportService,
        // RoleService,
        // ExportUsersService,
        // MailinglistService,
        // ExportFormsService,
        // CustomFormsService,
        // FileUploadService,
        // RequestNotificationsService,
        // SettingsService,
        // FeedbackService,
        // UtilitiesService,
        // LayoutService,
        // CloudFunctionService,
        // UsageService,
        // InstitutionService,
        // SearchIndexService,
        // PhotoUploadService,
        // FileService,
        // CustomerNetworkService,
        // GroupService,
        // GroupActivityService,
        // GroupActivitiesGeneratorService,
        // ServiceRegistrationService,
        // RollbarErrorHandler,
        // ExportInventoryService,
        // ActivityService,
        // ReservationService,
        // PrintService,
        // EmailService,
        // CustomerServiceRegistrationService,
        // // route guards
        // AuthGuard,
        // NoAuthGuard,
        // InstitutionsListGuard,
        // ReservationsListGuard,
        // GroupsListGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {
    /* make sure CoreModule is imported only by one NgModule the AppModule */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
