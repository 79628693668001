import { Injectable } from '@angular/core';
import { SpecialCharacters } from '@models/model/special-chars.model';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SearchIndexService {
  constructor() { }

  private getDynamicTokens(prop: string, index: number, props: string[], scan: any, generatedValues: any[]) {
    if (prop) {
      if (prop.indexOf('{') >= 0) {
        Object.keys(scan).forEach(key => {
          this.getDynamicTokens(props[index + 1], index + 1, props, scan[key], generatedValues);
        });
      } else {
        this.getDynamicTokens(props[index + 1], index + 1, props, scan[prop] || {}, generatedValues);
      }
    } else if (typeof scan === 'string') {
      generatedValues.push(scan);
    }
  }

  private getTokenValues(data: any, property: string): string[] {
    if (property.indexOf('{') >= 0) {
      const generatedValues = [];

      const props = property.split('.');
      this.getDynamicTokens(props[0], 0, props, Object.assign(data), generatedValues);
      return generatedValues;
    } else {
      const values: string = property.split('.').reduce((accumulator, currentProperty) => {
        return accumulator[currentProperty] || {};
      }, data) || '';

      if (typeof values === 'string') {
        return values.includes(' ') ? [values].concat(values.split(' ')) : [values];
      } else {
        return [];
      }
    }
  }

  public updateSearchIndex<T>(data: any): T {
    if (data.searchIndex) {
      const properties: string[] = data.searchIndex.properties;
      const index = {};

      properties.forEach((property) => {
        if (property.startsWith('$')) {
          const val = get(data, property.substring(1));
          if (typeof val === 'string' && val) {
            index[val.toLowerCase()] = true;
          }
        } else {
          this.getTokenValues(data, property).map(s => {
            return SpecialCharacters.fromSpecial(s);
          }).forEach((res: string) => {
            try {
              const tokens = res.toLowerCase().split('');
              let searchSpace = '';

              tokens.forEach((token) => {
                searchSpace += token;
                index[searchSpace] = true;
              });
            } catch (e) { }
          });
        }
      });

      data.searchIndex.index = index;
    }

    return data;
  }
}
