import { IObjectMap } from '@models/interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

// payload is outside of scope of the store to main data persistence
const payload: IObjectMap<BehaviorSubject<any>> = {};

export class AppStore {
  static getData<T>(key: string): Observable<IObjectMap<T>> {
    if (!payload[key]) {
      this.setData(key, undefined);
    }

    return payload[key].asObservable().pipe(filter(r => r !== undefined));
  }

  static getDataSync<T>(key: string): T {
    if (!payload[key]) {
      this.setData(key, undefined);
    }

    return payload[key].getValue();
  }

  static setData<T>(key: string, data: T): void {
    payload[key] = payload[key] || new BehaviorSubject(undefined);
    payload[key].next(data);
  }

  static remove(key: string): void {
    this.setData(key, undefined);
  }
}
