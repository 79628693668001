<app-modal>
    <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
        <div class="modal_title">
            {{labels.save_activity}}
        </div>
    </div>

    <div class="modal_body m-4" #modal_body>
        <form *ngIf="form" [formGroup]="form">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row">
                    <mat-radio-group aria-label="Select an option" formControlName="saveOption">                      
                        <mat-radio-button value="saveAndComplete">{{this.labels.save_and_complete}}</mat-radio-button>
                        <mat-radio-button value="justSave">{{this.labels.just_save}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                
                <mat-form-field appearance="outline">
                    <mat-label>{{labels.due_date}}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="modal_footer full-width" #modal_footer>
        <button type="submit" mat-raised-button color="primary" [disabled]="form?.invalid"
            (click)="save()">{{labels.save}}</button>
    </div>
</app-modal>