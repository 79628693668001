import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ModalComponent } from '@shared/components';
import {
  RequestService, ISearchRequestsQuery,
  ExportService, UserService, OrgServiceService, WorkingAreaService, SettingsService,
  LabelService,
  CustomFormsService,
  ExportFormsService,
  LocalStorageService,
  ISearchQuery
} from '@core/services';
import { Request, Service, WorkingArea, ICustomFormModel, Role, User } from 'app/shared/model';
import { uniq } from 'lodash';
import { take } from 'rxjs/operators';
import { Sub } from '@shared/subscriptions';

@Component({
  selector: 'app-export-requests-dialog',
  templateUrl: './export-requests.component.html',
  styleUrls: ['./export-requests.component.scss']
})
export class ExportRequestsComponent extends ModalComponent implements OnInit, OnDestroy {
  public searchQuery: ISearchRequestsQuery;
  public requests: Request[];
  public requestsCount: number;
  public executorsCount: number;
  public customersCount: number;
  public isGenerating = false;
  public service: Service;
  public workarea: WorkingArea;
  public coordinator: User;
  public executor: User;
  public labels: any = {};
  public selectedForm: ICustomFormModel;
  public customForms: ICustomFormModel[] = [];

  private sub: Sub = new Sub();
  private customerIds: string[] = [];

  constructor(
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ExportRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {searchQuery: ISearchQuery, requests: Request[]},
    public requestService: RequestService,
    public exportService: ExportService,
    public userService: UserService,
    public settingsService: SettingsService,
    public orgServiceService: OrgServiceService,
    public workingAreaService: WorkingAreaService,
    public labelService: LabelService,
    private customFormsService: CustomFormsService,
    private exportFormsService: ExportFormsService,
    private localStorageService: LocalStorageService
  ) {
    super(dialogRef, breakpointObserver);
  }

  async ngOnInit() {
    this.searchQuery = this.data.searchQuery;
    this.initRequests();
    if (!this.data.requests?.length) this.initFilter();
    this.fetchCustomForms();

    this.labels = (await this.labelService.getLabels('app-export-requests-dialog')).data;
  }

  private fetchCustomForms() {
    const userRole: Role = JSON.parse(this.localStorageService.getItemSync('user_role'));

    this.sub.add(
      this.customFormsService.getFormsForWorkingArea(userRole.workingAreaId).subscribe(forms => {
        this.customForms = forms.filter(f => !!f);
      })
    );
  }

  async initFilter() {
    if (this.searchQuery.serviceId) {
      this.service = await this.orgServiceService.getServiceById(this.searchQuery.serviceId).pipe(take(1)).toPromise();
    }
    if (this.searchQuery.workareaId) {
      this.workarea = await this.workingAreaService.getWorkingAreaById(this.searchQuery.workareaId).pipe(take(1)).toPromise();
    }
    if (this.searchQuery.coordinatorId) {
      this.coordinator = await this.userService.getUserById(this.searchQuery.coordinatorId).pipe(take(1)).toPromise();
    }
    if (this.searchQuery.executorId) {
      this.executor = await this.userService.getUserById(this.searchQuery.executorId).pipe(take(1)).toPromise();
    }
  }

  async initRequests() {
    this.searchQuery.limit = 9999;
    this.searchQuery.forExport = true;

    this.requests = this.data.requests?.length ? this.data.requests : await this.requestService.getRequestsNoCache(this.searchQuery);
    this.initCounters(this.requests);
  }

  initCounters(requests: Request[]) {
    this.requestsCount = this.requests.length;

    const executorsIds: string[] = requests.map((request: Request) => request.management.executorId);
    this.executorsCount = uniq(executorsIds).length;

    const customersIds: string[] = requests.map((request: Request) => request.management.customerId)
      .filter(id => !!id);
    this.customerIds = uniq(customersIds);
    this.customersCount = this.customerIds.length;
  }

  public async exportServiceRequests(spiegel?: boolean) {
    this.isGenerating = true;
    await this.exportService.makeServiceRequestExport(this.requests, spiegel);
    this.isGenerating = false;
  }

  public async exportCustomers() {
    this.isGenerating = true;
    await this.exportService.makeUsersFromRequestsExport(this.requests, 'customers');
    this.isGenerating = false;
  }

  public exportForms() {
    this.exportFormsService.exportFormForCustomers(this.selectedForm, this.customerIds);
  }

  public async exportVolunteer() {
    this.isGenerating = true;
    await this.exportService.makeUsersFromRequestsExport(this.requests, 'volunteer');
    this.isGenerating = false;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
