<app-modal>
    <div class="modal_body" #modal_body>
        <div fxLayout="row">
            <div fxLayout="column" [ngStyle]="{height: config.height, width: config.width, margin: '15px auto'}">
                <img [src]="config.url" [alt]="config.alt || 'View image larger'">
            </div>
        </div>
    </div>

    <div class="modal_footer" #modal_footer fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px">
        <button type="button" mat-raised-button (click)="cancel()">
            {{labels.cancel}}
        </button>
    
        <button type="button" mat-raised-button color="primary" (click)="edit()">
            {{labels.edit}}
        </button>
    </div>

</app-modal>