import { ChecklistItem, IObjectMap, RequestDurationTypes } from '..';
import { RequestStatus } from '../enum';
import { DocumentLog } from './documentLog';
import { GoTogetherDetails } from './go-together';
import { IArea } from './postcodeAddress';
import { UnitTypes } from './product';
import { SearchIndex } from './searchIndex';

export type RequestPublishScope = 'org' | 'workingArea' | 'service';

export enum RequestStoppedReason {
  inAccordance = 1,
  unilaterallyClient,
  unilaterallyProvider,
  externalCircumstances,
}

export class UserInfoItem {
  firstname: string;
  lastname: string;
  userId: string;
  phone?: string;
  picture?: string;
  remark?: string;

  constructor() {
    this.firstname = '';
    this.lastname = '';
    this.userId = '';
  }
}

export class InstitutionInfoItem {
  name: string;
  logo: string;
  id: string;

  constructor(institutionInfoItem?: InstitutionInfoItem) {
    this.name = institutionInfoItem?.name || '';
    this.logo = institutionInfoItem?.logo || '';
    this.id = institutionInfoItem?.id || '';
  }
}

export class Management {
  workareaId?: string;
  // workAreaId?: string;
  serviceId?: string;
  executorId?: string;
  coordinatorId?: string;
  customerId?: string;
  executor?: UserInfoItem;
  customer: UserInfoItem;
  team: IObjectMap<UserInfoItem>;

  constructor() {
    this.customer = new UserInfoItem();
    this.executor = new UserInfoItem();
    this.team = {};
  }
}

export enum RequestResult {
  completed = 1,
  stopped,
  redirected,
  cancelled
}

export interface RequestActiveTimeline {
  years: number[];
  months: string[];
}

export interface RequestReaction {
  id: string;
  firstName: string;
  lastName: string;
  userId: string;
  picture: string;
  remark: string;
  remarkByCoordinator: string;
  refused: boolean
}

interface PublishDetails {
  numberOfReactions: number;
  maxReactions: number;
  weekdays: string[];
  publishDate: Date;
}

export interface PerMonth {
  activityCount: number;
  month: string; // year + month e.g 2023-01
  spent: number;
}

export class RequestActivity {
  public activityCount: number;
  public perMonth: PerMonth[];
  public remainingBudget: number;
  public totalSpent: number;
  public unitKind: UnitTypes
  public lastActivityCreatedAt: Date;
  public signalValue: number;
  public budgetSignal: boolean;
  public firstScheduledActivity: Date
  public hasOverDueActivity: boolean;

  constructor(requestActivity?: RequestActivity) {
    this.activityCount = requestActivity?.activityCount || null;
    this.perMonth = requestActivity?.perMonth || [];
    this.remainingBudget = requestActivity?.remainingBudget || null;
    this.totalSpent = requestActivity?.totalSpent || null;
    this.unitKind = requestActivity?.unitKind || null;
    this.lastActivityCreatedAt = requestActivity?.lastActivityCreatedAt || null;
    this.signalValue = requestActivity?.signalValue || null;
    this.budgetSignal = requestActivity?.budgetSignal || false;
    this.firstScheduledActivity = requestActivity?.firstScheduledActivity || null;
    this.hasOverDueActivity = requestActivity?.hasOverDueActivity || false;
  }
}

export class Request {
  id?: string;
  management: Management;
  title: string;
  description: string;
  note: string;
  status: RequestStatus;
  log?: DocumentLog;
  searchIndex?: SearchIndex;
  area: IArea;
  isClosed: boolean;
  startDate?: string;
  endDate?: string;
  assignedBy?: string;
  referredBy?: string;
  referredByNote?: string;
  expiryDate?: Date;
  result?: RequestResult;
  redirectedTo?: string;
  followupSteps?: string;
  requestKind?: string;
  closeRemark?: string;
  budget: number;
  fileLink?: string;
  referenceNumber: string;
  requestStoppedReason: RequestStoppedReason;
  checklistItems: ChecklistItem[];
  activeIn: RequestActiveTimeline;
  published: boolean;
  publishScope: RequestPublishScope;
  productCode?: string;
  publishDetails?: PublishDetails;
  activity: RequestActivity;
  weekBudget?: number;
  appointmentId: string;
  sequenceId: string;
  goTogetherDetails: GoTogetherDetails;
  scheduledFor: Date
  referredByInstitution?: InstitutionInfoItem;
  referredByContactPerson?: UserInfoItem;
  requestDurationType: RequestDurationTypes;

  constructor() {
    this.management = new Management();
    this.fileLink = '';
    this.status = RequestStatus.waitingList;
    this.isClosed = false;
    this.checklistItems = [];
    this.requestDurationType = RequestDurationTypes.ongoing;
    this.searchIndex = {
      properties: [
        'title',
        'management.customer.firstname',
        'management.customer.lastname',
        '$referenceNumber',
        '$id'
      ],
      index: {}
    };
  }
}
