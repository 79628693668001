import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService } from '../../../core/services/labels.service';
import { UserService } from '../../../core/services/user.service';
import { keys, uniqBy } from 'lodash';
import { IDataTableConfig, ITableData } from 'app/shared/components/data-table/data-table.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { IObjectMap } from '@shared/interface';
import { User } from '@shared/model';
import { Subscription } from 'rxjs';
import { parse } from 'date-fns';

interface Inputdata {
    exclusionList: IObjectMap<boolean>;    // user ids mapping to boolean (to be excluded)
    preSelectedList: IObjectMap<boolean>;  // user ids mapping to boolean (to be disabled)
    buttonLabel: string;
}

@Component({
    selector: 'app-users-selector',
    templateUrl: './users-selector.component.html',
    styleUrls: ['./users-selector.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsersSelectorComponent implements OnInit, OnDestroy {
    public labels: any = {};
    public form: FormGroup;
    public selectedUsers: IObjectMap<User> = {}; // user ids mapping to profile
    public userListForView: User[] = [];
    public userSelectionTableConfig: IDataTableConfig;
    public isMasterToggled = false;
    public isEmpty = false;

    private pageLimit = 30;
    private usersCache: any[] = [];
    private allDocsLoaded: boolean;
    public fetchingData: boolean;
    private usersSub: Subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<UsersSelectorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Inputdata,
        public breakpointObserver: BreakpointObserver,
        private labelService: LabelService,
        private userService: UserService,
        private fb: FormBuilder
    ) { }

    async ngOnInit() {
        this.labels = (await this.labelService.getLabels('app-users-selector')).data;
        this.data.buttonLabel = this.data.buttonLabel || this.labels.proceed;
        this.data.preSelectedList = this.data.preSelectedList || {};
        this.data.exclusionList = this.data.exclusionList || {};

        this.setupForm();
        this.getUsers();
    }

    public clearSearch() {
        this.form.controls.search.setValue('');
    }

    public toggleSelectAll() {
        const status = this.isMasterToggled;
        if (!status) {
            this.selectedUsers = {};
        }

        this.setDataTableConfig(status);
    }

    private setupForm() {
        this.form = this.fb.group({
            search: ''
        });

        // listens to search input changes
        this.form.controls.search.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(search => {
                if (search.length > 1) { } else {
                    this.usersCache = [];
                    this.allDocsLoaded = false;
                }

                this.getUsers();
            });
    }

    public getSelectedNumberOfUsers(): number {
        return keys(this.selectedUsers).length;
    }

    private getFilteredList(): User[] {
        return this.usersCache.slice();
    }

    // we get users that belongs to any of the selected levels
    public getUsers(nextPage?: boolean) {
        this.fetchingData = true;

        // first applies from cache
        this.userListForView = this.getFilteredList();
        this.setDataTableConfig(this.isMasterToggled);

        if (nextPage && this.allDocsLoaded) {
            this.fetchingData = false;
            return;
        }

        const formModel = this.form.value;

        this.usersSub.unsubscribe();
        this.userService.getUsersAndRefsInOrganization(ref => {
            ref = ref.where('active', '==', true);

            if (formModel.search && formModel.search.length > 1) {
                ref = ref.where(`searchIndex.index.${formModel.search.toLowerCase()}`, '==', true);
            } else {
                ref = ref.orderBy('firstname', 'asc').limit(this.pageLimit);
            }

            const lastDoc = this.usersCache[this.usersCache.length - 1];
            if (
                nextPage && (!formModel.search || formModel.search.length <= 1)
                && lastDoc && lastDoc.__doc
            ) {
                ref = ref.startAfter(lastDoc.__doc);
            }

            return ref;
        }).subscribe(users => {
            if (nextPage && users.length < this.pageLimit) {
                this.allDocsLoaded = true;
            }

            if (nextPage) {
                this.usersCache = uniqBy(this.usersCache.concat(users), 'id')
                    .sort((a, b) => {
                        return a.firstname > b.firstname ? 1
                            : a.firstname < b.firstname ? -1 : 0;
                    });
            } else {
                this.usersCache = users;
            }

            this.userListForView = this.getFilteredList();
            this.setDataTableConfig(this.isMasterToggled);

            this.fetchingData = false;
        });
    }

    // here we aggregate the ids of the users in the selection
    public async proceedWithAction() {
        const users: User[] = keys(this.selectedUsers).map(userId => {
            return this.selectedUsers[userId];
        });

        // here we output the selection
        this.dialogRef.close(users);
    }

    private constructUserSubtitle(user: User): string {
        let result = `${user.address?.street || ''} ${user.address?.number || ''}`;

        if (user.birthday) {
            const birthday = parse(user.birthday);
            result += ` - ${birthday.toLocaleDateString(
                undefined,
                { day: 'numeric', month: 'short' }
            )}`;
        }

        return result;
    }

    private setDataTableConfig(status?: boolean) {
        const payload: ITableData[] = [];

        (this.userListForView || []).forEach(user => {
            let isPreselected = false;
            if (this.data.preSelectedList[user.id]) {
                isPreselected = true;
                this.data.preSelectedList[user.id] = false;
            }

            let isDisabled = false;
            if (this.data.exclusionList[user.id]) {
                isDisabled = true;
            }

            payload.push({
                userId: user.id,
                user: {
                    title: `${user.firstname} ${user.lastname}`,
                    subtitle: this.constructUserSubtitle(user)
                },
                picture: user.picture,

                _metadata: {
                    checked: isDisabled ? false : isPreselected || status || !!(this.selectedUsers[user.id]),
                    disabled: this.data.exclusionList[user.id],
                    originalData: user
                }
            });
        });

        this.userSelectionTableConfig = {
            data: payload,
            displayProperties: ['select', 'picture', 'user'],
            headers: {},
            rowOptions: [],
            allowSelection: true,
            displayHeaders: false,
            propertyWithImage: 'picture'
        };
    }

    public handleSelectProfile(data: ITableData) {
        const value: any = data._metadata.originalData;

        if (data._metadata.checked) {
            this.selectedUsers[value.id] = value;
        } else {
            delete this.selectedUsers[value.id];
        }
    }

    ngOnDestroy() {
        this.usersSub.unsubscribe();
    }
}
