<app-modal>
  <div class="modal_body" #modal_body>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button class="btn-modal-close" mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div style="overflow: hidden; padding: 15px; padding-left: 5px;">
      <div class="img-preview">
        <thumbnail height="100%" width="100%" [image]="image"></thumbnail>
      </div>
    </div>
  </div>
</app-modal>