import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PrintService {
    private printContainer: HTMLElement = document.getElementById('app_print_area_container');

    constructor() { }

    public printElement(elemId: string): boolean {
        const content = document.getElementById(elemId).innerHTML;

        this.printContainer.innerHTML = `<div class="print_area_body">${content}</div>`;
        window.print();

        return true;
    }
}
