import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService, ServiceRegistrationService, UserService, UtilitiesService } from '@core/services';
import { User } from '@shared/model';
import { uniq, find, orderBy } from 'lodash';
import { take } from 'rxjs/operators';
import { subDays, isBefore } from 'date-fns';
import { ServiceRegistration, ServiceRegHistory, ServiceRegStatusTypes } from '@models/model/serviceRegistration';

@Component({
    selector: 'app-service-registration-history-modal',
    templateUrl: './service-registration-history-modal.component.html',
    styleUrls: ['./service-registration-history-modal.component.css']
})
export class ServiceRegistrationHistoryModalComponent implements OnInit {
    public labels: any = {};
    public serviceRegistration: ServiceRegistration;
    public history: { data: ServiceRegHistory; user?: User; }[];
    public serviceRegStatusTypes = ServiceRegStatusTypes;

    constructor(
        private labelService: LabelService,
        private dialogRef: MatDialogRef<ServiceRegistrationHistoryModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { serviceRegistration: ServiceRegistration; },
        private serviceRegistrationService: ServiceRegistrationService,
        private userService: UserService,
        private utilitiesService: UtilitiesService
    ) { }

    async ngOnInit() {
        this.serviceRegistration = this.data.serviceRegistration;

        this.serviceRegistrationService.getServiceRegistrationHistory(this.serviceRegistration.id)
            .subscribe(hs => {
                // @ts-ignore
              this.history = orderBy(hs, ['log.createdAt'], ['desc']).map(h => {
                    return { data: h };
                });

                // we get associated users that took the history action
                const userIds: string[] = hs.map(h => h.log.createdBy);
                this.userService.getUsersFromIds(uniq(userIds)).pipe(take(1)).subscribe(users => {
                    this.history.forEach(h => {
                        h.user = find(users, { id: h.data.log.createdBy });
                    });
                });
            });

        this.labels = (await this.labelService.getLabels('app-service-registration-history-modal')).data;
    }

    public formatDate(date: Date): string {
        const monthAgo = subDays(new Date(), 30);
        if (isBefore(date, monthAgo)) {
            return this.utilitiesService.getLocaleDateWithYear(date);
        } else {
            return this.utilitiesService.timeAgo(date);
        }
    }

    public cancel() {
        this.dialogRef.close(false);
    }
}
