<div fxLayout="column">
    <div *ngFor="let group of customForm?.groups">
        <h4>{{group.groupLabel}}</h4>

        <div class="groups" fxLayout="row" fxLayoutAlign="space-between end">
            <div *ngFor="let field of mappedFields[group.groupName]" fxFlex="{{getFieldWidth(field)}}">
                <ng-container *ngIf="!field.hidden">
                    <div [ngSwitch]="field.type">
                        <div *ngSwitchCase="'multiple'" class="input-container">
                            <div class="print-placeholder">{{field.label}}</div>
                            <div class="print-value">{{field.value ? field.value.join(', ') : ''}}</div>
                        </div>

                        <div *ngSwitchCase="'checkbox'" class="input-container">
                            <div class="print-placeholder">{{field.label}}</div>
                            <div class="print-value">{{field.checked ? labels.checkbox_true : labels.checkbox_false}}
                            </div>
                        </div>

                        <div *ngSwitchDefault class="input-container">
                            <div class="print-placeholder">{{field.label}}</div>
                            <div class="print-value carriage_returns">{{field.value}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>