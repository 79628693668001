import { Component, OnInit, Inject } from '@angular/core';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LabelService } from '@core/services/labels.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User, SocialRelation, SocialRelationKindTypes } from '@shared/model';
import { UtilitiesService, UserService, UserPromptsService, FirestoreService } from '@core/services';
import { take } from 'rxjs/operators';
import { parse } from 'date-fns';

@Component({
    selector: 'app-social-relations-modal',
    templateUrl: './social-relations-modal.component.html',
    styleUrls: ['./social-relations-modal.component.scss']
})
export class SocialRelationsModalComponent extends ModalComponent implements OnInit {
    public labels: any = {};
    public form: FormGroup;
    public relation: SocialRelation;
    public socialRelationKindTypes = SocialRelationKindTypes;
    public selectedRelation: User;
    public usersOptionsRef = '';
    public noteMaxLength = 100;
    public createdByUser: User;
    public customer: User;
    public socialRelation_lists: { id: number; name: string; }[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public breakpointObserver: BreakpointObserver,
        public dialogRef: MatDialogRef<SocialRelationsModalComponent>,
        public utilitiesService: UtilitiesService,
        private userPromptsService: UserPromptsService,
        private labelService: LabelService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private firestoreService: FirestoreService
    ) {
        super(dialogRef, breakpointObserver);
    }

    async ngOnInit() {
        this.relation = this.data.relation;
        this.customer = this.data.customer;
        this.usersOptionsRef = `/organizations/${localStorage.getItem('user_organization')}/users`;
        this.createForm();

        if (this.relation.log) {
            this.userService.getUserById(this.relation.log.createdBy).pipe(take(1)).subscribe(user => {
                this.createdByUser = user;
            });
        }

        this.labels = (await this.labelService.getLabels('app-social-relations')).data;
        this.socialRelation_lists = await this.labelService.getSocialRelations();
    }

    private createForm() {
        this.form = this.formBuilder.group({
            socialRelationKind: [this.relation.socialRelationKind],
            person: [this.relation.person, Validators.required],
            note: [this.relation.note, Validators.maxLength(this.noteMaxLength)]
        });

        if (this.relation.person) {
            this.userService.getUserById(this.relation.person).pipe(take(1)).subscribe(user => {
                this.addRelation(user);
            });
        }
    }

    public getUserSubtitleConstruct() {
        return this.constructUserSubtitle.bind(this);
    }

    public constructUserSubtitle(user: User): string {
        let result = `${user.address.street} ${user.address.number}`;

        if (user.birthday) {
            const birthday = parse(user.birthday);
            result += ` - ${birthday.toLocaleDateString(
                undefined,
                { day: 'numeric', month: 'short' }
            )}`;
        }

        return result;
    }

    public deleteItem() {
        this.userPromptsService.showConfirmDialogue(
            this.labels.confirm_action,
            this.labels.sure_to_delete_social_network_body,
            async (sure) => {
                if (sure) {
                    // removes complement social relation from user
                    delete this.selectedRelation.socialRelations[this.customer.id];
                    await this.userService.updateUser(this.selectedRelation);

                    delete this.customer.socialRelations[this.relation.person];
                    await this.userService.updateUser(this.customer);
                    this.userPromptsService.showToast(this.labels.social_network_deleted);
                    this.dialogRef.close();
                }
            }
        );
    }

    public getSelectedRelationName(): string {
        return this.selectedRelation ? `${this.selectedRelation.firstname} ${this.selectedRelation.lastname}` : '';
    }

    public addRelation(user: User) {
        this.form.controls.person.setValue(user ? user.id : '');
        this.selectedRelation = user;
    }

    private updateComplementaryRelation(relation: SocialRelation): Promise<any> {
        this.selectedRelation.socialRelations = this.selectedRelation.socialRelations || {};
        let complementRelation: SocialRelation;

        if (this.selectedRelation.socialRelations[this.customer.id]) {
            complementRelation = this.selectedRelation.socialRelations[this.customer.id];
            complementRelation.log.updatedAt = this.firestoreService.getServerTime();
        } else {
            complementRelation = new SocialRelation(relation.person);
            complementRelation.note = relation.note;
            complementRelation.person = relation.customerId;
            complementRelation = JSON.parse(JSON.stringify(complementRelation));
            complementRelation.log = this.firestoreService.createLog();
        }

        switch (relation.socialRelationKind) {
            case SocialRelationKindTypes.brother:
            case SocialRelationKindTypes.sister: {
                complementRelation.socialRelationKind = this.customer.gender === 'female'
                    ? SocialRelationKindTypes.sister : SocialRelationKindTypes.brother;
                break;
            }

            case SocialRelationKindTypes.father:
            case SocialRelationKindTypes.mother: {
                complementRelation.socialRelationKind = this.customer.gender === 'female'
                    ? SocialRelationKindTypes.daughter : SocialRelationKindTypes.son;
                break;
            }

            case SocialRelationKindTypes.son:
            case SocialRelationKindTypes.daughter: {
                complementRelation.socialRelationKind = this.customer.gender === 'female'
                    ? SocialRelationKindTypes.mother : SocialRelationKindTypes.father;
                break;
            }

            case SocialRelationKindTypes.friend: {
                complementRelation.socialRelationKind = SocialRelationKindTypes.friend;
                break;
            }

            default: {
                complementRelation.socialRelationKind = this.socialRelationKindTypes.family;
            }
        }

        this.selectedRelation.socialRelations[this.customer.id] = complementRelation;
        return this.userService.updateUser(this.selectedRelation);
    }

    public async save() {
        if (this.selectedRelation) {
            const model = this.form.value;
            const relation: SocialRelation = Object.assign({}, this.relation, {
                socialRelationKind: model.socialRelationKind,
                person: this.selectedRelation.id,
                note: model.note
            });

            this.customer.socialRelations[relation.person] = relation;
            if (relation.log && relation.log.createdBy) {
                this.customer.socialRelations[relation.person].log.updatedAt = this.firestoreService.getServerTime();
                await this.userService.updateUser(this.customer);
                this.updateComplementaryRelation(this.customer.socialRelations[relation.person]);

                this.userPromptsService.showToast(this.labels.social_network_updated);
            } else {
                this.customer.socialRelations[relation.person].log = this.firestoreService.createLog();
                await this.userService.updateUser(this.customer);
                this.updateComplementaryRelation(this.customer.socialRelations[relation.person]);

                this.userPromptsService.showToast(this.labels.social_network_created);
            }
            this.dialogRef.close();
        }
    }
}
