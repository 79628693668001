import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { find } from 'lodash';
import { LocalStorageService } from '@core/services';
import { Role } from '@shared/model';
import { RoleTypes } from '@shared/enum';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(private localStorageService: LocalStorageService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise(async resolve => {
      let allow = false;
      if (route.data && route.data.allowToRoles && route.data.allowToRoles.length) {
        const userRole: Role = JSON.parse(this.localStorageService.getItemSync('user_role'));
        if (userRole) {
          const hasRole = !!find(
            route.data.allowToRoles as RoleTypes[],
            i => i === userRole.roleType
          );
          if (hasRole) {
            allow = true;
          }
        }
      }
      if (!allow) {
        this.router.navigateByUrl('');
      }
      resolve(allow);
    });
  }
}
