<app-modal>
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{serviceRegistration.title}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="padding: 10px 0;">
        <div *ngFor="let item of history">
            <div class="history-container">
                <div class="history-header">
                    {{labels['action_'+serviceRegStatusTypes[item.data.action]]}} 
                    {{labels.by}} 
                    {{item.user?.fullname}}
                </div>
                <div class="history-subheader">
                    {{formatDate(item.data.log.createdAt)}}
                </div>

                <div class="history-body carriage_returns">
                    {{item.data.remark}}
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
            <button mat-stroked-button (click)="cancel()">{{labels.close}}</button>
        </div>
    </div>
</app-modal>