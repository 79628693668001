<div id="main_print_container" *ngIf="customer && request">
    <div id="print_container">
        <div fxLayout="column" fxLayoutGap="30px" style="background-color: #ffffff;">
            <div fxLayout="row" fxLayoutGap="10px" class="p-n">
                <div fxFlex="stretch" fxLayout="column" fxLayoutGap="10px">
                    <div>{{customer.fullname}}</div>
                    <div>{{request.title}}</div>
                </div>

                <div fxFlex="stretch" fxLayout="column" fxLayoutGap="7px" style="font-size: 0.8em;">
                    <div class="no_break">{{area?.name}}</div>
                    <div class="no_break">{{service?.name}}</div>
                </div>
            </div>

            <div class="printable_section" fxLayout="column" fxLayoutGap="20px">
                <div class="header">{{labels.contact}}</div>

                <div class="body" fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="row" fxLayoutGap="10px">
                        <div fxLayout="column" fxLayoutGap="7px" fxFlex="stretch">
                            <div>{{customer.address?.street || ''}} {{customer.address?.number || ''}} <span
                                    *ngIf="customer.address?.letter"> - {{customer.address?.letter}}</span></div>
                            <div>{{customer.address?.postalcode || ''}} {{customer.address?.city || ''}}</div>

                            <div *ngIf="customer.area?.neighbourhood?.text" style="padding-top: 10px;">
                                {{customer.area?.neighbourhood.text}}</div>
                            <div style="margin-top: 15px;"
                                *ngIf="customer.userPartner && customer.userPartner.partnerRegistration">
                                {{customer.userPartner.firstname}} {{customer.userPartner.lastname}}
                                ({{labels.partner}})</div>
                            <div
                                *ngIf="customer.userContactPerson && customer.userContactPerson.firstName">
                                {{customer.userContactPerson.firstName}} {{customer.userContactPerson.lastName}}
                                {{customer.userContactPerson.phone}}
                                ({{labels[socialKind[customer.userContactPerson.socialRelationKind]]}})</div>
                            <div
                                *ngIf="customer.userContactPerson2 && customer.userContactPerson.firstName">
                                {{customer.userContactPerson2.firstName}} {{customer.userContactPerson2.lastName}}
                                {{customer.userContactPerson2.phone}}
                                ({{labels[socialKind[customer.userContactPerson2.socialRelationKind]]}})</div>
                        </div>

                        <div fxLayout="column" fxLayoutGap="7px" fxFlex="stretch">
                            <div>{{customer.phone}} {{customer.phoneRemark}}</div>
                            <div>{{customer.phone2}} {{customer.phone2Remark}}</div>
                            <div style="padding-top: 15px;">{{customer.email}}</div>
                            <div style="padding-top: 5px;">{{customer.emailAuthorizedPerson}}</div>
                        </div>
                    </div>

                    <div style="font-size: 0.8em;">{{customer.usernote}}</div>
                </div>
            </div>

            <div class="printable_section" fxLayout="column" fxLayoutGap="20px">
                <div class="header">{{labels.details}}</div>

                <div class="body" fxLayout="column" fxLayoutGap="25px">
                    <div class="item" fxLayout="column" fxLayoutGap="7px">
                        <div class="title">{{labels.description}}</div>
                        <div class="carriage_returns">{{request.description}}</div>
                    </div>

                    <div class="item" fxLayout="column" fxLayoutGap="7px">
                        <div class="title">{{labels.notes}}</div>
                        <div class="carriage_returns">{{request.note}}</div>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px">
                        <div class="item" fxLayout="column" fxLayoutGap="7px" fxFlex="stretch">
                            <div class="title">{{labels.coordinator}}</div>
                            <div>{{coordinator?.firstname}} {{coordinator?.lastname}}</div>
                        </div>

                        <div class="item" fxLayout="column" fxLayoutGap="7px" fxFlex="stretch">
                            <div class="title">{{labels.executor}}</div>
                            <div>{{executor?.firstname}} {{executor?.lastname}}</div>
                        </div>

                        <div class="item" fxLayout="column" fxLayoutGap="7px" fxFlex="stretch">
                            <div class="title">{{labels.referred_by}}</div>
                            <div>{{referredBy?.firstname}} {{referredBy?.lastname}}</div>
                            <div>{{request?.referredByNote}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>