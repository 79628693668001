import { Pipe, PipeTransform } from '@angular/core';
import { Service } from '@shared/model';
import { filter } from 'lodash';

@Pipe({
    name: 'serviceFilter'
})
export class ServicePipe implements PipeTransform {
    public constructor() { }

    transform(services: Service[], workingAreaId?: string): Service[] {       
        return workingAreaId && services ? filter(services, { workingAreaId }) : [];
    }
}
