import { DocumentLog } from './documentLog';
import { GroupMember, Group } from './group';
import { IObjectMap } from '../interface';

export enum GroupActivityTemplateFrequencyTypes {
    until_date = 1,
    number_of_times,
    repeat_forever
}

export enum DurationTypes {
    minutes = 1,
    hours,
    days
}

export enum GroupActivityTemplateFrequency {
    once = 1,
    every_week,
    every_two_weeks
}

export enum GroupActivityAttendanceTypes {
    present = 1,
    absent,
    open,
    cancelled
}

export interface GroupActivityMember extends GroupMember {
    attendance: GroupActivityAttendanceTypes;
    cancelReason?: string;
    lastUpdated?: Date;
}

export class GroupActivity {
    id?: string;
    name: string;
    description: string;
    location: string;
    startDate: Date;
    endDate: Date;
    executors: IObjectMap<GroupActivityMember>;
    participants: IObjectMap<GroupActivityMember>;
    groupId: string;
    workingAreaId: string;
    serviceId: string;
    tags: string[];
    log?: DocumentLog;
    week: number;
    weekDay: number;
    month: number;
    year: number;
    dayOfYear: number;
    completed: boolean;

    constructor(group: Group) {
        this.executors = {};
        this.participants = {};
        this.groupId = group.id;
        this.workingAreaId = group.workingAreaId;
        this.serviceId = group.serviceId;
        this.tags = [];
        this.completed = false;
    }
}

export interface GeneratedGroupActivityModel {
    groupActivity: GroupActivity;
    date: Date;
    skipped?: boolean;
}

export class GroupActivityTemplate {
    name: string;
    id: string;             // the hash tag name of this template same as its document key/id
    location: string;
    executors: string[];     // array of user ids
    description: string;
    startDate: Date;
    startTime: string;
    duration: number;
    durationInterval: DurationTypes;
    workingAreaId: string;
    serviceId: string;
    tags: string[];
    log?: DocumentLog;

    // these values only show up depending on weekFrequency prop
    weekFrequency: GroupActivityTemplateFrequency;
    repeatEnd: GroupActivityTemplateFrequencyTypes;
    repeatValueNumber: number;  // number of activities
    repeatValueDate: Date;       // also used as endDate
    weekDays: number[];         // the days of the week for the activity
}

