import { Component, OnInit, OnDestroy } from '@angular/core';
import { LabelService, RouterService, UserPromptsService, WorkingAreaService } from '@core/services';
import { MenuItem, WorkingArea } from '@shared/model';
import { Router, ActivatedRoute } from '@angular/router';
import { chunk, flatten, orderBy } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { NgSub } from 'ng-sub';

@Component({
    selector: 'app-workingareas-catalog',
    templateUrl: './workingareas-catalog.component.html',
    styleUrls: ['./workingareas-catalog.component.scss']
})
export class WorkingareasCatalogComponent implements OnInit, OnDestroy {
    public labels: any = {};
    public workingAreas: WorkingArea[][] = [];

    private sub = new NgSub();
    private gridSize = 3;

    constructor(
        private labelService: LabelService,
        private routerService: RouterService,
        private workingAreaService: WorkingAreaService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userPromptsService: UserPromptsService,
    ) { }

    async ngOnInit() {
        this.userPromptsService.activeMediaQuery$.pipe(takeUntil(this.sub)).subscribe(m => {
            if (m === 'xs') {
                this.gridSize = 1;
            } else if (m === 'sm') {
                this.gridSize = 2;
            } else {
                this.gridSize = 3;
            }

            this.workingAreas = chunk(flatten(this.workingAreas), this.gridSize);
        });

        this.sub.add(
            this.workingAreaService.getWorkingAreasForCatalog().subscribe(w => {
                this.workingAreas = chunk(orderBy(w, ['name'], ['asc']), this.gridSize);
            })
        );

        this.labels = (await this.labelService.getLabels('app-workingareas-catalog')).data;

        const menuItem: MenuItem = Object.assign({}, new MenuItem(), {
            title: this.labels.workingareas_catalog
        });
        this.routerService.setMainMenuItem(menuItem);
    }

    public gotoServicesPage(workingArea: WorkingArea): void {
        this.router.navigate([workingArea.id, 'services'], { relativeTo: this.activatedRoute });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
