<app-modal>
  <div class="modal_header" #modal_header fxLayout="row" fxLayoutAlign="start center">
    <span class="modal_title">
      <ng-container *ngIf="isAddMode()">{{labels.add_activity_title}}</ng-container>
      <ng-container *ngIf="isUpdateMode()">{{labels.update_activity_title}}</ng-container>
    </span>
  </div>

  <div class="modal_body" #modal_body>
    <ng-container *ngIf="deny">
      <mat-error>{{labels.deny}}</mat-error>
    </ng-container>

    <ng-container *ngIf="!deny && form">
      <form fxLayout="column" class="search-form" class="info" [formGroup]="form">
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="stretch">
            <mat-label>{{labels.workarea}}</mat-label>
            <input matInput type="text" [readonly]="disableWorkArea" formControlName="workarea" />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="stretch" *ngIf="data.services"
            [ngClass]="{'disabled_element': disableServiceInput}">
            <mat-label>{{labels.select_service}}</mat-label>
            <mat-select id="serviceInput" formControlName="serviceId">
              <mat-option *ngFor="let s of data.services" [value]="s.id">
                {{ s.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="1 1 100%" fxLayout="row" fxLayoutGap="10px">
          <div fxFlex="1 1 49%">
            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.activity_kind}}</mat-label>
              <mat-select id="kindInput" formControlName="kind">
                <mat-option *ngFor="let kind of sortKindsByLabels(activityKinds)" [value]="kind">
                  {{ labels[ActivityKindTypes[kind]] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="1 1 49%" fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.date}}</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="date">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="stretch">
              <mat-label>{{labels.plan_date}}</mat-label>
              <input matInput [matDatepicker]="picker2" formControlName="planDate">
              <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="stretch" color="primary">
            <mat-label>{{labels.title_activity}}</mat-label>
            <input matInput type="text" formControlName="title" />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="80px">
            <mat-label>{{labels.minutes}}</mat-label>
            <input matInput type="number" formControlName="minutes" />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="80px" *ngIf="canShowAmount()">
            <mat-label>{{labels.amount}}</mat-label>
            <input matInput type="number" formControlName="amount" />
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="full-width" color="primary">
          <mat-label>{{labels.description}}</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows minRows="3" type="text"
            formControlName="description">
          </textarea>
        </mat-form-field>

        <div class="section_card section full-width border-primary">
          <div class="section_title text-primary">
            {{labels.attachments}}
          </div>

          <div class="content">
            <mat-chip-listbox>
              <mat-chip-option (click)="selectFile()" class="chip_style" selected="true" [color]="'primary'">
                <mat-icon>add</mat-icon>
              </mat-chip-option>

              <mat-chip-option [matTooltip]="attach.item.filename" class="chip_style"
                *ngFor="let attach of attachmentsForView" selected="true" [color]="'default'">
                <mat-icon color="primary">insert_drive_file</mat-icon>
                <span *ngIf="attach.item.url" (click)="openAttachment(attach.item)" class="chip_text">
                  {{ attach.item.filename.substr(0, 7) }} {{ attach.item.filename.length > 7 ? '...' : '' }}
                </span>
                <span *ngIf="!attach.item.url">
                  {{labels._translate('upload_progress', {percent: attach.progress})}}
                </span>
                <mat-icon class="close" *ngIf="attach.item.url" [matMenuTriggerFor]="menu">more_vert</mat-icon>

                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="showFileInfo(attach.item)">
                    <mat-icon>info</mat-icon>
                    <span>{{ labels.info }}</span>
                  </button>
                  <button mat-menu-item (click)="deleteFile(attach.item)">
                    <mat-icon>delete</mat-icon>
                    <span>{{ labels.delete }}</span>
                  </button>
                </mat-menu>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </div>
      </form>
    </ng-container>
  </div>

  <div class="modal_footer full-width" #modal_footer>
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
      <div>
        <button type="button" mat-icon-button
          [color]="!activity?.visibleFor?.servicePoint || !activity?.visibleFor?.executor ? 'primary' : ''"
          [style.color]="activity?.visibleFor?.servicePoint || activity?.visibleFor?.executor ? 'grey' : ''"
          (click)="openVisibleForDialog()"><span class="material-symbols-outlined">
            visibility_lock
          </span></button>
      </div>
      <div>
        <div>
          <button [disabled]="invoicing" *ngIf="isSalesKind() && isEdit" type="button" mat-stroked-button
            (click)="addInvoice()">{{labels.add_invoice}}</button>
        </div>
        <div fxFlex="stretch"></div>
        <button type="button" mat-button (click)="closeModal()">{{labels.close}}</button>
        <div>
          <button *ngIf="!deny" type="submit" mat-raised-button color="primary"
            [disabled]="form?.invalid || isFileUploading()" (click)="onSave()">{{labels.save}}</button>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<input #fileSelectorElem type="file" (change)="onFileSelected($event)" [hidden]="true" />