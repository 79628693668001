import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { UserPromptsService } from './user-prompt.service';
import { Organization } from '@shared/model';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationListDialog } from '@shared/entry-components/organization-list/organization-list.component';
import { CloudFunctionService } from './functions.service';
import { IObjectMap } from '@models/interface';
import { ApiResponse, ApiService } from './api.service';
import { ExportTypes } from '@models/model/export';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  public activeMediaQuery = '';
  public themePreviewSubject$: Subject<string> = new Subject();

  public constructor(
    public dialog: MatDialog,
    private afsDB: FirestoreService,
    private localStorageService: LocalStorageService,
    private userPromptsService: UserPromptsService,
    private fnService: CloudFunctionService,
    private apiService: ApiService,
  ) { }

  public getOrganizations(): Observable<Organization[]> {
    return this.afsDB.colWithIds$('/organizations');
  }

  public getOrganizationById(orgId: string): Observable<Organization> {
    return this.afsDB.docWithId$(`/organizations/${orgId}`);
  }

  public getCurrentOrganization(): Observable<Organization> {
    return this.getOrganizationById(
      this.localStorageService.getItemSync('user_organization')
    );
  }

  public get currentOrgId(): string {
    return this.localStorageService.getItemSync('user_organization');
  }

  public async getOrganizationName(isAuth: boolean): Promise<string> {
    return new Promise<string>(async resolve => {
      if (isAuth) {
        return resolve(
          this.localStorageService.getItemSync('user_organization')
        );
      }

      if (this.isAuthRoute()) {
        // we try to get organizationName from the route
        const org: Organization = await this.getOrganizationById(
          this.getOrgNameFromRoute()
        )
          .pipe(take(1))
          .toPromise();

        if (org) {
          return resolve(org.id);
        }
      }

      resolve('');
    });
  }

  public selectOrganization(organizations: Organization[]): Promise<Organization> {
    return new Promise(resolve => {
      if (organizations.length === 1) {
        resolve(organizations[0]);
      } else {
        this.userPromptsService.showDialogue(OrganizationListDialog, { organizations }, org => {
          resolve(org);
        });
      }
    })
  }

  public updateOrganization(org: Organization): Promise<any> {
    const newOrg = JSON.parse(JSON.stringify(org));

    return this.afsDB.update(`organizations/${org.id}`, newOrg);
  }

  private isAuthRoute() {
    const path = location.pathname.split('/').filter(segment => segment);
    return path.length === 2 && path[path.length - 1] === 'auth';
  }

  private getOrgNameFromRoute() {
    const path = location.pathname.split('/').filter(segment => segment);
    return path[0];
  }

  public getUserOrgsByEmail(email: string): Promise<{ orgs: Organization[] }> {
    return this.fnService.getUserOrgsByEmail(email);
  }

  public submitNewOrganization(org: IObjectMap<any>): Promise<ApiResponse<string>> {
    return this.apiService.post<string>(`orgs/new/submit`, org)
      .pipe(take(1)).toPromise();
  }

  public exportOrgData(payload: { orgId: string, exportType: ExportTypes, collections: string[] }) {
    return this.apiService.exportDatabasePost(`orgs/export`, payload);
  }

  public syncExpiringUsers(orgId: string) {
    return this.apiService.get(`orgs/${orgId}/sync-expire-users`).pipe(take(1)).toPromise();
  }
}
