<app-modal>
  <div class="modal_header" #modal_header>
    <span class="modal_title">
      {{labels.checklist_items}}
    </span>
  </div>

  <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
    <div fxLayout="column" fxLayoutGap="20px">
      <div>
        <div class="check_item" *ngFor="let item of items; let i = index" fxLayout="row" fxLayoutAlign="start center"
          fxLayoutGap="10px">
          <mat-checkbox color="primary" [(ngModel)]="item.checked"></mat-checkbox>
          <span fxFlex="stretch">{{item.title}}</span>
          <div class="delete_container">
            <button mat-icon-button (click)="removeItem(i)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div>
        <mat-form-field class="full-width">
          <mat-label>{{labels.new_item}}</mat-label>
          <input matInput type="text" [placeholder]="labels.new_item" [(ngModel)]="newItemText" />
          <mat-icon matSuffix style="cursor: pointer" (click)="saveNewItem()">add</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="modal_footer" #modal_footer>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <button color="primary" mat-raised-button (click)="save()">{{labels.save}}</button>
    </div>
  </div>
</app-modal>