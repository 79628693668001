import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelService, LocalStorageService, OrganizationService, OrgServiceService, RequestService, SearchService, UsageService, UserPromptsService, UserService, UtilitiesService } from '@core/services';
import { RoleTypes } from '@models/enum';
import { Address, Organization, Service, UsageActivity } from '@models/model';
import { User } from '@models/model/user';
import { IDataTableConfig, ITableData } from '@shared/components';
import { NewUserComponent } from '@users/components/new-user/new-user.component';
import { cloneDeep, flatten, orderBy, uniq, uniqBy } from 'lodash';
import { NgSub } from 'ng-sub';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { UsersSelectorComponent } from '../users-selector/users-selector.component';

@Component({
  selector: 'app-search-person',
  templateUrl: './search-person.component.html',
  styleUrls: ['./search-person.component.scss']
})
export class SearchPersonComponent implements OnInit, OnDestroy {
  public labels = this.labelService.defaultProvider();
  public loading = false;
  public form: FormGroup;
  public userSelectionTableConfig: IDataTableConfig;
  public lastRef: any;

  public usersListSubscription: Subscription;
  private users: any[] = [];
  private usersCache: User[] = [];
  private isExecutor = false;
  private usageDocs: UsageActivity[];
  private org: Organization;
  private sub = new NgSub();
  private services: Service[];
  private lastSeenProp = 'last_seen_users_toggle'; // value: 'yes' || 'no'

  constructor(
    private labelService: LabelService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private usageService: UsageService,
    private utilitiesService: UtilitiesService,
    private searchService: SearchService,
    private orgServiceService: OrgServiceService,
    private requestService: RequestService,
    private usersService: UserService,
    private userPromptsService: UserPromptsService,
    private orgService: OrganizationService,
    public dialogRef: MatDialogRef<UsersSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
  ) { }

  async ngOnInit() {
    this.dialogRef.disableClose = true;
    this.isExecutor = this.utilitiesService.rolesMatch(RoleTypes.excecutor);

    const lastSeen = this.localStorageService.getItemSync(this.lastSeenProp);
    const ls = lastSeen ? lastSeen === 'yes' : true;

    this.form = this.fb.group({
      search: [''],
      lastSeen: [ls],
    });

    this.form.valueChanges.pipe(
      debounceTime(700),
      distinctUntilChanged(),
      takeUntil(this.sub),
    ).subscribe(model => {
      this.usersCache = [];
      this.fetchUsers(model.search);
    });

    this.usageService.getReportingForUserWithDateRange(
      this.localStorageService.getItemSync('user_id'), 30, 35
    ).pipe(take(1)).subscribe(docs => {
      this.usageDocs = docs.filter(doc => doc.actionTo.userId);
      this.fetchUsers();
    }),

      this.orgService.getCurrentOrganization().pipe(takeUntil(this.sub)).subscribe({
        next: org => {
          this.org = org;
          this.updateTableConfig();
        },
        error: e => console.log(e),
      });

    this.labels = (await this.labelService.getLabels('app-search-person')).data;
  }

  private async getUsersForExecutor(searchWord?: string) {
    if (searchWord) {
      this.usersCache = this.searchService.searchListBySearchIndex(this.users, searchWord);
      this.updateTableConfig();
    } else {
      if (this.usersListSubscription) {
        this.usersListSubscription.unsubscribe();
        this.usersListSubscription = null;
      }

      const executorId = this.localStorageService.getItemSync('user_id');
      if (!this.services) {
        this.services = await this.orgServiceService.getServicesForExecutor(executorId);
      }

      this.requestService.getAllRequestsForExecutor(
        executorId, this.services,
      ).pipe(take(1)).subscribe(requests => {
        const userIds: string[] = flatten(requests.map(req => {
          return [req.management.coordinatorId, req.management.customerId];
        })).filter(id => !!id);

        this.usersListSubscription = this.usersService.getUsersFromIds(uniq(userIds))
          .subscribe(users => {
            this.usersCache = users;
            this.users = this.updateUsers(cloneDeep(this.usersCache));
            this.updateTableConfig();
            this.loading = false;
          });
      });
    }
  }

  public async fetchUsers(searchWord?: string) {
    if (this.usersCache.length === 0) {
      this.lastRef = null;
    }

    if (this.isExecutor) {
      return this.getUsersForExecutor(searchWord);
    }

    this.loading = true;
    if (searchWord && searchWord.length < 2) {
      searchWord = undefined;
    }

    if (this.usersListSubscription) {
      this.usersListSubscription.unsubscribe();
      this.usersListSubscription = null;
    }

    if (searchWord) {
      this.usersListSubscription = this.usersService.getUsers(searchWord, 50)
        .subscribe((res) => {
          this.loading = false;
          this.users = this.updateUsers(
          
            this.searchService.orderItemsBySearchRelevance(res, searchWord)
          );
          this.users = orderBy(uniqBy(this.users, 'id'), ['countMatch', 'id'], ['desc', 'asc']);

          this.updateTableConfig();
        });
    } else {
      if (this.utilitiesService.rolesMatch(RoleTypes.orgAdmin) || !this.form.get('lastSeen').value) {
        const limit = 30;

        this.usersService.getUsersForOrgAdmin(limit, this.lastRef).subscribe((res) => {
          this.loading = false;
          this.usersCache = uniqBy(this.usersCache.concat(res), 'id');
          this.users = this.updateUsers(cloneDeep(this.usersCache));

          this.lastRef = res.length === limit ? res[res.length - 1]['__doc'] : null;
          this.updateTableConfig();
        });
      } else if (this.usageDocs && this.usageDocs.length > 0) {
        const userIds: string[] = this.usageDocs
          .map(doc => doc.actionTo.userId);

        this.usersListSubscription = this.usersService.getUsersFromIds(uniq(userIds)).subscribe(users => {
          this.loading = false;
          this.users = this.updateUsers(users);
          this.updateTableConfig();
        });
      } else {
        this.usersListSubscription = this.usersService.getUsers(null, 100)
          .subscribe((res) => {
            this.loading = false;
            this.users = this.updateUsers(res);
            this.updateTableConfig();
          });
      }
    }

    this.sub.add(this.usersListSubscription);
  }

  private updateUsers(users: User[]): User[] {
    return users.slice().filter(u => !!u).map(user => {
      user.address = user.address || new Address();
      user.address.postalcode = user.address.postalcode || '';
      user.addressline = user.address.postalcode+ ' - '
        + user.address.street + ' '
        + user.address.number
        + (user.address.letter ? '-' + user.address.letter : '');
      if (user.decedent) {
        user.fullname = `${user.fullname} - ${this.labels.decedent?.toUpperCase() || ''}`;
      }

      return user;
    });
  }

  public canFilterAllUsers(): boolean {
    return !this.utilitiesService.rolesMatch(RoleTypes.excecutor, RoleTypes.orgAdmin) && this.org?.settings?.canShowAllUsers;
  }

  private updateTableConfig() {
    this.userSelectionTableConfig = {
      data: this.users.map(user => {
        return {
          name: user.firstname + ' ' + user.lastname,
          phone: user.phone || user.phone2,
          address: user.addressline,
          picture: {
            title: user.picture || '/assets/images/user.png',
            width: '80px'
          },
          _metadata: {
            originalData: user
          }
        };
      }),
      displayProperties: ['picture', 'name', 'phone', 'address'],
      headers: {},
      rowOptions: [],
      allowSelection: false,
      displayHeaders: false,
      propertyWithImage: 'picture'
    };
  }

  public clearSearch(): void {
    this.form.get('search').setValue('');
  }

  public onSelectRow(row: ITableData): void {
    const user: User = row._metadata.originalData as any;
    this.dialogRef.close(user);
  }

  public proceedWithAction(): void {
    const user: User = new User();
    this.userPromptsService.showDialogue(NewUserComponent, { user }, (user: User) => {
      this.dialogRef.close(user);
    }, true);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
