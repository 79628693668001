import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UserPromptsService } from 'app/core/services/user-prompt.service';
import { PhotoUploadService } from 'app/core/services/photoupload.service';
import { IUploadProgress, IObjectMap } from 'app/shared/interface';
import { FileService } from 'app/core/services/file.service';
import { DomSanitizer } from '@angular/platform-browser';

export interface ImageHandlerOutput {
    file: File,
    url: string;
    id: number;
    uniqueId: string;
    errorMsg?: string;
    uploadPath?: string;
    downloadUrl?: string;
}

export class ImageHandlerConfig {
    isMultiple?: boolean; // if true, then the corresponding result is an array
    shouldCrop?: boolean; // defaults to true
    shouldUpload?: boolean; // defaults to true
    maintainAspectRatio?: boolean;
    aspectRatio?: number; // defaults to 1/1
    uploadFolder?: string;
    resizeWidth?: number; // in px
    defaultImages?: string[];

    // output configs
    outputFile?: boolean;
    outputBase64?: boolean;
    outputUploadPath?: boolean;
    outputId?: boolean;

    constructor() {
        this.shouldCrop = true;
        this.shouldUpload = true;
        this.aspectRatio = 1 / 1;
        this.uploadFolder = 'uploads/unnamed';
        this.defaultImages = [];
    }
}

@Component({
    selector: 'app-image-handler-modal',
    styleUrls: ['./image-handler-modal.component.scss'],
    templateUrl: './image-handler-modal.component.html',
})
export class ImageHandlerModalComponent implements OnInit, AfterViewInit {
    @ViewChild('imageSelectorElement', { static: false }) private imageSelector: ElementRef;

    public ImageHandlerOutputChanged: File;
    public labels: any = {};
    public fileHandlerCursor: number;
    public previewMode: boolean;
    public selectedFiles: File[] = [];
    public uploadProgress: IObjectMap<IUploadProgress> = {};
    public files: ImageHandlerOutput[] = [];
    public fileInScope: ImageHandlerOutput;
    public replaceFileActive: boolean;
    public busy = true;

    constructor(
        public dialogRef: MatDialogRef<ImageHandlerModalComponent>,
        @Inject(MAT_DIALOG_DATA) public config: ImageHandlerConfig,
        private userPromptsService: UserPromptsService,
        private photoUploadService: PhotoUploadService,
        private fileService: FileService,
        public _DomSanitizationService: DomSanitizer
    ) { }

    ngOnInit() {
        // sets defaults config values
        this.config = Object.assign({}, new ImageHandlerConfig(), this.config);
    }

    async ngAfterViewInit() {
        if (this.config.defaultImages.length > 0) {
            const files: File[] = await Promise.all(this.config.defaultImages.map(async (url) => {
                try {
                    const res = url ? await this.fileService.getFileFromUrl(url) : null;
                    return res;
                } catch (e) {
                    console.log(e);
                    return null;
                }
            }));

            const validFiles = files.filter(f => !!f);

            if (validFiles.length > 0) {
                this.onFileSelected(null, files);
            } else {
                this.imageSelector.nativeElement.click();
            }
        } else {
            this.imageSelector.nativeElement.click();
        }

        setTimeout(() => {
            this.busy = false;
        }, 200);
    }

    public async onFileSelected(evt: any, defaultFiles?: File[]) {
        if (this.replaceFileActive) {
            const files: File[] = await this.fileService.getFilesFromInput(evt);
            this.files[this.fileInScope.id].file = files[0];
            this.selectFile(this.files[this.fileInScope.id]);
        } else {
            const files: File[] = defaultFiles || await this.fileService.getFilesFromInput(evt);
            this.files = files.map((file, index) => {
                return {
                    file,
                    url: this.getUrlFromFile(file),
                    id: index,
                    uniqueId: `${Date.now()}-${Math.floor(Math.random() * 1000000)}`
                };
            });

            // we select the first file by default
            this.selectFile(this.files[0]);
        }
    }

    // invoked by image cropper to get updated cropped image
    imageCropped(event: ImageCroppedEvent) {
        const file = <File>event.blob;
        this.files[this.fileInScope.id].file = file;
        this.files[this.fileInScope.id].url = this.getUrlFromFile(file);
    }

    selectFile(file: ImageHandlerOutput) {
        this.fileInScope = Object.assign({}, file);
    }

    replaceImage(file: ImageHandlerOutput) {
        this.replaceFileActive = true;
        this.selectFile(file);
        this.imageSelector.nativeElement.click();
    }

    closeModal() {
        this.dialogRef.close();
    }

    outputResult() {
        let result: ImageHandlerOutput[] | ImageHandlerOutput = this.config.isMultiple ? this.files : this.files[0];
        this.dialogRef.close(result);
    }

    loadImageFailed() {
        this.closeModal();
        this.userPromptsService.showToast('Image handling failed');
    }

    private getPathForUpload(uniqueId: string, filename?: string): string {
        let path = `${this.config.uploadFolder}/${uniqueId}_image_`;
        path += filename || 'random_filename.png';

        return path;
    }

    private updateUploadProgress(progress: number, file: ImageHandlerOutput) {
        const percent = Math.ceil(progress);
        this.uploadProgress[file.id] = {
            percent,
            message: `${percent}%`
        };
    }

    private handUploadPromise(promise: Promise<any>, file: ImageHandlerOutput): Promise<void> {
        return new Promise(resolve => {
            promise.then(downloadUrl => {
                file.downloadUrl = downloadUrl;
                resolve()
            }).catch(e => {
                console.log(e);
                file.errorMsg = e;
                resolve()
            });
        });
    }

    public uploadFile(file: ImageHandlerOutput) {
        return this.handUploadPromise(this.photoUploadService.uploadFileImage(
            file.file,
            file.uploadPath,
            (progress: number) => {
                this.updateUploadProgress(progress, file);
            }
        ), file);
    }

    public async uploadFiles() {
        if (this.files.length > 0) {
            // stops modal from being closed with backdrop
            this.dialogRef.disableClose = true;

            this.files = this.files.map(file => {
                file.uploadPath = this.getPathForUpload(file.uniqueId, file.file.name);

                return file;
            });

            // here we upload images in parallel
            await Promise.all(this.files.map(async file => {
                await this.uploadFile(file);
                return true;
            }));

            // here we output results
            this.outputResult();
        } else {
            this.userPromptsService.showToast('An error was encountered');
            this.closeModal();
        }
    }

    getUrlFromFile(file: File) {
        if (file) {
            return URL.createObjectURL(file);
        } else {
            return 'https://cdn1.iconfinder.com/data/icons/image-manipulations/100/13-512.png';
        }
    }
}
