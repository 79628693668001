import { Component, OnInit } from '@angular/core';
import { RouterService, UserPromptsService, UserService, LocalStorageService, LabelService } from 'app/core';
import { MenuItem, User, Role } from 'app/shared/model';
import { IToolbarSearchEvent, RequestService, ToolbarSearchService } from '@core/services';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@shared/BaseComponent';
import { RoleTypes } from 'app/shared/enum';
import { take } from 'rxjs/operators';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { ViewUserComponent } from '@shared/components/view-user/view-user.component';

@Component({
  selector: 'app-coach-list',
  templateUrl: './coach-list.component.html',
  styleUrls: ['./coach-list.component.scss']
})
export class CoachListComponent extends BaseComponent implements OnInit {
  labels: any = {};
  users: User[] = [];
  usersListSubscription: Subscription;
  roleTypes = RoleTypes;
  searchText: string;
  public isLoading = false;

  private menuItem: MenuItem = Object.assign({}, new MenuItem(), {
    title: 'coaches',
    searchEnabled: true,
    searchPlaceholder: 'search_person'
  });

  public userRole: Role;

  constructor(
    private labelService: LabelService,
    private localStorageService: LocalStorageService,
    private routerService: RouterService,
    private usersService: UserService,
    private toolbarSearchService: ToolbarSearchService,
    private userPromptsService: UserPromptsService,
    private requestService: RequestService,
  ) {
    super();
  }

  async ngOnInit() {
    this.routerService.setMainMenuItem(this.menuItem);
    this.initToolbarSearch();
    this.fetchUsers();

    this.localStorageService.getItem('user_role').subscribe((role) => {
      if (role) {
        this.userRole = JSON.parse(this.localStorageService.getItemSync('user_role'));
      }
    });

    this.labels = (await this.labelService.getLabels('app-users')).data;
  }

  private async fetchUsers(searchWord?: string) {
    this.isLoading = true;
    if (searchWord && searchWord.length < 2) {
      searchWord = undefined;
    }

    if (this.usersListSubscription) {
      this.usersListSubscription.unsubscribe();
    }

    this.usersListSubscription = this.usersService.getCoaches(searchWord)
      .subscribe((res) => {
        this.users = res;
        this.isLoading = false;
      });
    this.subscriptions.push(this.usersListSubscription);
  }

  private async initToolbarSearch() {
    const newSub: Subscription = this.toolbarSearchService.getEmitter()
      .subscribe(async (event: IToolbarSearchEvent) => {
        if (event.event === 'search') {
          this.searchText = event.val;
          this.fetchUsers(event.val);
        }
      });
    this.subscriptions.push(newSub);
  }

  async openEditUserDialog(user?: User) {
    this.userPromptsService.showDialogue(EditUserComponent, { user: user });
    this.toolbarSearchService.reset('search_person');
  }

  async openDeleteUserDialog(user: User) {
    let confirmText = this.labels.confirm_delete_user_warning;

    // get requests where user is customer
    const rqs = await this.requestService.getAllCustomerRequests(user.id).pipe(take(1)).toPromise();
    if (rqs.length > 0) {
      confirmText = this.labels._translate('confirm_delete_user_and_requests', { count: rqs.length });
    }

    this.userPromptsService.confirmPromise(
      this.labels.confirm_action,
      confirmText,
    ).then(async (sure) => {
      if (sure) {
        if (rqs.length > 0) {
          Promise.all(rqs.map(r => this.requestService.deleteRequest(r))).catch();
        }

        await this.usersService.deleteUser(user.id);
        this.userPromptsService.showToast(this.labels.user_delete_successful);

        this.users = [];
        this.fetchUsers(this.searchText);
      }
    });
  }

  handleRowAction(res) {
    if (res.option.id === 'edit_user') {
      this.openEditUserDialog(res.data);
    }
  }

  openViewUserDialog(user: User) {
    this.toolbarSearchService.reset('search_person');
    this.userPromptsService.showDialogue(ViewUserComponent, { user: user });
  }

  handleRowSelect(res) {
    this.openViewUserDialog(res);
  }

  getTableConfig() {
    const config = {
      data: this.users,
      displayProperties: ['picture', 'fullname'],
      rowOptions: [
        {
          id: 'edit_user',
          title: this.labels.edit_user,
          icon: 'edit'
        }
      ],
      allowSelection: false,
      displayHeaders: false,
      propertyWithImage: 'picture'
    };

    return config;
  }

  createNewUser() {
    const user: User = new User();
    if (this.searchText) {
      const isSearchMatchAddressFormat = this.searchText.substr(0, 6).match(/^[0-9]{4}[a-zA-Z]{2}/);
      if (isSearchMatchAddressFormat) {
        user.address.postalcode = this.searchText.substring(0, 6);

        const houseNumber = parseInt(this.searchText.substr(6), 10);
        if (houseNumber > 0) {
          user.address.number = houseNumber;
        }
      }
      this.toolbarSearchService.reset('search_person');
    }
    this.openEditUserDialog(user);
  }
}
