<div class="container">
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center" class="empty-msg">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>

  <div *ngIf="!loading">
    <mat-card *ngIf="!customer.email || !customer.phone || !customer.address" [style]="{width: isMobile ? '100%' : '70%', margin: '0 auto'}">
      <mat-card-header>
        <mat-card-title>
          {{labels.activate_find_company}}
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="empty-msg mt-3">
          {{labels.email_needed_for_findcompany}}
        </div>
      </mat-card-content>
    </mat-card>

    <div *ngIf="customer.email && customer.phone && customer.address">
      <div *ngIf="user">
        <span [ngSwitch]="user.status">
          <div *ngSwitchCase="UserStatusTypes.invited" class="empty-msg" fxLayout="row" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" (click)="resendInvite()">
              {{labels.resend_invite}}
            </button>

          </div>

          <div *ngSwitchCase="UserStatusTypes.active" class="empty-msg">
            <div [style]="{width: isMobile ? '100%' : '50%', margin: '0 auto'}">
              <ng-container *ngTemplateOutlet="profileCard; context: { height: cardRowConfig.height-5 }"></ng-container>
            </div>
          </div>

          <div *ngSwitchDefault>
            Reminder: User status has not been handled in description here
          </div>
        </span>
      </div>

      <div *ngIf="!user" class="empty-msg" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="primary" (click)="activateFindCompany()">
          {{labels.activate_find_company}}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #profileCard let-height="height">
  <mat-card class="full-width" fxLayout="column" [ngStyle]="{'height': height+'px'}">
    <img mat-card-image [src]="user.profileBgImg" style="height:175px !important;">
    <div class="card_option" fxLayout="row" fxLayoutAlign="flex-end">
      <div>
        <button [matMenuTriggerFor]="menu" mat-raised-button>{{labels.options}}</button>
        <mat-menu #menu="matMenu">
          <button (click)="resendInvite()" mat-menu-item
            [disabled]="user.status !== UserStatusTypes.invited">{{labels.resend_invite}}</button>
          <button (click)="changePhoneNumber(user)" mat-menu-item>{{labels.edit_phone_number}}</button>
          <button (click)="deleteUser(user)" mat-menu-item>{{labels.delete_user}}</button>
        </mat-menu>
      </div>
    </div>

    <img style="z-index: 1;" [src]="user.photoUrl" class="profile_img">

    <div fxLayout="column" fxLayoutGap="10px" fxFlex="stretch">
      <div class="text-center name_section" fxLayout="column" fxLayoutGap="7px">
        <div class="name fs-1-1" fxLayout="column" fxLayoutAlign="center center">
          <div #profileName>{{user.firstName}} {{user.lastName}}</div>
          <div class="email_text">{{user.email}}</div>
          <div class="email_text">{{user.phoneNumber}}</div>
          <div class="email_text" style="margin-top: 7px;" fxLayout="row" fxLayoutGap="5px">
            <span *ngIf="user.lastActive">
              {{labels.active}}: {{user.lastActive | date}}
            </span>
            <span *ngIf="user.lastActive">|</span>
            <span>{{user.published ? labels.published : labels.unpublished}}</span>
          </div>
        </div>
      </div>

      <!-- <div class="interests_section" fxFlex="stretch">
                <span class="intrest_chip" *ngFor="let intr of profile?.interests | slice:0:5">{{intr}}</span>
                <div style="clear: left;"></div>
            </div> -->
    </div>
  </mat-card>
</ng-template>