export enum RoleTypes {
    excecutor = 1,
    coordinator,
    professional,
    superAdmin,
    orgAdmin,
    user,
    networkPartner,
    coordinatorReservations,
    institutionContact,
    customer,
    servicePoint
}
