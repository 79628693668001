import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelService } from '@core/services';
import { Activity, ActivityScheduleStatus } from '@models/model';
import { getHours, getMinutes, getSeconds, setHours, setMinutes, setSeconds } from 'date-fns';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-save-activity',
  templateUrl: './save-activity.component.html',
  styleUrls: ['./save-activity.component.scss']
})
export class SaveActivityComponent implements OnInit {

  private activity: Activity;
  public labels: any = {};
  protected form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { activity: Activity },
    private dialogRef: MatDialogRef<SaveActivityComponent>,
    private labelService: LabelService,
    private fb: FormBuilder,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.activity = cloneDeep(this.data.activity);
    this.labels = (await this.labelService.getLabels('app-save-activity')).data;
    this.setupForm();
  }

  private setupForm() {
    this.form = this.fb.group({
      date: [new Date()],
      saveOption: ['saveAndComplete', Validators.required]
    });

    this.form.get('saveOption').valueChanges.subscribe(value => {
      const dateCtl = this.form.get('date');
      if (value == 'justSave') dateCtl.disable();
      else dateCtl.enable();
    })
  }

  protected save() {
    const model = this.form.value;
    if (model.saveOption == 'justSave') {
      this.dialogRef.close(this.activity);
    } else {
      const now = new Date();
      model.date = setSeconds(setMinutes(setHours(new Date(model.date), getHours(now)), getMinutes(now)), getSeconds(now));
      this.activity.date = model.date;
      this.activity.scheduleDetails.status = ActivityScheduleStatus.completed;
      this.dialogRef.close(this.activity);
    }
  }
}
