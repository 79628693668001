import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService, DocItem } from './firestore.service';
import { LocalStorageService } from './local-storage.service';
import { GroupActivityService } from './group-activity.service';
import { map, switchMap } from 'rxjs/operators';
import { uniq } from 'lodash';
import { Group } from '@models/model';
import { GroupFilter } from 'app/groups-module/components';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';
import { SearchService } from './search.service';

@Injectable({
    providedIn: 'root',
})
export class GroupService {
    private orgId: string;
    private ref: string;

    constructor(
        private localStorageService: LocalStorageService,
        private afsDB: FirestoreService,
        private groupActivityService: GroupActivityService,
        private searchService: SearchService,
    ) {
        this.orgId = this.localStorageService.getItemSync('user_organization');
        this.ref = `/organizations/${this.orgId}/groups`;
        this.localStorageService.getItem('user_organization').subscribe(orgId => {
            this.orgId = orgId;
            this.ref = `/organizations/${this.orgId}/groups`;
        });
    }

    public getGroups(queryFn?: any): Observable<Group[]> {
        return this.afsDB.colWithIds$(this.ref, queryFn, true);
    }

    public getGroupById(groupId: string): Observable<Group> {
        return this.afsDB.docWithId$(`${this.ref}/${groupId}`);
    }

    public createGroup(group: Group): Promise<Group> {
        return new Promise(async (resolve) => {
            group.id = this.afsDB.getNewId();
            await this.afsDB.setDoc(`${this.ref}/${group.id}`, group as DocItem);

            resolve(group);
        });
    }

    public deleteGroup(groupId: string): Promise<any> {
        return this.afsDB.remove(`${this.ref}/${groupId}`);
    }

    public updateGroup(group: Group): Promise<any> {
        return this.afsDB.update(`${this.ref}/${group.id}`, group as DocItem);
    }

    public getGroupCount(filters: GroupFilter) {
        return this.afsDB.getCounts(this.ref, () => this.getFilters(filters))
    }

    public getGroupsForWorkingArea(workingAreaId: string, limit?: number): Observable<Group[]> {
        return this.getGroups(ref => {
            ref = ref.where('workingAreaId', '==', workingAreaId);
            if (limit) {
                ref = ref.limit(limit)
            }
            return ref.orderBy('name', 'asc');
        });
    }

    public getGroupsForService(serviceId: string, limit?: number, sort: boolean = false): Observable<Group[]> {
        return this.getGroups(ref => {
            ref = ref.where('serviceId', '==', serviceId);
            if (limit) ref = ref.limit(limit);
            if (sort) ref = ref.orderBy('name', 'asc');
            return ref;
        });
    }

    public getGroupsFromIds(groupIds: string[]): Observable<Group[]> {
        return this.afsDB.docsFromId$(this.ref, groupIds);
    }

    public getGroupsForExecutor(executorId: string, limit: number): Observable<Group[]> {
        return this.groupActivityService.getGroupActivities(ref => {
            return ref.where(`executors.${executorId}.id`, '==', executorId)
                .limit(limit).orderBy('name', 'asc');
        }).pipe(
            map(activities => {
                const groupIds = activities.map(a => a.groupId);
                return uniq(groupIds);
            }),
            switchMap(groupIds => {
                return this.getGroupsFromIds(groupIds);
            })
        );
    }

    public getGroupsByFilter(query: GroupFilter, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean) {
        const options: Partial<QueryConstraintOptions<Group>> = {};
        if (lastOrFirstRef) {
            options.orderBy = [{ field: 'name', val: 'asc' }]
            if (isReloadedContext) {
                options.startAt = lastOrFirstRef;
                options.limit = limit || 30;
            } else {
                if (isForwardNavigation) {
                    options.startAfter = lastOrFirstRef;
                    options.limit = limit || 30;
                } else {
                    options.endBefore = lastOrFirstRef;
                    options.limitToLast = limit || 30;
                }
            }
        } else {
            options.limit = limit || 30
        }
        return this.afsDB.colWithIdsNew$<Group>(this.ref, () => this.getFilters(query), options, true);
    }

    public search(query: GroupFilter) {
        const word = query.textFilter
        if (word) {
            const where = this.getFilters(query);
            return new Observable<Group[]>(observer => {
                this.searchService.searchCollectionIndex<Group>({
                    searchText: query.textFilter,
                    ref: this.ref,
                    where
                }).then(groups => {
                    observer.next(groups)
                })
            })
        }
    }

    private getFilters(query: GroupFilter) {
        const where: Where[] = [];
        if (query.workingAreaId) {
            where.push(['workingAreaId', '==', query.workingAreaId]);
        }
        if (query.serviceId) {
            where.push(['serviceId', '==', query.serviceId]);
        }
        if (query.executorId) {
            where.push([`executors.${query.executorId}.id`, '==', query.executorId])
        }

        return where;
    }
}
