import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { CountryApi, MatchingAddresses } from '@models/model';
import { Observable } from 'rxjs';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  
  private sessionKey: string; // sessionKey for completion of an address

  constructor(
    private apiService: ApiService,
    private utilitiesService: UtilitiesService
  ) { 
    this.sessionKey = utilitiesService.generateRandomString();
  }

  public getCountries() {
    return this.apiService.get('address/countries').pipe(take(1)).toPromise() as unknown as Promise<CountryApi[]>;
  }

  public getAddresses(context: string, term: string) {
    const username = environment.addressApi.key;
    const password = environment.addressApi.secret;
    const session = this.sessionKey;
    return this.apiService.post('address/get-address', { context: encodeURIComponent(context), term: encodeURIComponent(term), username, password, session }) as unknown as Observable<MatchingAddresses>;
  }

  public getSelectedAddress(context: string, dispatchCountry: string) {
    const username = environment.addressApi.key;
    const password = environment.addressApi.secret;
    const session = this.sessionKey;
    this.sessionKey = this.utilitiesService.generateRandomString(); // sessionKey needs to be changed after a completion, To avoid cost increment
    return this.apiService.post('address/selected-address', { context, username, dispatchCountry, password, session });
  }

  public getAddressByPostalCode(postalCode: string, houseNumber: number, houseNumberAddition: string) {
    const username = environment.addressApi.key;
    const password = environment.addressApi.secret;
    return this.apiService.post('address/postal-code', { postalCode, username, houseNumber, houseNumberAddition, password }) as unknown as Observable<any>;
  }
}
