import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public redirectUrl = '';
  public authState$: BehaviorSubject<firebase.User> = new BehaviorSubject<any>(undefined);
  public customClaimsHandler: {
    clear: () => Promise<void>;
  };

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.afAuth.authState.subscribe(async (auth) => {
      this.authState$.next(auth);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.indexOf('auth') === -1 && this.router.url.length > 2) {
          localStorage.setItem('last_path', event.urlAfterRedirects);
        }
      }
    });
  }

  public async propagateCustomClaims(): Promise<void> {
    return this.afAuth.currentUser.then(auth => {
      return auth.getIdTokenResult(true);
    }).then(() => {
      return null;
    });
  }

  public register(user: { email: string; password: string }) {
    return this.afAuth.createUserWithEmailAndPassword(user.email, user.password);
  }

  public login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  public linkMicrosoftAuth(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    return firebase.auth().currentUser.linkWithPopup(provider);
  }

  private getPasswordCredential(email: string, password: string): firebase.auth.AuthCredential {
    return firebase.auth.EmailAuthProvider.credential(email, password);
  }

  public async changeEmailAddress(oldEmail: string, newEmail: string, password: string): Promise<void> {
    const credential = this.getPasswordCredential(oldEmail, password);

    await firebase.auth().currentUser.reauthenticateWithCredential(credential);
    return firebase.auth().currentUser.updateEmail(newEmail);
  }

  public unlinkOtherAuthExceptMicrosoft(): Promise<any> {
    return Promise.all(firebase.auth().currentUser.providerData.map(provider => {
      if (provider.providerId === 'microsoft.com') {
        return Promise.resolve(null);
      } else {
        console.log(`Unlinking ${provider.providerId} auth...`);
        return firebase.auth().currentUser.unlink(provider.providerId);
      }
    }));
  }

  public unlinkMicrosoftAuth(): Promise<any> {
    return Promise.all(firebase.auth().currentUser.providerData.map(provider => {
      if (provider.providerId === 'microsoft.com') {
        console.log(`Unlinking ${provider.providerId} auth...`);
        return firebase.auth().currentUser.unlink(provider.providerId);
      } else {
        return Promise.resolve(null);
      }
    }));
  }

  public loginSocial(socialNetwork: string): Promise<firebase.auth.UserCredential> {
    switch (socialNetwork) {
      case 'google':
        return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
      case 'microsoft':
        return this.afAuth.signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'));
    }
  }

  // public async sendSigninLinkToEmail(email: string) {
  //     const user = await this.userService.getUserByEmail(email);

  //     if (user) {
  //         localStorage.setItem('user_auth_email', email);
  //         const orgName = this.localStorageService.getItemSync('user_organization');

  //         const settings: firebase.auth.ActionCodeSettings = {
  //             url: `http://${location.host}/${orgName}/auth?verify=true`,
  //             handleCodeInApp: true
  //         };

  //         return this.afAuth.sendSignInLinkToEmail(email, settings);
  //     } else {
  //         return false;
  //     }
  // }

  public isLoggedIn(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const value = this.authState$.getValue();

      if (value === undefined) {
        this.afAuth.onAuthStateChanged(auth => {
          resolve(!!(auth && auth.uid));
        });
      } else {
        resolve(!!(value && value.uid));
      }
    });
  }

  public async logout() {
    await this.customClaimsHandler.clear();
    return this.afAuth.signOut();
  }

  public resetPassword(email: string) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  public isSignInWithEmailLink(link: string) {
    return this.afAuth.isSignInWithEmailLink(link);
  }

  public signInWithEmailLink(authEmail, authRedirectUrl) {
    return this.afAuth.signInWithEmailLink(authEmail, authRedirectUrl);
  }

  public sendVerificationEmail(): Observable<any> {
    return new Observable((observer) => {
      const user: firebase.User = firebase.auth().currentUser;

      const interval = setInterval(async () => {
        await user.reload();
        if (user.emailVerified) {
          clearInterval(interval);
          observer.next();
        }
      }, 2000);

      user.sendEmailVerification();
    });
  }

  public emailIsRegistered(email: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const signInMethods: string[] = await this.afAuth.fetchSignInMethodsForEmail(email);
      return resolve(signInMethods.length > 0);
    });
  }
}
