import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent implements OnChanges {
  @Input() width = '40px';
  @Input() height = '40px';
  @Input() image: string;
  @Input() noImageDefaultImage: boolean;


  protected url: string;

  ngOnChanges(_changes: SimpleChanges): void {
    this.url = this.noImageDefaultImage == undefined || this.noImageDefaultImage ? this.image || '/assets/images/user.png' : this.image;
  }
}
