import { Address } from "./address";
import { IArea } from "./postcodeAddress";

export enum ModeOfTransport {
    publicTransport = 1,
    carCustomer,
    carExecutor,
    organizationVehicle,
    bicycle,
    walking
}

enum TravelStatus {
    pickUp = 1,
    towardsDestination,
    returnTrip,
    waiting
}

export interface IGoTogetherSettings {
    activated: boolean;
    kilometerAllowance: number;
    defaultModeOfTransport: ModeOfTransport;
}



export class GoTogetherDetails {
    fromAddress: Address;
    toAddress: Address;
    fromArea: IArea;
    toArea: IArea;
    pickupTime: number;
    appointmentTime: number;
    returnTime: number;
    modeOfTransport: ModeOfTransport;
    roundtrip: boolean;
    travelStatus: TravelStatus;
    distance: { text: string; value: number };
    duration: { text: string; value: number };
    distanceExecutorToAddress: { text: string; value: number }
    
    constructor(goTogetherDetails?: GoTogetherDetails) {
        this.fromAddress = goTogetherDetails?.fromAddress || null;
        this.toAddress = goTogetherDetails?.toAddress || null;
        this.fromArea = goTogetherDetails?.fromArea || null;
        this.toArea = goTogetherDetails?.toArea || null;
        this.pickupTime = goTogetherDetails?.pickupTime || null;
        this.appointmentTime = goTogetherDetails?.appointmentTime || null;
        this.returnTime = goTogetherDetails?.returnTime || null;
        this.modeOfTransport = goTogetherDetails?.modeOfTransport || null;
        this.roundtrip = goTogetherDetails?.roundtrip != null ? goTogetherDetails?.roundtrip : null;
        this.travelStatus = goTogetherDetails?.travelStatus || null;
        this.distance = goTogetherDetails?.distance || { text: '', value: null };
        this.duration = goTogetherDetails?.duration || { text: '', value: null }
        this.distanceExecutorToAddress = goTogetherDetails?.distanceExecutorToAddress || { text: '', value: null };
    }
}