<div class="menu_title" *ngIf="data.title">
    {{data.title}} 
</div>

<mat-divider *ngIf="data.title"></mat-divider>

<mat-nav-list>
  <mat-list-item 
      *ngFor="let option of data.options" 
      (click)="optionSelected(option)"> 
      {{option.text}} 
  </mat-list-item>
</mat-nav-list>
